
    import { Component, Vue, Inject } from 'vue-property-decorator'
    import { mapState } from 'vuex'
    import DatePicker from '@/app/common/components/date-picker.vue'
    import { VForm } from '@/app/common/types/v-form.type'
    import { License } from '@/app/license/entities/license.entity'
    import { ProductPhase } from '@/app/api-entity/entities/product-phase.entity'
    import { IncomeType } from '@/app/api-entity/entities/income-type.entity'
    import { GetAllProductPhasesCommand } from '@/app/api-entity/commands/get-all-product-phases.command'
    import { GetAllIncomeTypesCommand } from '@/app/api-entity/commands/get-all-income-types.command'
    import { CreateDossierCommand } from '@/app/dossier/commands/create-dossier.command'
    import { NotificationDispatcher } from '@/app/common/services/notification-dispatcher.service'
    import validation from '@/app/common/helpers/validation'
    import localizeContent from '@/app/common/helpers/localize-content'

    @Component({
        components: { DatePicker },
        metaInfo() {
            return {
                title: 'Create Dossier'
            }
        },
        computed: {
            ...mapState('license', ['license']),
            ...mapState('apiEntity', ['productPhases', 'incomeTypes']),
        }
    })
    export default class CreateDossier extends Vue {
        license!: License
        productPhases!: ProductPhase[]
        incomeTypes!: IncomeType[]
        localizeContent = localizeContent

        @Inject() getAllProductPhasesCommand!: GetAllProductPhasesCommand
        @Inject() getAllIncomeTypesCommand!: GetAllIncomeTypesCommand
        @Inject() createDossierCommand!: CreateDossierCommand
        @Inject() notificationDispatcher!: NotificationDispatcher

        loadingCreate = false

        genderItems = [
            { text: 'Man', value: 'male' },
            { text: 'Vrouw', value: 'female' }
        ]
        gender: string|null = null
        initials: string|null = null
        firstName: string|null = null
        preposition: string|null = null
        lastName: string|null = null
        postalCode: string|null = null
        houseNumber: string|null = null
        houseNumberAddition: string|null = null
        street: string|null = null
        city: string|null = null
        homePhone: string|null = null
        mobilePhone: string|null = null
        dateOfBirth: string|null = null
        country: string|null = null
        email: string|null = null

        hasPartner = false

        partnerGender: string|null = null
        partnerInitials: string|null = null
        partnerFirstName: string|null = null
        partnerPreposition: string|null = null
        partnerLastName: string|null = null
        partnerPostalCode: string|null = null
        partnerHouseNumber: string|null = null
        partnerHouseNumberAddition: string|null = null
        partnerStreet: string|null = null
        partnerCity: string|null = null
        partnerHomePhone: string|null = null
        partnerMobilePhone: string|null = null
        partnerDateOfBirth: string|null = null
        partnerCountry: string|null = null
        partnerEmail: string|null = null

        productPhaseItems: { text: string, value: string }[] = []
        productPhase: string|null = null
        incomeTypeItems: { text: string, value: string }[] = []
        incomeType: string|null = null
        incomeTypePartnerItems: { text: string, value: string }[] = []
        incomeTypePartner = null

        rules = validation

        get form(): VForm {
            return this.$refs.form as VForm
        }

        async created() {
            if (!this.license.features.showAppointmentForm) {
                await this.$router.replace('404')
                return
            }

            await this.getAllProductPhasesCommand.run()
            await this.getAllIncomeTypesCommand.run()

            this.fillProductPhasesDropdown()
            this.fillIncomeTypesDropdown()
        }

        validate(): boolean {
            return this.form.validate()
        }

        fillProductPhasesDropdown() {
            this.productPhaseItems = this.productPhases.map((productPhase: ProductPhase) => {
                return { text: productPhase.label, value: productPhase.code }
            })
        }

        fillIncomeTypesDropdown() {
            this.incomeTypeItems = this.incomeTypes.map((incomeType: IncomeType) => {
                return { text: incomeType.label, value: incomeType.code }
            })
            this.incomeTypePartnerItems = this.incomeTypeItems
        }

        async create() {
            const data = {
                hasPartner: this.hasPartner,
                productPhase: this.productPhase,
                leadSource: this.license.content.leadSource,
                persons: [
                    {
                        gender: this.gender,
                        initials: this.initials,
                        firstName: this.firstName,
                        preposition: this.preposition,
                        lastName: this.lastName,
                        postalCode: this.postalCode,
                        houseNumber: this.houseNumber,
                        houseNumberAddition: this.houseNumberAddition,
                        street: this.street,
                        city: this.city,
                        homePhone: this.homePhone,
                        mobilePhone: this.mobilePhone,
                        dateOfBirth: this.dateOfBirth,
                        country: this.country,
                        email: this.email,
                        addressStyle: this.license.features.defaultAddressTyle,
                        incomeType: this.incomeType
                    },
                    {
                        gender: this.partnerGender,
                        initials: this.partnerInitials,
                        firstName: this.partnerFirstName,
                        preposition: this.partnerPreposition,
                        lastName: this.partnerLastName,
                        postalCode: this.partnerPostalCode,
                        houseNumber: this.partnerHouseNumber,
                        houseNumberAddition: this.partnerHouseNumberAddition,
                        street: this.partnerStreet,
                        city: this.partnerCity,
                        homePhone: this.partnerHomePhone,
                        mobilePhone: this.partnerMobilePhone,
                        dateOfBirth: this.partnerDateOfBirth,
                        country: this.partnerCountry,
                        email: this.partnerEmail,
                        addressStyle: this.license.features.defaultAddressTyle,
                        incomeType: this.incomeTypePartner
                    }
                ]
            }

            try {
                this.loadingCreate = true
                await this.createDossierCommand.run(data)
                await this.notificationDispatcher.success("this.$t(this.dossierTranslation.createsuccess).toString()")
                this.form.reset()
            } catch (err) {
                await this.notificationDispatcher.error(err.message)
            } finally {
                this.loadingCreate = false
            }
        }
    }
