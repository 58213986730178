import { Command } from '@/app/common/base/command'
import { Mortgage } from '@/app/mortgage/entities/mortgage.entity'

export class GetAllMortgagesCommand extends Command {
    public async run() {
        const response = await this.client.get('/api/products/mortgages')
        const mortgages = response.asEntities(Mortgage)

        await this.store.dispatch('mortgage/setMortgages', mortgages)
    }
}
