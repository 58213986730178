import { MutationTree } from 'vuex'
import { ApiEntityState } from '@/app/api-entity/store/state'
import { ProductPhase } from '@/app/api-entity/entities/product-phase.entity'
import { IncomeType } from '@/app/api-entity/entities/income-type.entity'

export const mutations: MutationTree<ApiEntityState> = {

    setProductPhases(state: ApiEntityState, productPhases: ProductPhase[]) {
        state.productPhases = productPhases
    },

    setIncomeTypes(state: ApiEntityState, incomeTypes: IncomeType[]) {
        state.incomeTypes = incomeTypes
    }

}
