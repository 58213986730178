import { ActionTree, Commit } from 'vuex'
import { RootState } from '@/app/common/types/root-state.type'
import { LicenseState } from '@/app/license/store/state'
import { License } from '@/app/license/entities/license.entity'
import { Language } from '@/app/license/entities/language.entity'

export const actions: ActionTree<LicenseState, RootState> = {

    setLicense({ commit }: { commit: Commit }, license: License) {
        commit('setLicense', license)
    },

    setDomain({ commit }: { commit: Commit }, domain: string) {
        commit('setDomain', domain)
    },

    clearDomain({ commit }: { commit: Commit }) {
        commit('setDomain', null)
    },

    setLanguages({ commit }: { commit: Commit }, languages: Language[]) {
        commit('setLanguages', languages)
    },

}
