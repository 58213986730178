import { Expose, Type } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'
import { ProductActionCounter } from '@/app/product/entities/product-action-counter.entity'

export class CardifAo extends Entity<CardifAo> {
    @Expose()
    id!: string

    @Expose()
    label!: string

    @Expose({ name: 'reference_number' })
    referenceNumber!: string

    @Expose({ name: 'quote_number' })
    quoteNumber!: string

    @Expose({ name: 'action_counter' })
    @Type(() => ProductActionCounter)
    actionCounter!: ProductActionCounter
}
