
    import { Vue, Component, Prop } from 'vue-property-decorator'

    @Component
    export default class TwoFactor extends Vue {
        otp = ''

        @Prop({ type: Boolean, default: false }) loading!: boolean

        close() {
            this.$emit('close')
        }

        submit() {
            this.$emit('submit', this.otp)
        }
    }
