
    import { Component, Inject, Prop, Vue } from "vue-property-decorator"
    import { mapState } from "vuex"
    import { GetPartnerDataCommand } from "@/app/inventory-form/commands/get-partner-data.command"
    import { Consumer } from "@/app/identity"
    import validation from "@/app/common/helpers/validation"
    import { NotificationDispatcher } from "@/app/common/services/notification-dispatcher.service"
    import { VForm } from "@/app/common/types/v-form.type"

    @Component({
        computed: {
            ...mapState('auth', ['consumer'])
        },
    })
    export default class Pep extends Vue {
        formData = {
            pepSelf: null,
            pepAssociate: null,
            pepReason: '',
        }
        @Inject() notificationDispatcher!: NotificationDispatcher
        @Inject() getPartnerDataCommand!: GetPartnerDataCommand
        couple: Consumer|null = null
        rules = validation
        @Prop({ default: () => ({}) }) readonly externalFormData!: any
        async mounted() {
            this.couple = await this.getPartnerDataCommand.run()
            this.formData = { ...this.formData, ...this.externalFormData }
        }
        get form(): VForm {
            return this.$refs.form as VForm
        }
        validate(): boolean {
            return this.form.validate()
        }
        goToPreviousStep() {
            this.$emit('changeStep', 1)
        }
        goToNextStep() {
            if (this.validate()) {
                this.$emit('updateData', this.formData)
                this.$emit('changeStep', 3)
            }
        }
    }
