import { AxiosClient } from '@/app/common/services/http/axios-client.service'
import store from '@/plugins/store'
import { GetAllActionsCommand } from '@/app/product/commands/get-all-actions.command'
import { DownloadQuoteAttachmentCommand } from '@/app/product/commands/download-quote-attachment.command'
import { DownloadDownloadableDocumentCommand } from '@/app/product/commands/download-downloadable-document.command'
import { DownloadProductActionFileCommand } from '@/app/product/commands/download-product-action-file.command'
import { UpdateDateConditionPrecedentCommand } from '@/app/product/commands/update-date-condition-precedent.command'
import { UploadDocumentCommand } from '@/app/product/commands/upload-document.command'
import { GetProductDefinitionsCommand } from '@/app/product/commands/get-product-definitions.command'
import { RespondDocumentApprovalCommand } from "@/app/product/commands/respond-document-approval.command"

const httpClient = new AxiosClient()

export const getAllActionsCommand = new GetAllActionsCommand(httpClient, store)
export const downloadQuoteAttachmentCommand = new DownloadQuoteAttachmentCommand(httpClient, store)
export const downloadDownloadableFileCommand = new DownloadDownloadableDocumentCommand(httpClient, store)
export const downloadProductActionFileCommand = new DownloadProductActionFileCommand(httpClient, store)
export const updateDateConditionPrecedentCommand = new UpdateDateConditionPrecedentCommand(httpClient, store)
export const uploadDocumentCommand = new UploadDocumentCommand(httpClient, store)
export const getProductDefinitionsCommand = new GetProductDefinitionsCommand(httpClient, store)
export const respondDocumentApproval = new RespondDocumentApprovalCommand(httpClient, store)
