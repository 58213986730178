import 'reflect-metadata'
import Vue from 'vue'
import moment from 'moment'
import VueMeta from 'vue-meta'
import App from './app/app.vue'
import './worker'
import router from './plugins/router'
import store from './plugins/store'
import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n'
import './plugins/sentry'
import bindings from './app/bindings'
import formatDate from '@/app/common/helpers/format-date'
import localizeDate from '@/app/common/helpers/localize-date'
import timeFromNow from '@/app/common/helpers/time-from-now'
import formatMoney from '@/app/common/helpers/format-money'
import localizeContent from '@/app/common/helpers/localize-content'

Vue.use(VueMeta)

Vue.config.productionTip = false
Vue.config.devtools = true

// Set stored locale or use the default.
i18n.locale = store.state.global.locale || i18n.locale

// Moment locale.
moment.locale(i18n.locale)

// Vuetify locale.
if (vuetify.userPreset.lang) {
    vuetify.userPreset.lang.current = i18n.locale
}
vuetify.preset.lang.current = i18n.locale
vuetify.framework.lang.current = i18n.locale

// Register filters
Vue.filter('localizeDate', (date: string): string => localizeDate(date))
Vue.filter('localizeDateWithTime', (date: string): string => localizeDate(date, true))
Vue.filter('formatDate', (date: string, format: string): string => formatDate(date, format))
Vue.filter('timeFromNow', (date: string): string => timeFromNow(date))
Vue.filter('formatMoney', (value: number|string): string => formatMoney(value))
Vue.filter('localizeContent', (value: any): string => localizeContent(value))

new Vue({
    router,
    store,
    vuetify,
    i18n,
    provide: bindings,
    render: h => h(App)
}).$mount('#app')
