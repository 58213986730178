import { ActionTree, Commit } from 'vuex'
import { RootState } from '@/app/common/types/root-state.type'
import { InvoiceState } from '@/app/invoice/store/state'
import { Invoice } from '@/app/invoice/entities/invoice.entity'

export const actions: ActionTree<InvoiceState, RootState> = {

    setInvoices({ commit }: { commit: Commit }, invoices: Invoice[]) {
        commit('setInvoices', invoices)
    }

}
