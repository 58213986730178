import { GetterTree } from 'vuex'
import { RootState } from '@/app/common/types/root-state.type'
import { CardifAoState } from '@/app/cardifao/store/state'
import { CardifAo } from '@/app/cardifao/entities/cardifao'

export const getters: GetterTree<CardifAoState, RootState> = {

    cardifAosWithActionPoints(state: CardifAoState, otherGetters: any, rootState: RootState, rootGetters: any): CardifAo[] {
        return state.cardifAos.filter((cardifAo: CardifAo) => {
            const productActions = rootGetters['product/productActionsByProductId'](cardifAo.id)
            return productActions.length !== 0
        })
    }

}
