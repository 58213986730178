import { ActionTree, Commit } from 'vuex'
import { RootState } from '@/app/common/types/root-state.type'
import { PurchaseIntentionState } from '@/app/purchase-intention/store/state'
import { PurchaseIntention } from '@/app/purchase-intention/entities/purchase-intention.entity'

export const actions: ActionTree<PurchaseIntentionState, RootState> = {

    setPurchaseIntentions({ commit }: { commit: Commit }, purchaseIntentions: PurchaseIntention[]) {
        commit('setPurchaseIntentions', purchaseIntentions)
    }

}
