import { Expose, Type } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'
import { AddressStyle } from '@/app/identity/support/address-style'

export class Consumer extends Entity<Consumer> {
    @Expose()
    id!: string

    @Expose()
    gender!: string

    @Expose()
    initials!: string

    @Expose({ name: 'first_name' })
    firstName!: string

    @Expose()
    preposition!: string

    @Expose({ name: 'last_name' })
    lastName!: string

    @Expose({ name: 'display_name' })
    displayName!: string

    @Expose({ name: 'date_of_birth' })
    dateOfBirth!: string

    @Expose()
    address!: string

    @Expose()
    nationality!: string

    @Expose()
    email!: string

    @Expose()
    profilePicture?: string

    @Expose()
    @Type(() => Number)
    phoneNumbers?: number[]

    @Expose({ name: 'two_factor' })
    twoFactor!: boolean

    @Expose({ name: 'street_name' })
    streetName!: string

    @Expose({ name: 'house_number' })
    houseNumber!: string

    @Expose({ name: 'house_number_addition' })
    houseNumberAddition!: string

    @Expose()
    zipcode!: string

    @Expose()
    city!: string

    @Expose({ name: 'birth_country' })
    birthCountry!: string

    @Expose({ name: 'birth_last_name' })
    birthLastName!: string

    @Expose({ name: 'birth_preposition' })
    birthPreposition!: string

    @Expose({ name: 'phone_home' })
    phoneHome!: string

    @Expose({ name: 'phone_mobile' })
    phoneMobile!: string

    @Expose({ name: 'marital_state' })
    maritalState!: string

    @Expose()
    degree!: string

    @Expose()
    smoker!: boolean

    @Expose({ name: 'in_paid_employment' })
    inPaidEmployment!: boolean

    @Expose({ name: 'ever_divorced' })
    everDivorced!: boolean

    @Expose({ name: 'address_style' })
    addressStyle!: string

    @Expose()
    salutation!: string

    @Expose({ name: 'is_company' })
    isCompany!: boolean

    hasFormalAddressStyle(): boolean
    {
        return this.addressStyle === AddressStyle.formal
    }

}
