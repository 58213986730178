/* eslint-disable @typescript-eslint/camelcase */
import { Command } from '@/app/common/base/command'

export class RespondDocumentApprovalCommand extends Command {
    public async run(productActionId: string, response: string, explanation: string): Promise<void> {
        await this.client.post(`/api/actions/${productActionId}/respond-document-approval`, {
            response,
            explanation
        })
    }
}
