import { ActionTree, Commit } from 'vuex'
import { RootState } from '@/app/common/types/root-state.type'
import { Mortgage } from '@/app/mortgage/entities/mortgage.entity'
import { MortgageState } from '@/app/mortgage/store/state'

export const actions: ActionTree<MortgageState, RootState> = {

    setMortgages({ commit }: { commit: Commit }, mortgages: Mortgage[]) {
        commit('setMortgages', mortgages)
    }

}
