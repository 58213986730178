import { Command } from '@/app/common/base/command'

export class DownloadProductActionFileCommand extends Command {
    public async run(productActionId: string) {
        const response = await this.client.download(
            `/api/actions/${productActionId}/download`,
            {
                responseType: 'blob',
                timeout: 30000
            },
            true
        )

        let filename = ''
        const disposition: any = response.headers['content-disposition']
        if (disposition && disposition.indexOf('attachment') !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
            const matches = filenameRegex.exec(disposition)
            if (matches?.[1]) {
                filename = matches[1].replace(/['"]/g, '')
            }
        }
        const fileURL = window.URL.createObjectURL(response.data)
        const fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute('download', decodeURI(filename))
        document.body.appendChild(fileLink)
        fileLink.click()
    }
}
