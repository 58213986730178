import { Command } from '@/app/common/base/command'
import { Bankguarantee } from '@/app/bankguarantee/entities/bankguarantee.entity'

export class GetAllBankguaranteesCommand extends Command {
    public async run() {
        const response = await this.client.get('/api/products/bankguarantees')
        const bankguarantees = response.asEntities(Bankguarantee)

        await this.store.dispatch('bankguarantee/setBankguarantees', bankguarantees)
    }
}
