
    import { Component, Inject, Vue } from 'vue-property-decorator'
    import Layout from '../../common/components/layout/layout.vue'
    import { mapState } from 'vuex'
    import { WillWritingAdvice } from '@/app/will_writing_advice/entities/will-writing-advice.entity'
    import { GetAllWillWritingAdvicesCommand } from '@/app/will_writing_advice/commands/get-all-will-writing-advices.command'

    @Component({
        components: { Layout },
        metaInfo() {
            return {
                title: 'Testamenten'
            }
        },
        computed: {
            ...mapState('willWritingAdvice', ['willWritingAdvices'])
        }
    })
    export default class WillWritingAdviceList extends Vue {
        willWritingAdvices!: WillWritingAdvice[]

        @Inject() getAllWillWritingAdvicesCommand!: GetAllWillWritingAdvicesCommand

        mounted() {
            this.getAllWillWritingAdvicesCommand.run()
        }
    }
