
    import { mapGetters, mapState } from 'vuex'
    import { Component, Vue, Inject } from 'vue-property-decorator'
    import Layout from '../../common/components/layout/layout.vue'
    import DatePicker from '@/app/common/components/date-picker.vue'
    import CurrencyField from '@/app/common/components/currency-field.vue'
    import { NotificationDispatcher } from '@/app/common/services/notification-dispatcher.service'
    import SimpleTable from '@/app/common/components/simple-table.vue'
    import { GetAllActionsCommand } from '@/app/product/commands/get-all-actions.command'
    import { presentSmartcareSavingsPeriods } from '@/app/product/support/smartcare-saving-period'
    import validation from '@/app/common/helpers/validation'
    import { VForm } from '@/app/common/types/v-form.type'
    import { Consumer } from '@/app/identity'
    import {
        UpdatePresentOpportunityToConsumerCommand
    } from '@/app/mortgage/commands/update-present-opportunity-to-consumer-command'
    import { ProductAction } from '@/app/product/entities/product-action.entity'
    import { GetAllMortgagesCommand } from '@/app/mortgage/commands/get-all-mortgages.command'
    import { Mortgage as MortgageEntity } from '@/app/mortgage/entities/mortgage.entity'
    import { smartcareExplainParts, translatePeriodText, translateFrontText } from '@/app/product/support/parse-smartcare-explain'

    @Component({
        methods: {
            smartcareSavingPeriods() {
                return presentSmartcareSavingsPeriods.map(period => ({
                    text: this.$t(period.text as string).toString(),
                    value: period.value
                }))
            }
        },
        components: { Layout, DatePicker, CurrencyField, SimpleTable },
        metaInfo() {
            return {
                title: 'Saving Opportunity'
            }
        },
        computed: {
            ...mapState('product', ['productActions']),
            ...mapState('auth', ['consumer']),
            ...mapGetters('product', ['getByProductActionId']),
            ...mapGetters('mortgage', ['getByMortgageId']),
            ...mapState('mortgage', ['mortgages'])
        }
    })
    export default class PresentOpportunityToConsumer extends Vue {
        response = ''
        responseTypes = {
            yes: 'yes',
            futureDate: 'future_date',
            customAmount: 'custom_amount',
            no: 'no'
        }

        mortgage: MortgageEntity | null = null
        getByMortgageId!: (id: string) => MortgageEntity | undefined
        mortgages!: MortgageEntity[]

        productCustomAmount: number | null = null
        productCustomPeriod: string | null = null

        productActions!: ProductAction[]
        getByProductActionId!: (id: string) => ProductAction | undefined
        productAction: ProductAction | null = null
        consumer!: Consumer
        saving = false

        customAmount: number | null = null
        customPeriod: string | null = null
        futureDate: string | null = null
        rules = validation

        dissectedExplain: ReturnType<typeof smartcareExplainParts> | null = null

        @Inject() getAllMortgagesCommand!: GetAllMortgagesCommand
        @Inject() updatePresentOpportunityToConsumerCommand!: UpdatePresentOpportunityToConsumerCommand
        @Inject() notificationDispatcher!: NotificationDispatcher
        @Inject() getAllActionsCommand!: GetAllActionsCommand

        async mounted() {
            await this.getAllMortgages()
            const id = this.$route.params.id
            this.mortgage = this.getByMortgageId(id) ?? null

            await this.getAllProductActions()
            const actionId = this.$route.params.action

            this.productAction = this.getByProductActionId(actionId) ?? null
            this.productCustomAmount = this.productAction?.smartcareMinSavingsAmount ?? null
            this.productCustomPeriod = this.productAction?.smartcareSavingPeriod ?? null
            this.customAmount = this.customAmount ?? this.productCustomAmount
            this.customPeriod = this.customPeriod ?? this.productCustomPeriod
            this.futureDate = this.futureDate ?? null

            const rawSmartcareExplain = this.productAction?.smartcareExplain || ''
            this.dissectedExplain = smartcareExplainParts(rawSmartcareExplain)
        }

        get translatedPeriodText() {
            if (!this.dissectedExplain) {
                return ''
            }
            return this.$t(translatePeriodText(this.dissectedExplain.periodText)).toString()
        }

        get translatedFrontText() {
            if (!this.dissectedExplain) {
                return ''
            }
            return this.$t(translateFrontText(this.dissectedExplain.frontText)).toString()
        }

        async getAllMortgages() {
            if (!this.mortgages.length) {
                await this.getAllMortgagesCommand.run()
            }
        }

        async getAllProductActions() {
            if (!this.productActions.length) {
                await this.getAllActionsCommand.run()
            }
        }

        get form(): VForm {
            return this.$refs.form as VForm
        }

        validate(): boolean {
            const validated = this.form.validate()
            if (!validated) {
                this.notificationDispatcher.error(this.$t('validation.error.all_required_fields_must_be_filled').toString())
            }

            return validated
        }

        async save() {
            try {
                this.saving = true
                const successMessageKey = this.consumer.hasFormalAddressStyle()
                    ? 'mortgage.present_opportunity_to_consumer_notification.success.formal'
                    : 'mortgage.present_opportunity_to_consumer_notification.success.informal'

                await this.updatePresentOpportunityToConsumerCommand.run(this.mortgage!.id, this.productAction!.id, this.response, this.customAmount ?? 0, this.customPeriod ?? '', this.futureDate ?? '')
                await this.notificationDispatcher.success(this.$t(successMessageKey).toString())
                await this.getAllActionsCommand.run()
                this.$router.back()
            } catch (err) {
                await this.notificationDispatcher.error(err.message)
            } finally {
                this.saving = false
            }
        }

    }
