import { Command } from '@/app/common/base/command'
import { CardifAo } from '@/app/cardifao/entities/cardifao'

export class GetAllCardifAosCommand extends Command {

    public async run() {
        const response = await this.client.get('/api/products/cardifao')
        const cardifAos = response.asEntities(CardifAo)

        await this.store.dispatch('cardifAo/setCardifAos', cardifAos)
    }

}
