import { ActionTree, Commit } from 'vuex'
import { DossierState } from '@/app/dossier/store/state'
import { RootState } from '@/app/common/types/root-state.type'
import { Dossier } from '@/app/dossier/entities/dossier.entity'

export const actions: ActionTree<DossierState, RootState> = {

    setDossier({ commit }: { commit: Commit }, dossier: Dossier) {
        commit('setDossier', dossier)
    }

}
