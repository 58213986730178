import { render, staticRenderFns } from "./iwize-form.vue?vue&type=template&id=dd2d3174&scoped=true"
import script from "./iwize-form.vue?vue&type=script&lang=ts"
export * from "./iwize-form.vue?vue&type=script&lang=ts"
import style0 from "./iwize-form.vue?vue&type=style&index=0&id=dd2d3174&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd2d3174",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard})
