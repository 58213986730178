import { Command } from '@/app/common/base/command'
import { PaymentStatus } from '@/app/invoice/entities/payment-status.entity'

export class ValidateInvoicePaymentCommand extends Command {
    public async run(
        { productId, productActionId, invoiceId }:
        { productId: string, productActionId: string, invoiceId: string }
    ): Promise<PaymentStatus> {
        const response = await this.client.post('/api/invoices/validate-payment', {
            productId,
            productActionId,
            invoiceId
        })

        return response.asEntity(PaymentStatus)
    }
}
