
    import { Component, Inject, Prop, Vue } from "vue-property-decorator"
    import { VForm } from "@/app/common/types/v-form.type"
    import validation from "@/app/common/helpers/validation"
    import { NotificationDispatcher } from "@/app/common/services/notification-dispatcher.service"
    import { GetPartnerDataCommand } from "@/app/inventory-form/commands/get-partner-data.command"
    import { Consumer } from "@/app/identity"
    import { mapState } from "vuex"

    @Component({
        components: {},
        computed: {
            ...mapState('auth', ['consumer'])
        },
        metaInfo() {
            return {
                title: 'Passing away'
            }
        }
    })
    export default class AdviceForm extends Vue {
        formData = {
            customer: [
                { inHomeAfterDeath: null, netDisposableIncomeDeath: null, inHomeAfterDisability: null, netDisposableIncomeDisability: null, inHomeAfterUnemployment: null, netDisposableIncomeUnemployment: null },
                { inHomeAfterDeath: null, netDisposableIncomeDeath: null, inHomeAfterDisability: null, netDisposableIncomeDisability: null, inHomeAfterUnemployment: null, netDisposableIncomeUnemployment: null }
            ]
        }
        @Inject() notificationDispatcher!: NotificationDispatcher
        @Inject() getPartnerDataCommand!: GetPartnerDataCommand
        couple: Consumer|null = null
        consumer!: Consumer
        rules = validation
        @Prop({ default: () => ({}) }) readonly externalFormData!: any
        async mounted() {
            this.couple = await this.getPartnerDataCommand.run()
            if (this.externalFormData.customer && this.externalFormData.customer.length >= 2) {
                this.formData.customer.forEach((item, index) => {
                    this.formData.customer[index] = { ...item, ...this.externalFormData.customer[index] }
                })
            }
        }
        get form(): VForm {
            return this.$refs.form as VForm
        }
        validate(): boolean {
            return this.form.validate()
        }
        goToPreviousStep() {
            this.$emit('changeStep', 7)
        }
        goToNextStep() {
            if (this.validate()) {
                this.$emit('updateData', this.formData)
                this.$emit('changeStep', 9)
            }
        }
    }
