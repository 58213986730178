
    import { Component, Inject, Vue } from 'vue-property-decorator'
    import Layout from '@/app/common/components/layout/layout.vue'
    import { GetAllBusinessIncomesCommand } from '@/app/business-income/commands/get-all-business-incomes.command'
    import { mapGetters, mapState } from 'vuex'
    import { BusinessIncome as BusinessIncomeEntity } from '@/app/business-income/entities/business-income.entity'
    import ProductLayout from '@/app/product/components/product-layout.vue'
    import BusinessIncomeOverview from '@/app/business-income/components/business-income-overview.vue'

    @Component({
        components: { BusinessIncomeOverview, ProductLayout, Layout },
        metaInfo() {
            return {
                title: 'Inkomensverklaring'
            }
        },
        computed: {
            ...mapState('businessIncome', ['businessIncomes']),
            ...mapGetters('businessIncome', ['getByBusinessIncomeId'])
        }
    })
    export default class BusinessIncome extends Vue {
        businessIncomes!: BusinessIncomeEntity[]
        businessIncome: BusinessIncomeEntity | null = null
        getByBusinessIncomeId!: (id: string) => BusinessIncomeEntity | undefined

        @Inject() getAllBusinessIncomesCommand!: GetAllBusinessIncomesCommand

        async mounted() {
            if (!this.businessIncomes.length) {
                await this.getAllBusinessIncomesCommand.run()
            }

            this.businessIncome = this.getByBusinessIncomeId(this.$route.params.id) ?? null
        }
    }
