
    import { Vue, Component, Inject } from 'vue-property-decorator'
    import { ConfirmResetTokenCommand } from '../commands/confirm-reset-token.command'
    import { ResetPasswordCommand } from '../commands/reset-password.command'
    import { NotificationDispatcher } from '../../common/services/notification-dispatcher.service'
    import { VForm } from '../../common/types/v-form.type'
    import TopMenu from '@/app/common/components/layout/top-menu.vue'
    import zxcvbn from 'zxcvbn'

    @Component({
        components: { TopMenu },
        metaInfo() {
            return {
                title: 'Reset Password'
            }
        }
    })
    export default class ResetPassword extends Vue {
        score = 0
        password = ''
        progress = 0
        color = 'red'
        message = '';
        repeatPassword = ''
        showPassword = false
        loading = false
        shouldCheckGuidelines = false
        isPasswordStrongEnough = false
        passWordsMatch = true

        @Inject() resetPasswordCommand!: ResetPasswordCommand
        @Inject() confirmResetTokenCommand!: ConfirmResetTokenCommand
        @Inject() notificationDispatcher!: NotificationDispatcher

        get form(): VForm {
            return this.$refs.form as VForm
        }

        checkGuidelines() {
            this.shouldCheckGuidelines = true

            const score = zxcvbn(this.password).score

            this.passWordsMatch = this.password === this.repeatPassword
            this.isPasswordStrongEnough = score >= this.score && this.passWordsMatch
            this.progress = (score / this.score) * 100

            if (this.progress < 40) {
                this.color = 'red'
                this.message = 'page.newpassword.strength.weak'
            } else if (this.progress < 100) {
                this.color = 'yellow'
                this.message = 'page.newpassword.strength.average'
            } else {
                this.color = 'light-green'
                this.message = 'page.newpassword.strength.strong'
            }
        }

        passesValidation(): boolean {
            return this.isPasswordStrongEnough
        }

        async reset() {
            try {
                this.loading = true
                await this.resetPasswordCommand.run(this.$route.params.token, this.password)
                await this.notificationDispatcher.success(this.$t('page.newpassword.notifications.passwordsaved.body').toString())
                await this.$router.push({ name: 'login' })
            } catch (err) {
                await this.notificationDispatcher.error(err.message)
            } finally {
                this.loading = false
            }
        }

        validate() {
            return this.form.validate()
        }

        async created() {
            try {
                this.score = (await this.confirmResetTokenCommand.run(this.$route.params.token)).score
            } catch (err) {
                await this.notificationDispatcher.error(err.message)
                await this.$router.push({ name: 'login' })
            }
        }
    }
