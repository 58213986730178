import { GetterTree } from 'vuex'
import { RootState } from '@/app/common/types/root-state.type'
import { ProductState } from './state'
import { ProductAction } from '@/app/product/entities/product-action.entity'
import { downloadTypes } from '@/app/product/support/download-type'
import { approveTypes } from '@/app/product/support/approve-type'

export const getters: GetterTree<ProductState, RootState> = {
    openProductActions(state: ProductState): ProductAction[] {
        return state.productActions.filter((productAction: ProductAction) => !productAction.isDone)
    },

    productActionsGroupedByProduct(state: ProductState) {
        const productActions = state.productActions.filter(
            (p: ProductAction) => downloadTypes.indexOf(p.type) === -1
        ) as ProductAction[]
        const grouped = productActions.reduce((acc: any, productAction: ProductAction) => {
            if (acc[productAction.product.id]) {
                acc[productAction.product.id].actions.push(productAction)
            } else {
                acc[productAction.product.id] = {
                    product: productAction.product,
                    actions: [productAction]
                }
            }

            return acc
        }, {})

        return Object.values(grouped)
    },

    downloadableProductActionsGroupedByProduct(state: ProductState) {
        const productActions = state.productActions.filter(
            p => downloadTypes.includes(p.type) || (approveTypes.includes(p.type) && p.isDone)
        ) as ProductAction[]

        const grouped = productActions.reduce((acc: any, productAction: ProductAction) => {
            if (acc[0]) {
                acc[0].actions.push(productAction)
            } else {
                acc[0] = {
                    product: productAction.product,
                    actions: [productAction]
                }
            }

            return acc
        }, {})
        return Object.values(grouped)
    },

    downloadableProductActionsByProductId(state: ProductState): (id: string) => ProductAction[] {
        return (id: string) => {
            return state.productActions.filter(
                p => (downloadTypes.includes(p.type) || (approveTypes.includes(p.type) && p.isDone)) && p.product.id === id
            )
        }
    },

    getByProductActionId(state: ProductState): (id: string) => ProductAction | undefined {
        return (id: string) => {
            const actions = state.productActions.filter(a => a.id === id)
            return actions?.[0]
        }
    },

    productActionsByProductId(state: ProductState, otherGetters: any): (id: string) => ProductAction[] {
        return (id: string) => {
            const productActions = otherGetters.productActionsGroupedByProduct.filter((action: ProductAction) => {
                return action.product.id === id
            })

            return productActions?.[0]?.actions ?? []
        }
    },
}
