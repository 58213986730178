import { Expose } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'

export class Smartcare extends Entity<Smartcare> {
    @Expose({ name: 'minimum_savings_amount' })
    minimumSavingsAmount!: number

    @Expose({ name: 'minimum_savings_period' })
    minimumSavingsPeriod!: string

    @Expose({ name: 'abstain_from_calculation_till' })
    abstainFromCalculationTill!: string
}
