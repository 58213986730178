import { Expose, Type } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'
import { ProductActionCounter } from '@/app/product/entities/product-action-counter.entity'
import moment from 'moment'

export class Appointment extends Entity<Appointment> {
    @Expose()
    id!: string

    @Expose()
    label!: string

    @Expose()
    type!: string

    @Expose({ name: 'start_time' })
    startTime!: string

    @Expose({ name: 'end_time' })
    endTime!: string

    @Expose()
    date!: string

    @Expose()
    location!: string

    @Expose({ name: 'advisor_name' })
    advisorName!: string

    @Expose({ name: 'is_true_meeting' })
    isTrueMeeting!: boolean

    @Expose({ name: 'true_meeting_url' })
    trueMeetingURL!: string

    @Expose({ name: 'is_google_meet' })
    isGoogleMeet!: boolean

    @Expose({ name: 'google_meet_url' })
    googleMeetUrl!: string

    @Expose({ name: 'is_teams' })
    isTeams!: boolean

    @Expose({ name: 'teams_url' })
    teamsUrl!: string

    @Expose({ name: 'edit_url' })
    editUrl!: string

    @Expose({ name: 'cancel_url' })
    cancelUrl!: string

    @Expose({ name: 'action_counter' })
    @Type(() => ProductActionCounter)
    actionCounter!: ProductActionCounter

    get fullStartDate(): string {
        if (this.startTime !== undefined && this.startTime !== '') {
            return `${this.date} ${this.startTime}`
        }

        return this.date
    }

    isInThePast(): boolean {
        return moment(this.date).isBefore(moment())
    }

    isInTheFuture(): boolean {
        return moment(this.fullStartDate).isAfter(moment())
    }
}
