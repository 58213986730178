
    import { Vue, Component, Inject } from 'vue-property-decorator'
    import { GetLicensesCommand } from '@/app/license/commands/get-licenses.command'
    import { DomainLicense } from '@/app/license/entities/domain-license.entity'
    import { NotificationDispatcher } from '@/app/common/services/notification-dispatcher.service'
    import { SetDomainCommand } from '@/app/license/commands/set-domain.command'
    import { LogoutCommand } from '@/app/auth/commands/logout.command'


    @Component({
        metaInfo() {
            return {
                title: 'Kies uw vestiging'
            }
        },
    })
    export default class DomainChooser extends Vue {
        domainLicenses: { text: string, value: string }[] = []
        loading = false
        selectedDomain: string|null = null

        @Inject() notificationDispatcher!: NotificationDispatcher
        @Inject() getLicensesCommand!: GetLicensesCommand
        @Inject() setDomainCommand!: SetDomainCommand
        @Inject() logoutCommand!: LogoutCommand

        async created() {
            try {
                this.loading = true
                this.domainLicenses = (await this.getLicensesCommand.run()).map((domainLicense: DomainLicense) => {
                    return {
                        text: domainLicense.name,
                        value: domainLicense.domain
                    }
                })
            } catch (err) {
                await this.notificationDispatcher.error(err.message)
            } finally {
                this.loading = false
            }
        }

        async setDomain() {
            if (this.selectedDomain) {
                await this.logoutCommand.run()
                await this.setDomainCommand.run(this.selectedDomain)
            }
        }
    }
