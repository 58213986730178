
    import { Component, Vue, Prop } from 'vue-property-decorator'
    import { MortgageTimeline } from '@/app/mortgage/entities/mortgage-timeline.entity'
    import localizeContent from '@/app/common/helpers/localize-content'

    @Component
    export default class MortgageStatus extends Vue {
        @Prop({ type: Array, required: true }) timeline!: MortgageTimeline[]

        localizeContent = localizeContent
    }
