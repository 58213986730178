import { ActionTree, Commit } from 'vuex'
import { RootState } from '@/app/common/types/root-state.type'
import { GeneralInsuranceState } from '@/app/general-insurance/store/state'
import { GeneralInsurance } from '@/app/general-insurance/entities/general-insurance.entity'

export const actions: ActionTree<GeneralInsuranceState, RootState> = {

    setGeneralInsurances({ commit }: { commit: Commit }, generalInsurances: GeneralInsurance[]) {
        commit('setGeneralInsurances', generalInsurances)
    }

}
