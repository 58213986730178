import { Command } from '@/app/common/base/command'
import { Maintenance } from '@/app/maintenance/entities/maintenance.entity'

export class GetMaintenanceCommand extends Command {
    public async run() {
        const response = await this.client.get('/api/maintenance')
        const maintenance = response.asEntity(Maintenance)

        await this.store.dispatch('maintenance/setMaintenance', maintenance)
    }
}
