import { Command } from "@/app/common/base/command"
import { Mortgage } from "@/app/mortgage/entities/mortgage.entity"
import { ProductActionAuthEntity } from "@/app/auth/entities/product-action-auth.entity"

export class MortgageDataNoLoginCommand extends Command {
    public async run(productActionId: string, auth: ProductActionAuthEntity): Promise<Mortgage> {
        const response = await this.client.get(`/api/products/mortgage-no-login/${productActionId}/${auth.id}`, {
            headers: {
                'Token': auth.token
            }
        })

        return response.asEntity(Mortgage)
    }
}
