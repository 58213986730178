import { Expose, Type } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'
import { DamageReport } from '@/app/general-insurance/entities/damage-report.entity'
import { ProductActionCounter } from '@/app/product/entities/product-action-counter.entity'

export class GeneralInsurance extends Entity<GeneralInsurance> {
    @Expose()
    id!: string

    @Expose({ name: 'product_id' })
    productId!: string

    @Expose()
    label!: string

    @Expose()
    state!: string

    @Expose()
    premium!: number

    @Expose({ name: 'policy_number' })
    policyNumber!: string

    @Expose({ name: 'payment_period' })
    paymentPeriod!: number

    @Expose({ name: 'date_start' })
    dateStart!: string

    @Expose({ name: 'company_name' })
    companyName!: string

    @Expose({ name: 'insurance_type' })
    insuranceType!: string

    @Expose({ name: 'show_damage_report_button' })
    showDamageReportButton!: boolean

    @Expose({ name: 'damage_reports' })
    @Type(() => DamageReport)
    damageReports!: DamageReport[]

    @Expose({ name: 'action_counter' })
    @Type(() => ProductActionCounter)
    actionCounter!: ProductActionCounter
}
