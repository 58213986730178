
    import { Vue, Component, Inject } from 'vue-property-decorator'
    import { LogoutCommand } from '../commands/logout.command'

    @Component
    export default class Logout extends Vue {
        @Inject() logoutCommand!: LogoutCommand

        async created() {
            await this.logoutCommand.run()
            await this.$router.push({ name: 'login' })
        }
    }
