import { Command } from '@/app/common/base/command'
import { Language } from '@/app/license/entities/language.entity'

export class GetLanguagesCommand extends Command {
    public async run(): Promise<void> {
        const response = await this.client.get('/api/languages')
        const languages = response.asEntities(Language)

        this.store.dispatch('license/setLanguages', languages)
    }
}
