import { Expose } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'

export class ProductDefinitionMeta extends Entity<ProductDefinitionMeta> {
    @Expose()
    counter!: number

    @Expose({ name: 'open_actions' })
    openActions!: number

    @Expose({ name: 'first_product_id' })
    firstProductId!: string
}
