
    import { Vue, Component } from 'vue-property-decorator'
    import TopMenu from './top-menu.vue'

    @Component({
        components: { TopMenu }
    })
    export default class NoLoginLayout extends Vue {

    }
