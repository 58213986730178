
    import { mapState } from 'vuex'
    import { Vue, Component, Inject } from 'vue-property-decorator'
    import Notifications from './common/components/notifications/notifications.vue'
    import { HttpClient } from './common/services/http/http-client.interface'
    import { Maintenance as MaintenanceEntity } from '@/app/maintenance/entities/maintenance.entity'
    import { GetMaintenanceCommand } from '@/app/maintenance/commands/get-maintenance.command'
    import { GetCurrentLicenseCommand } from '@/app/license/commands/get-current-license-command'
    import Maintenance from '@/app/maintenance/components/maintenance.vue'
    import DomainChooser from '@/app/domain-chooser/components/domain-chooser.vue'
    import { GetMultipleDomainsCommand } from '@/app/domain-chooser/commands/get-multiple-domains.command'
    import { NotificationDispatcher } from '@/app/common/services/notification-dispatcher.service'
    import authorizationMiddleware from '@/app/common/middleware/authorization'
    import refreshTokenMiddleware from '@/app/common/middleware/refresh-token'
    import NetworkIndicator from '@/app/common/components/network-indicator.vue'
    import { License } from '@/app/license/entities/license.entity'
    import { Language } from '@/app/license/entities/language.entity'
    import { GetLanguagesCommand } from '@/app/license/commands/get-languages.command'
    import { SetDomainCommand } from '@/app/license/commands/set-domain.command'
    import { GetThemeCommand } from '@/app/theme/commands/get-theme.command'
    import { Theme } from '@/app/theme/entities/theme.entity'
    import GoogleFontResolver from '@/app/common/services/google-font-resolver.service'
    import { GetDictionaryCommand } from '@/app/dictionary/commands/get-dictionary.command'
    import { Dictionary } from '@/app/dictionary/entities/dictionary.entity'
    import { GetLicensesCommand } from '@/app/license/commands/get-licenses.command'

    @Component({
        components: { DomainChooser, Notifications, Maintenance, NetworkIndicator },
        computed: {
            ...mapState('maintenance', ['maintenance']),
            ...mapState('license', ['domain', 'license', 'languages']),
            ...mapState('global', ['networkLoading', 'locale'])
        },
        metaInfo() {
            return {
                titleTemplate: (title: string) => {
                    return title ? `${title} - Consumer Portal` : 'Consumer Portal'
                },
                htmlAttrs: {
                    lang: this.$i18n.locale
                },
                meta: [
                    { charset: 'utf-8' }
                ]
            }
        }
    })
    export default class AppComponent extends Vue {
        maintenance!: MaintenanceEntity
        domain!: string
        license!: License
        languages!: Language[]
        locale?: string
        networkLoading!: boolean
        showDomainChooser = false
        theme?: Theme
        themeFontSize = 16
        themeDefaultFontFamily = 'Roboto'
        googleFontImportUrl = ''
        googleFontFamily = ''
        themeColorMenuBackground = this.$vuetify.theme.themes.light.primary
        themeColorMenuActive = this.$vuetify.theme.themes.light.accent
        themeColorFooterBackground = this.$vuetify.theme.themes.light.primary
        themeColorMenuText = '#ffffff'
        themeColorFooterText = '#ffffff'
        themeColorButtons = this.$vuetify.theme.themes.light.primary
        themeColorLinks = this.$vuetify.theme.themes.light.primary
        themeColorHeaders = '#000'
        themeColorSubheaders = '#000'
        themeColorSmallHeaders = '#000'

        @Inject() httpClient!: HttpClient
        @Inject() getMaintenanceCommand!: GetMaintenanceCommand
        @Inject() getLicensesCommand!: GetLicensesCommand
        @Inject() getCurrentLicenseCommand!: GetCurrentLicenseCommand
        @Inject() getMultipleDomainsCommand!: GetMultipleDomainsCommand
        @Inject() getLanguagesCommand!: GetLanguagesCommand
        @Inject() setDomainCommand!: SetDomainCommand
        @Inject() getThemeCommand!: GetThemeCommand
        @Inject() notificationDispatcher!: NotificationDispatcher
        @Inject() getDictionaryCommand!: GetDictionaryCommand

        async created() {
            this.registerMiddleware()

            await this.getDictionary()
            await this.checkDomainParameter()
            await this.applyTheme()
            await this.getLicenseData()
            await this.getLanguages()

            await this.checkMaintenance()
            await this.checkMultipleDomains()
        }

        registerMiddleware() {
            this.httpClient.getClient().interceptors.request.use((config: any) => {
                this.$store.dispatch('global/showNetworkLoader')
            return config
            })
            this.httpClient.getClient().interceptors.request.use(authorizationMiddleware())

            this.httpClient.getClient().interceptors.response.use((response: any) => {
                this.$store.dispatch('global/hideNetworkLoader')
                return response
            }, refreshTokenMiddleware(async () => {
                await this.$router.push({ name: 'login' })
                await this.notificationDispatcher.error(this.$t('messages.expired').toString())
            }))
        }

        async checkDomainParameter() {
            const domain = this.$route.query.domain as string|undefined

            if (domain !== undefined) {
                await this.setDomainCommand.run(domain)
                const queryParams = { ...this.$route.query }
                delete queryParams.domain
                await this.$router.push({ name: this.$route.name as string, params: this.$route.params, query: queryParams })
            }
        }

        async checkMultipleDomains() {
            this.showDomainChooser = await this.getMultipleDomainsCommand.run()
        }

        async checkMaintenance() {
            await this.getMaintenanceCommand.run()
        }

        async getLanguages() {
            await this.getLanguagesCommand.run()
        }

        async getLicenseData() {
            await this.getCurrentLicenseCommand.run()
        }

        async getDictionary() {
            const translations = await this.getDictionaryCommand.run()
            const localeMessages: { [key: string]: { [key: string]: string } | undefined } = {}

            translations.forEach((item: Dictionary) => {
                const translationsObject = JSON.parse(item.translation)

                Object.keys(translationsObject).forEach((locale: string) => {
                    if (localeMessages[locale] === undefined) {
                        localeMessages[locale] = {}
                    }

                    localeMessages[locale]![item.id] = translationsObject[locale]
                })
            })

            Object.keys(localeMessages).forEach((locale: string) => {
                this.$i18n.setLocaleMessage(locale, localeMessages[locale]!)
            })

        }

        async applyTheme() {
            this.theme = await this.getThemeCommand.run()

            this.$vuetify.theme.themes.light.primary = this.theme.colorPrimary ?? this.$vuetify.theme.themes.light.primary
            this.$vuetify.theme.themes.light.accent = this.theme.colorAccent ?? this.$vuetify.theme.themes.light.accent
            this.themeColorButtons = this.theme.colorButtons ?? this.themeColorButtons
            this.themeColorLinks = this.theme.colorLinks ?? this.themeColorLinks
            this.themeColorHeaders = this.theme.colorHeaders ?? this.themeColorHeaders
            this.themeColorSubheaders = this.theme.colorSubheaders ?? this.themeColorSubheaders
            this.themeColorSmallHeaders = this.theme.colorSmallHeaders ?? this.themeColorSmallHeaders
            this.themeFontSize = this.theme.fontFontSize ?? this.themeFontSize
            this.themeColorMenuBackground = this.theme.colorMenuBackground ?? this.themeColorMenuBackground
            this.themeColorMenuActive = this.theme.colorMenuActive ?? this.themeColorMenuActive
            this.themeColorMenuText = this.theme.colorMenuText ?? this.themeColorMenuText
            this.themeColorFooterBackground = this.theme.colorFooterBackground ?? this.themeColorFooterBackground
            this.themeColorFooterText = this.theme.colorFooterText ?? this.themeColorFooterText

            const googleFontResolver = new GoogleFontResolver(this.theme.fontFontFamily ?? this.themeDefaultFontFamily)
            this.googleFontFamily = googleFontResolver.getCssFontFamily()
            this.googleFontImportUrl = googleFontResolver.getImportUrl()
        }
    }
