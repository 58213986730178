import { Expose, Type } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'
import { ProductDefinitionMeta } from '@/app/product/entities/product-definition-meta.entity'

export class ProductDefinition extends Entity<ProductDefinition> {
    @Expose()
    @Type(() => ProductDefinitionMeta)
    dossier!: ProductDefinitionMeta

    @Expose()
    @Type(() => ProductDefinitionMeta)
    mortgage!: ProductDefinitionMeta

    @Expose()
    @Type(() => ProductDefinitionMeta)
    credit!: ProductDefinitionMeta

    @Expose({ name: 'lifeinsurance' })
    @Type(() => ProductDefinitionMeta)
    lifeInsurance!: ProductDefinitionMeta

    @Expose({ name: 'general_insurance' })
    @Type(() => ProductDefinitionMeta)
    generalInsurance!: ProductDefinitionMeta

    @Expose()
    @Type(() => ProductDefinitionMeta)
    appointment!: ProductDefinitionMeta

    @Expose()
    @Type(() => ProductDefinitionMeta)
    otd!: ProductDefinitionMeta

    @Expose()
    @Type(() => ProductDefinitionMeta)
    bankguarantee!: ProductDefinitionMeta

    @Expose()
    @Type(() => ProductDefinitionMeta)
    fgv!: ProductDefinitionMeta

    @Expose()
    @Type(() => ProductDefinitionMeta)
    cardifao!: ProductDefinitionMeta

    @Expose({ name: 'purchase_intention' })
    @Type(() => ProductDefinitionMeta)
    purchaseIntention!: ProductDefinitionMeta

    @Expose()
    @Type(() => ProductDefinitionMeta)
    invoice!: ProductDefinitionMeta

    @Expose({ name: 'business_income' })
    @Type(() => ProductDefinitionMeta)
    businessIncome!: ProductDefinitionMeta

    @Expose({ name: 'will_writing_advice' })
    @Type(() => ProductDefinitionMeta)
    willWritingAdvice!: ProductDefinitionMeta
}
