import { Expose } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'

export class IncomeType extends Entity<IncomeType> {
    @Expose()
    code!: string

    @Expose()
    label!: string
}
