
    import { mapState } from 'vuex'
    import { Component, Vue, Inject } from 'vue-property-decorator'
    import Layout from '../../common/components/layout/layout.vue'
    import getQuestions from '@/app/knowledge-form/support/knowledge-form-questions'
    import { SubmitKnowledgeFormCommand } from '@/app/knowledge-form/commands/submit-knowledge-form.command'
    import { NotificationDispatcher } from '@/app/common/services/notification-dispatcher.service'
    import SimpleTable from '@/app/common/components/simple-table.vue'
    import { Consumer } from '@/app/identity'
    import i18n from '@/plugins/i18n'

    @Component({
        components: { Layout, SimpleTable },
        computed: {
            ...mapState('auth', ['consumer'])
        },
        metaInfo() {
            return {
                title: 'Knowledge form'
            }
        }
    })
    export default class KnowledgeForm extends Vue {
        consumer!: Consumer
        questions = {}
        loading = false
        selections: { [key: string]: string } = {}

        @Inject() submitKnowledgeFormCommand!: SubmitKnowledgeFormCommand
        @Inject() notificationDispatcher!: NotificationDispatcher

        updateSelection(question: string, answer: string) {
            this.selections[question] = answer
        }
        mounted() {
            this.questions = getQuestions()

            this.$watch(() => i18n.locale, () => {
                this.questions = getQuestions()
            })
        }

        async submitKnowledgeForm() {
            try {
                this.loading = true
                const productActionId = this.$route.params.action

                await this.submitKnowledgeFormCommand.run(this.selections, productActionId)
                await this.$router.push({ name: 'dashboard' })
                await this.notificationDispatcher.success(this.$t('page.knowledge_form.submit_success').toString())
            } catch (err) {
                await this.notificationDispatcher.error(err.message)
            } finally {
                this.loading = false
            }
        }
    }
