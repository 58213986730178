import { Command } from '@/app/common/base/command'
import { Invoice } from '@/app/invoice/entities/invoice.entity'

export class GetAllInvoicesCommand extends Command {
    public async run() {
        const response = await this.client.get('/api/invoices')
        const invoices = response.asEntities(Invoice)

        await this.store.dispatch('invoice/setInvoices', invoices)
    }
}
