import { MutationTree } from 'vuex'
import { MortgageState } from '@/app/mortgage/store/state'
import { Mortgage } from '@/app/mortgage/entities/mortgage.entity'

export const mutations: MutationTree<MortgageState> = {

    setMortgages(state: MortgageState, mortgages: Mortgage[]) {
        state.mortgages = mortgages
    }

}
