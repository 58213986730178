import { Command } from '@/app/common/base/command'
import { ProductPhase } from '@/app/api-entity/entities/product-phase.entity'

export class GetAllProductPhasesCommand extends Command {
    public async run() {
        const response = await this.client.get('/api/entities/product-phases')
        const productPhases = response.asEntities(ProductPhase)

        await this.store.dispatch('apiEntity/setProductPhases', productPhases)
    }
}
