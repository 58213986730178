import { GetterTree } from 'vuex'
import { RootState } from '@/app/common/types/root-state.type'
import { ImportantDocumentState } from './state'
import { Document } from '@/app/important-documents/entities/document.entity'

export const getters: GetterTree<ImportantDocumentState, RootState> = {
    documents(state: ImportantDocumentState): Document[] {
        return state.documents
    },

    documentsByCategory(state: ImportantDocumentState) {
        return state.documentsByCategory
    }
}
