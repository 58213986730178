import { Consumer } from '@/app/identity'

export interface AuthState {
    consumer?: Consumer
    accessToken?: string
    refreshToken?: string
    pendingCredentials?: { consumer?: Consumer, accessToken?: string, refreshToken?: string }
    tempLogin?: { id?: string, token?: string }
}

export const state: AuthState = {
    consumer: undefined,
    accessToken: undefined,
    refreshToken: undefined,
    pendingCredentials: {},
    tempLogin: {}
}
