import { GetterTree } from 'vuex'
import { RootState } from '@/app/common/types/root-state.type'
import { AuthState } from './state'

export const getters: GetterTree<AuthState, RootState> = {
    isAuthenticated: (state: AuthState) => {
        return state.accessToken !== undefined
    },

    tempLogin: (state: AuthState) => {
        return state.tempLogin
    },

    consumer: (state: AuthState) => {
        return state.consumer
    }
}
