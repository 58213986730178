var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('layout',[_c('v-card',{staticClass:"mb-8",attrs:{"elevation":"3"}},[_c('v-card-title',{staticClass:"mainHeading"},[_vm._v(_vm._s(_vm.$t('page.overview.title')))]),_c('v-card-text',[(_vm.otds && _vm.otds.length > 1)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('otd.list.overview_body.plural'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('otd.list.overview_body.singular'))+" ")])])],1),(!_vm.otds.length)?_c('v-skeleton-loader',{staticClass:"mb-3",attrs:{"type":"card-heading","elevation":"3"}}):_vm._e(),(!_vm.otds.length)?_c('v-skeleton-loader',{attrs:{"type":"card-heading","elevation":"3"}}):_vm._e(),(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-card',{staticClass:"mb-1",attrs:{"elevation":"2"}},[_c('v-row',{staticClass:"mb-2 d-flex flex-wrap font-weight-black"},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"8"}},[_c('span',{staticClass:"ms-4 header-label"},[_vm._v(" "+_vm._s(_vm.$t('page.otd.title'))+" ")])]),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"2"}},[_c('span',{staticClass:"header-label"},[_vm._v(" "+_vm._s(_vm.$t('page.otd.table.labels.date_offered'))+" ")])]),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"2"}},[_c('span',{staticClass:"align-center header-label pr-10"},[_vm._v(" "+_vm._s(_vm.$t('fields.status'))+" ")])])],1)],1):_vm._e(),_vm._l((_vm.otds),function(otd){return _c('v-card',{key:otd.id,staticClass:"mb-3",attrs:{"to":{ name: 'otd', params: { id: otd.id } },"elevation":"2"}},[_c('v-card-text',{staticClass:"item-label d-flex",style:({
            position: 'relative',
            overflow: 'hidden'
            })},[_c('div',{staticClass:"color-strip",class:{
                    'bg-success': otd.isSigned,
                    'bg-accent': !otd.isSigned,
                    'bg-primary': otd.isCancelled,
                }}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","md":"8","sm":"12"}},[_c('v-badge',{attrs:{"offset-x":_vm.$vuetify.breakpoint.smAndDown ? 8 : 0,"offset-y":_vm.$vuetify.breakpoint.smAndDown ? 8 : 0,"color":"accent lighten-3","content":otd.actionCounter.open,"value":otd.actionCounter.open}},[_c('span',{staticClass:"font-weight-black"},[_vm._v(_vm._s(otd.label))])])],1),_c('v-col',{staticClass:"d-flex justify-start justify-md-center align-center font-weight-bold",class:_vm.$vuetify.breakpoint.smAndDown ? 'pt-3' : '',attrs:{"cols":"12","md":"2","sm":"12"}},[_vm._v(" "+_vm._s(_vm._f("localizeDate")(otd.dateFinalised))+" ")]),_c('v-col',{staticClass:"d-flex justify-start justify-md-center align-center pe-md-0",class:_vm.$vuetify.breakpoint.smAndDown ? 'pt-3' : '',attrs:{"cols":"12","md":"2","sm":"12"}},[_c('span',{class:otd.isSigned ? 'text-success' : 'text-accent'},[_c('v-chip',{staticClass:"text-uppercase font-weight-medium otd-pill-chip d-flex justify-center align-center",style:({
                                backgroundColor: otd.isCancelled
                                    ? 'var(--v-primary-lighten5)'
                                    : otd.isSigned
                                    ? 'var(--v-success-lighten4)'
                                    : 'var(--v-accent-lighten4)',
                                color: otd.isCancelled
                                    ? 'var(--v-primary-darken3)'
                                    : otd.isSigned
                                    ? 'var(--v-success-darken3)'
                                    : 'var(--v-accent-darken3)'
                            }),attrs:{"pill":""}},[_vm._v(" "+_vm._s(otd.isCancelled ? _vm.$t('page.otd.labels.status.cancelled') : otd.isSigned ? _vm.$t('page.otd.labels.status.signed') : _vm.$t('status.open'))+" ")])],1)])],1)],1)],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }