import { AxiosClient } from '@/app/common/services/http/axios-client.service'
import store from '@/plugins/store'
import { GetCurrentLicenseCommand } from '@/app/license/commands/get-current-license-command'
import { GetLicensesCommand } from '@/app/license/commands/get-licenses.command'
import { SetDomainCommand } from '@/app/license/commands/set-domain.command'
import { ClearDomainCommand } from '@/app/license/commands/clear-domain.command'
import { GetLanguagesCommand } from '@/app/license/commands/get-languages.command'
import { GetCurrentLanguageCommand } from '@/app/license/commands/get-current-language.command'

const httpClient = new AxiosClient()

export const getCurrentLicenseCommand = new GetCurrentLicenseCommand(httpClient, store)
export const getLicensesCommand = new GetLicensesCommand(httpClient, store)
export const setDomainCommand = new SetDomainCommand(httpClient, store)
export const clearDomainCommand = new ClearDomainCommand(httpClient, store)
export const getLanguagesCommand = new GetLanguagesCommand(httpClient, store)
export const getCurrentLanguageCommand = new GetCurrentLanguageCommand(httpClient, store)
