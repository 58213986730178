
    import { Vue, Component } from 'vue-property-decorator'
    import { mapState } from 'vuex'
    import Notification from './notification.vue'
    import { Notification as NotificationModel } from './notification.model'

    @Component({
        components: { Notification },
        computed: {
            ...mapState('notification', ['notifications'])
        }
    })
    export default class Notifications extends Vue {
        notifications!: NotificationModel[]
    }
