import store from '@/plugins/store'

export class NotificationDispatcher {

    protected store = store
    protected delay = 3000

    public async show(type: string, message: string) {
        await this.store.dispatch('notification/show', { type, message })
    }

    public async info(message: string) {
        await this.show('info', message)
    }

    public async success(message: string) {
        await this.show('success', message)
    }

    public async warning(message: string) {
        await this.show('warning', message)
    }

    public async error(message: string) {
        await this.show('error', message)
    }

    public setDelay(delay: number): NotificationDispatcher {
        this.delay = delay
        return this
    }

}
