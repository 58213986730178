import { ActionTree, Commit } from 'vuex'
import { RootState } from '@/app/common/types/root-state.type'
import { ProductState } from './state'
import { ProductAction } from '@/app/product/entities/product-action.entity'
import { ProductDefinition } from '@/app/product/entities/product-definition.entity'

export const actions: ActionTree<ProductState, RootState> = {

    setProductActions({ commit }: { commit: Commit }, productActions: ProductAction[]) {
        commit('setProductActions', productActions)
    },

    setDefinitions({ commit }: { commit: Commit }, definitions: ProductDefinition) {
        commit('setDefinitions', definitions)
    }

}
