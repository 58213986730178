
    import { Vue, Component } from 'vue-property-decorator'
    import TopMenu from './top-menu.vue'
    import MainHeader from './main-header.vue'
    import MainFooter from './main-footer.vue'

    @Component({
        components: { TopMenu, MainHeader, MainFooter }
    })
    export default class Layout extends Vue {

    }
