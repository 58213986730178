
    import { mapState } from 'vuex'
    import { Component, Inject, Prop, Vue } from 'vue-property-decorator'
    import { Product } from '@/app/product/entities/product.entity'
    import { ProductAction } from '../entities/product-action.entity'
    import { ProductActionMeta, productActionMeta } from '@/app/product/support/product-action-meta'
    import { UploadType, uploadTypes } from '@/app/product/support/upload-type'
    import { ProductActionType } from '@/app/product/support/product-action-type'
    import {
        DownloadDownloadableDocumentCommand
    } from '@/app/product/commands/download-downloadable-document.command'
    import {
        DownloadProductActionFileCommand
    } from '@/app/product/commands/download-product-action-file.command'
    import { NotificationDispatcher } from '@/app/common/services/notification-dispatcher.service'
    import { License } from '@/app/license/entities/license.entity'
    import { DownloadOtdCommand } from '@/app/otd/commands/download-otd.command'

    @Component({
        computed: {
            ...mapState('license', ['license'])
        }
    })
    export default class ProductActionCard extends Vue {
        license!: License
        uploadType = UploadType
        uploadTypes = uploadTypes
        loading = false

        @Prop({ type: ProductAction }) productAction!: ProductAction
        @Prop({ type: Object }) product?: Product
        @Prop({ type: Boolean, default: false }) showClosedActionPoints!: boolean
        @Prop({ type: Boolean, default: true }) consumerIsFormal!: boolean

        @Inject() downloadDownloadableFileCommand!: DownloadDownloadableDocumentCommand
        @Inject() downloadProductActionFileCommand!: DownloadProductActionFileCommand
        @Inject() downloadOtdCommand!: DownloadOtdCommand
        @Inject() notificationDispatcher!: NotificationDispatcher

        get productActionText(): ProductActionMeta {
            return productActionMeta(this.productAction, this.license)
        }

        async downloadAction() {
            const quoteTypes: string[] = [
                ProductActionType.downloadQuoteIndicativeProposal,
                ProductActionType.downloadQuoteFinalOffer,
                ProductActionType.downloadQuote,
                ProductActionType.downloadQuoteDocument
            ]
            const otdTypes: string[] = [
                ProductActionType.signOtd,
                ProductActionType.downloadOtd,
                ProductActionType.downloadSignedOtd,
                ProductActionType.downloadUnsignedOtd
            ]

            this.loading = true

            try {
                if (quoteTypes.includes(this.productAction.type)) {
                    await this.$router.push({ name: 'download-quote', params: { action: this.productAction.id } })
                } else if (this.productAction.type === ProductActionType.downloadAddedDocument) {
                    await this.downloadDownloadableFileCommand.run(this.productAction.id)
                    this.productAction.isDone = true
                } else if (this.productAction.type === ProductActionType.downloadDocumentManagementFile) {
                    await this.downloadProductActionFileCommand.run(this.productAction.id)
                    this.productAction.isDone = true
                } else if (otdTypes.includes(this.productAction.type)) {
                    await this.downloadOtdCommand.run(this.productAction.id)
                } else if (this.productAction.type === ProductActionType.approveDocument && this.productAction.isDone) {
                    await this.downloadProductActionFileCommand.run(this.productAction.id)
                }
            } catch (err) {
                await this.notificationDispatcher.error(err.message)
            } finally {
                this.loading = false
            }
        }
    }
