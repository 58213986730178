import { MutationTree } from 'vuex'
import { CreditState } from './state'
import { Credit } from '@/app/credit/entities/credit.entity'

export const mutations: MutationTree<CreditState> = {

    setCredits(state: CreditState, credits: Credit[]) {
        state.credits = credits
    }

}
