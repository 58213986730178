import { plainToClass } from 'class-transformer'
import { Constructable } from '@/app/common/types/constructable'

export class HttpResponse {
    protected response: any

    constructor(response: any) {
        this.response = this.normalizeJsonApi(response)
    }

    public asEntity<T>(entity: Constructable<T>): T {
        if (Array.isArray(this.response)) {
            return this.responseToEntity(this.response[0], entity)
        }

        return this.responseToEntity(this.response, entity)
    }

    public asEntities<T>(entity: Constructable<T>): T[] {
        if (!Array.isArray(this.response)) {
            return [this.asEntity(entity)]
        }

        return this.response.map((item: any) => this.responseToEntity(item, entity))
    }

    public asObject(): object {
        return Array.isArray(this.response) ? this.response[0] : this.response
    }

    public asArray(): object[] {
        return Array.isArray(this.response) ? this.response : [this.response]
    }

    protected responseToEntity<T>(response: any, entity: Constructable<T>): T {
        return plainToClass(entity, response, {
            excludeExtraneousValues: true
        })
    }

    protected normalizeJsonApi(response: any): any {
        const data = response.data || []

        return data.map((item: any) => {
            const relationships = item.relationships || {}
            const related = Object.keys(relationships).reduce((acc: any, key: any) => {
                acc[key] = relationships[key].data
                return acc
            }, {})

            return {
                ...item.attributes,
                ...related,
                id: item.id
            }
        })
    }
}
