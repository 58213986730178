
    import { mapGetters, mapState } from 'vuex'
    import { Component, Vue, Inject } from 'vue-property-decorator'
    import Layout from '../../common/components/layout/layout.vue'
    import CreditOverview from '../components/credit-overview.vue'
    import { GetAllCreditsCommand } from '@/app/credit/commands/get-all-credits.command'
    import { Credit as CreditEntity } from '@/app/credit/entities/credit.entity'
    import ProductLayout from '@/app/product/components/product-layout.vue'

    @Component({
        components: { Layout, CreditOverview, ProductLayout },
        metaInfo() {
            return {
                title: 'Krediet'
            }
        },
        computed: {
            ...mapGetters('credit', ['getByCreditId']),
            ...mapState('credit', ['credits'])
        }
    })
    export default class Credit extends Vue {
        credit: CreditEntity | null = null
        credits!: CreditEntity[]
        getByCreditId!: (id: string) => CreditEntity | undefined

        @Inject() getAllCreditsCommand!: GetAllCreditsCommand

        async mounted() {
            await this.getAllCredits()

            const id = this.$route.params.id
            this.credit = this.getByCreditId(id) ?? null
        }

        async getAllCredits() {
            // Fetch the credits if needed. This won't happen
            // if they navigate from the credit list page, but will
            // happen if they visit this page directly.
            if (!this.credits.length) {
                await this.getAllCreditsCommand.run()
            }
        }
    }
