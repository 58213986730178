
    import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
    import CurrencyField from "@/app/common/components/currency-field.vue"
    import SimpleTable from "@/app/common/components/simple-table.vue"
    import DatePicker from "@/app/common/components/date-picker.vue"
    import validation from "@/app/common/helpers/validation"
    import { LoanPartFormItem } from "@/app/mortgage/support/loan-part-form-item"
    import { LoanPartType } from "@/app/mortgage/entities/loan-part-type.entity"
    import { LoanPart } from "@/app/mortgage/entities/loan-part.entity"
    import { Mortgage } from "@/app/mortgage/entities/mortgage.entity"
    import { mapGetters, mapState } from "vuex"
    import formalText from "../../common/helpers/formal-text"

    @Component({
        methods: { formalText },
        components: { DatePicker, SimpleTable, CurrencyField },
        computed: {
            ...mapGetters('mortgage', ['getByMortgageId']),
            ...mapState('mortgage', ['mortgages'])
        }
    })
    export default class MortgageLoanParts extends Vue {
        @Prop({ type: Mortgage, required: true }) mortgage!: Mortgage

        required = false
        rules = validation

        loanPartsData: LoanPartFormItem[] = []
        loanPartTypes: { text: string, value: string }[] = []

        mounted() {
            this.fillLoanPartTypes()
            this.fillLoanPartsFormData()
        }

        fillLoanPartTypes() {
            this.loanPartTypes = this.mortgage.loanPartTypes.map((loanPartType: LoanPartType) => {
                return {
                    text: loanPartType.label,
                    value: loanPartType.name
                }
            }) ?? []
        }

        fillLoanPartsFormData() {
            this.loanPartsData = this.mortgage.loanParts.reduce((acc: LoanPartFormItem[], loanPart: LoanPart) => {
                acc.push({
                    id: loanPart.id,
                    type: loanPart.type,
                    amount: loanPart.amount,
                    currentAmount: undefined,
                    date: loanPart.date,
                    interest: loanPart.interest,
                    remainingFixedInterestPeriod: undefined,
                    startOfFixedRatePeriod: loanPart.startOfFixedRatePeriod,
                    endOfFixedRatePeriod: loanPart.endOfFixedRatePeriod,
                    endOfLoanPeriod: loanPart.endOfLoanPeriod,
                    isNew: false
                })

                return acc
            }, []) ?? []
        }

        addNewLoanPart() {
            this.loanPartsData.push({
                isNew: true
            })
        }

        deleteLoanPart(index: number) {
            this.$delete(this.loanPartsData, index)
        }

        @Watch('loanPartsData', { deep: true })
        watchFormData() {
            this.required = this.areloanPartsUpdated(this.mortgage.loanParts, this.loanPartsData)
            this.$emit('input', this.loanPartsData)
        }

        compareLoanPart(original: LoanPart, form: LoanPartFormItem): boolean {
            return null === (form.currentAmount ?? null)
                && original.date === form.date
                && original.interest === form.interest
                && original.startOfFixedRatePeriod === form.startOfFixedRatePeriod
                && original.endOfFixedRatePeriod === form.endOfFixedRatePeriod
        }

        areloanPartsUpdated(original: LoanPart[], formData: LoanPartFormItem[]): boolean {
            if (original.length !== formData.length) {
                return true
            }

            for(let i = 0; i < original.length; i++) {
                const originalPart = original[i]
                const formPart = formData.find(p => p.id === originalPart.id)

                if (!formPart || !this.compareLoanPart(originalPart, formPart)) {
                    return true
                }
            }

            return false
        }

        applyRules(rules: any[]): any[] {
            return this.required ? rules : []
        }
    }
