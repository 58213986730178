import { HttpParams } from '@/app/common/services/http/http-params.interface'
import { Entity } from '@/app/common/base/entity'
import { classToPlain } from 'class-transformer'

export class HttpRequestBody {
    constructor(private readonly body?: HttpParams) {}

    public transform(): HttpParams {
        return Object.keys(this.body || {}).reduce((acc: any, key) => {
            const param = this.body?.[key]

            if (param instanceof Entity) {
                acc[key] = classToPlain(param)
            } else if (Array.isArray(param)) {
                acc[key] = param.map(p => {
                    return p instanceof Entity ? classToPlain(p) : p
                })
            } else {
                acc[key] = param
            }

            return acc
        }, {})
    }
}
