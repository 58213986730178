
    import Vue from 'vue'
    import { Consumer } from '@/app/identity'
    import { Component, Prop } from 'vue-property-decorator'
    import SimpleTable from '@/app/common/components/simple-table.vue'
    import { mapState } from 'vuex'
    import { PurchaseIntention } from '@/app/purchase-intention/entities/purchase-intention.entity'

    @Component({
        components: { SimpleTable },
        computed: {
            ...mapState('auth', ['consumer'])
        }
    })
    export default class PurchaseIntentionsHouses extends Vue {
        @Prop({ type: PurchaseIntention, required: true }) purchaseIntention!: PurchaseIntention

        consumer!: Consumer
    }
