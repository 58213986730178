import { Command } from '@/app/common/base/command'
import { Otd } from '@/app/otd/entities/otd.entity'

export class GetAllOtdsCommand extends Command {
    public async run() {
        const response = await this.client.get('/api/products/otds')
        const otds = response.asEntities(Otd)

        await this.store.dispatch('otd/setOtds', otds)
    }
}
