import { AxiosClient } from '@/app/common/services/http/axios-client.service'
import store from '@/plugins/store'
import { GetAllBankguaranteesCommand } from '@/app/bankguarantee/commands/get-all-bankguarantees.command'
import { DownloadBankguaranteeDocumentCommand } from '@/app/bankguarantee/commands/download-bankguarantee-document.command'
import { SubmitBnpChoiceCommand } from '@/app/bankguarantee/commands/submit-bnp-choice.command'

const httpClient = new AxiosClient()

export const getAllBankguaranteesCommand = new GetAllBankguaranteesCommand(httpClient, store)
export const downloadBankguaranteeDocumentCommand = new DownloadBankguaranteeDocumentCommand(httpClient, store)
export const submitBnpChoiceCommand = new SubmitBnpChoiceCommand(httpClient, store)
