
    import { Vue, Component, Prop } from 'vue-property-decorator'

    @Component
    export default class Notification extends Vue {
        timeout?: number = undefined

        @Prop({ type: Number, required: true }) readonly id!: number
        @Prop({ type: String, default: 'info' }) readonly type!: string
        @Prop({ type: String, required: true }) readonly message!: string
        @Prop({ type: Number, default: 3000 }) readonly delay!: number

        get notificationClass() {
            return {
                notification: true,
                [`notification--${this.type}`]: true
            }
        }

        remove() {
            this.$store.dispatch('notification/remove', this.id)
            window.clearTimeout(this.timeout)
        }

        mounted() {
            this.timeout = window.setTimeout(() => {
                this.remove()
            }, this.delay)
        }
    }
