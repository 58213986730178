import { AxiosClient } from '@/app/common/services/http/axios-client.service'
import { LoginCommand } from '@/app/auth/commands/login.command'
import { LoginFromAccelerateCommand } from '@/app/auth/commands/login-from-accelerate.command'
import { LoginWithTwoFactorCommand } from '@/app/auth/commands/login-with-twofactor.command'
import { ForgotCommand } from '@/app/auth/commands/forgot.command'
import { LogoutCommand } from '@/app/auth/commands/logout.command'
import { ResetPasswordCommand } from '@/app/auth/commands/reset-password.command'
import { ConfirmResetTokenCommand } from '@/app/auth/commands/confirm-reset-token.command'
import store from '@/plugins/store'
import { UpdateConsumerCommand } from '@/app/auth/commands/update-consumer.command'
import { SetSentryUserCommand } from '@/app/auth/commands/set-sentry-user.command'
import { ProductActionLoginCommand } from "@/app/auth/commands/product-action-login.command";

const httpClient = new AxiosClient()

export const loginCommand = new LoginCommand(httpClient, store)
export const productActionLoginCommand = new ProductActionLoginCommand(httpClient, store)
export const loginFromAccelerateCommand = new LoginFromAccelerateCommand(httpClient, store)
export const loginWithTwoFactorCommand = new LoginWithTwoFactorCommand(httpClient, store)
export const forgotCommand = new ForgotCommand(httpClient, store)
export const logoutCommand = new LogoutCommand(httpClient, store)
export const resetPasswordCommand = new ResetPasswordCommand(httpClient, store)
export const confirmResetTokenCommand = new ConfirmResetTokenCommand(httpClient, store)
export const updateConsumerCommand = new UpdateConsumerCommand(httpClient, store)
export const setSentryUserCommand = new SetSentryUserCommand(httpClient, store)
