
    import { Vue, Component } from 'vue-property-decorator'
    import { mapState } from 'vuex'
    import { License } from '@/app/license/entities/license.entity'
    import hasLocalizedContent from '@/app/common/helpers/has-localized-content'
    import localizeContent from '@/app/common/helpers/localize-content'

    @Component({
        computed: {
            ...mapState('license', ['license'])
        }
    })
    export default class MainFooter extends Vue {
        license!: License
        hasLocalizedContent = hasLocalizedContent
        localizeContent = localizeContent

        get currentYear(): number {
            return new Date().getFullYear()
        }
    }
