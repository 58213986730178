
    import { Component, Vue, Inject } from 'vue-property-decorator'
    import Layout from '../../common/components/layout/layout.vue'
    import { NotificationDispatcher } from '@/app/common/services/notification-dispatcher.service'
    import { InventoryFormRequirement } from '@/app/inventory-form/entities/inventory-form-requirement.entity'
    import { GetInventoryFormRequirementsCommand } from '@/app/inventory-form/commands/get-inventory-form-requirements.command'
    import { GetOcktoFormStateCommand } from '@/app/inventory-form/commands/get-ockto-form-state.command'
    import { SubmitOcktoTestDataCommand } from "@/app/inventory-form/commands/submit-ockto-test-data.command"
    import { RenewQrCodeCommand } from '@/app/inventory-form/commands/renew-qr-code.command'
    import { OcktoFormState } from '@/app/inventory-form/entities/ockto-form-state.entity'
    import { OcktoStateType } from '@/app/inventory-form/support/ockto-state-type'
    import { mapGetters } from "vuex"
    import { ProductAction } from "@/app/product/entities/product-action.entity"
    import { GetAllActionsCommand } from "@/app/product/commands/get-all-actions.command"

    @Component({
        components: { Layout },
        computed: {
            ...mapGetters('product', ['getByProductActionId'])
        },
        metaInfo() {
            return {
                title: 'Ockto form'
            }
        }
    })
    export default class OcktoForm extends Vue {
        state: OcktoFormState|null = null
        productAction?: ProductAction | null = null
        getByProductActionId!: (id: string) => ProductAction | undefined
        requirements: InventoryFormRequirement|null = null
        data = {}
        events: { date: string, event: any }[] = []
        origin = ''
        ocktoStateType = OcktoStateType
        pollInterval?: number = undefined
        debugLoading = false

        testCases = {
            selected: "TS001_FSA",
            items: [
                { text: 'Test 1', value: 'TS001_FSA' },
                { text: 'Test 2', value: 'TS002_FSA' },
                { text: 'Test 3', value: 'TS003_FSA' },
                { text: 'Consumer 1', value: 'TS001_FSA_CLIENT' },
                { text: 'Consumer 2 (partner of consumer 1)', value: 'TS001_FSA_PARTNER' },
                { text: '1 Duo lening', value: 'TS002_DUOAppliedSource' },
                { text: '3 Duo leningen', value: 'TS002_DuoLoansMultiple' }
            ]
        }

        @Inject() getAllActionsCommand!: GetAllActionsCommand
        @Inject() getInventoryFormRequirementsCommand!: GetInventoryFormRequirementsCommand
        @Inject() notificationDispatcher!: NotificationDispatcher
        @Inject() getOcktoFormStateCommand!: GetOcktoFormStateCommand
        @Inject() submitOcktoTestDataCommand!: SubmitOcktoTestDataCommand
        @Inject() renewQrCodeCommand!: RenewQrCodeCommand

        async created() {
            try {
                this.state = await this.getOcktoFormStateCommand.run(this.$route.params.action)
            } catch (err) {
                await this.notificationDispatcher.error(this.$t('page.inventory_form.error_failed_to_retrieve_partner').toString())
            }
        }

        async mounted() {
            try {
                await this.getAllActionsCommand.run()
                this.productAction = this.getByProductActionId(this.$route.params.action) ?? null
                this.requirements = await this.getInventoryFormRequirementsCommand.run(this.$route.params.action)
                await this.stateUpdate()
                this.pollState()
            } catch (err) {
                await this.notificationDispatcher.error(err.message)
            }
        }

        async renewQRCode() {
            try {
                const productActionId = this.$route.params.action

                await this.renewQrCodeCommand.run(productActionId)
                await this.$router.go(0)
            } catch (err) {
                await this.notificationDispatcher.error(err.message)
            }
        }

        get ocktoDebug(): boolean {
            return this.requirements?.data?.ockto?.ockto_debug ?? false
        }

        get hasErrors(): boolean {
            const errors = this.requirements?.data?.ockto?.ockto_qrcode?.errors

            if (errors === undefined) {
                return false
            }

            return Object.keys(errors).length !== 0
        }

        get qrCodeImage(): string {
            const base64Qr = this.requirements?.data?.ockto?.ockto_qrcode?.qrCode

            if (base64Qr === undefined || base64Qr === null) {
                return ''
            }

            return `data:image/png;base64,${this.requirements?.data?.ockto?.ockto_qrcode?.qrCode}`
        }

        get deeplinkUrl(): string|null|undefined {
            return this.requirements?.data?.ockto?.ockto_qrcode?.url
        }

        async stateUpdate() {
            this.state = await this.getOcktoFormStateCommand.run(this.$route.params.action)

            switch (this.state?.code) {
                case this.ocktoStateType.start:
                    this.debugLoading = false
                    break
                case this.ocktoStateType.qrcodeScanned:
                    this.debugLoading = true
                    break
                case this.ocktoStateType.finished:
                    await this.$router.push({ name: 'dashboard' })
                    this.debugLoading = false
                    break
                default:
                    await this.notificationDispatcher.error(this.$t('page.inventory_form.ockto.error_body').toString())
                    await this.$router.push({ name: 'dashboard' })
                    this.debugLoading = false
            }
        }

        async submitTestData() {
            try {
                this.debugLoading = true
                await this.submitOcktoTestDataCommand.run(this.testCases.selected, this.$route.params.action)
            } catch (err) {
                await this.notificationDispatcher.error(err.message)
            } finally {
                this.debugLoading = false
            }
        }

        pollState() {
            this.pollInterval = setInterval(() => {
                this.stateUpdate()
            }, 5000)
        }

        destroyed() {
            if (this.pollInterval) {
                clearInterval(this.pollInterval)
            }
        }
    }
