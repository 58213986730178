
    import { Component, Prop, Vue } from 'vue-property-decorator'
    import { Bankguarantee } from '@/app/bankguarantee/entities/bankguarantee.entity'
    import SimpleTable from '@/app/common/components/simple-table.vue'
    import { mapState } from 'vuex'
    import { Consumer } from '@/app/identity'

    @Component({
        components: { SimpleTable },
        computed: {
            ...mapState('auth', ['consumer'])
        }
    })
    export default class BankguaranteeOverview extends Vue {
        @Prop({ type: Bankguarantee, required: true }) bankguarantee!: Bankguarantee

        consumer!: Consumer
    }
