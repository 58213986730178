import { MutationTree } from 'vuex'
import { MaintenanceState } from '@/app/maintenance/store/state'
import { Maintenance } from '@/app/maintenance/entities/maintenance.entity'

export const mutations: MutationTree<MaintenanceState> = {

    setMaintenance(state: MaintenanceState, maintenance: Maintenance) {
        state.maintenance = maintenance
    }

}
