import { Command } from '@/app/common/base/command'
import { WillWritingAdvice } from '@/app/will_writing_advice/entities/will-writing-advice.entity'

export class GetAllWillWritingAdvicesCommand extends Command {
    public async run() {
        const response = await this.client.get('/api/products/will-writing-advices')
        const willWritingAdvices = response.asEntities(WillWritingAdvice)

        await this.store.dispatch('willWritingAdvice/setWillWritingAdvices', willWritingAdvices)
    }
}
