import { Expose } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'

export class OcktoFormState extends Entity<OcktoFormState> {
    @Expose()
    id!: string

    @Expose()
    code!: number

    @Expose()
    label!: string
}
