import { MutationTree } from 'vuex'
import { Consumer } from '@/app/identity'
import { AuthState } from './state'

export const mutations: MutationTree<AuthState> = {
    updateConsumer(state: AuthState, consumer: Consumer) {
        state.consumer = consumer
    },

    updateTokens(state: AuthState, { accessToken, refreshToken }: { accessToken: string, refreshToken: string }) {
        state.accessToken = accessToken
        state.refreshToken = refreshToken
    },

    updateProductActionToken(state: AuthState, { id, token }: { id: string, token: string }) {
        state.tempLogin = { id, token }
    },

    removeProductActionToken(state: AuthState) {
        state.tempLogin = {}
    },

    updatePendingLogin(
        state: AuthState,
        { accessToken, refreshToken }: { accessToken: string, refreshToken: string }
    ) {
        state.pendingCredentials = { accessToken, refreshToken }
    },

    clearPendingLogin(state: AuthState) {
        state.pendingCredentials = {}
    },

    logout(state: AuthState) {
        state.consumer = undefined
        state.accessToken = undefined
        state.refreshToken = undefined
    },

    setTwoFactorStatus(state: AuthState, enabled: boolean) {
        if (state.consumer) {
            state.consumer.twoFactor = enabled
        }
    }
}
