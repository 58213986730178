import { Command } from '@/app/common/base/command'
import { Consumer } from '@/app/identity'
import * as Sentry from '@sentry/vue'

export class SetSentryUserCommand extends Command {
    public run(consumer: Consumer) {
        Sentry.setUser({
            id: consumer.id,
            email: consumer.email,
            username: consumer.displayName
        })
    }
}
