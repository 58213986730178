import { Expose } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'

export class Payment extends Entity<Payment> {
    @Expose()
    id!: string

    @Expose()
    description!: string

    @Expose()
    amount!: number

    @Expose({ name: 'is_paid' })
    isPaid!: boolean

    @Expose()
    issuer!: string

    @Expose()
    status!: string

    @Expose({ name: 'payment_url' })
    paymentUrl!: string
}
