import { Module } from 'vuex'
import { RootState } from '@/app/common/types/root-state.type'
import { WillWritingAdviceState, state } from './state'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'

export const willWritingAdviceStore: Module<WillWritingAdviceState, RootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
