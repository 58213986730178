
    import { Vue, Component, Inject } from 'vue-property-decorator'
    import { LoadQrCommand } from '@/app/settings/commands/load-qr.command'
    import { ConfirmTwoFactorCommand } from '@/app/settings/commands/confirm-two-factor.command'
    import { DisableTwoFactorCommand } from '@/app/settings/commands/disable-two-factor.command'
    import { NotificationDispatcher } from '@/app/common/services/notification-dispatcher.service'
    import { mapState } from 'vuex'
    import { Consumer } from '@/app/identity'

    @Component({
        components: {},
        computed: {
            ...mapState('auth', ['consumer'])
        }
    })
    export default class Twofactor extends Vue {
        showExplanation = false
        twoFactorEnabled = false
        enableFlowStep = 0
        qrCode?: string = undefined
        loading = false
        qrConfirmationCode = ''
        showDisableDialog = false

        consumer!: Consumer

        @Inject() loadQrCommand!: LoadQrCommand
        @Inject() confirmTwoFactorCommand!: ConfirmTwoFactorCommand
        @Inject() disableTwoFactorCommand!: DisableTwoFactorCommand
        @Inject() notificationDispatcher!: NotificationDispatcher

        mounted() {
            this.twoFactorEnabled = this.consumer.twoFactor
        }

        async loadQrCode() {
            this.qrConfirmationCode = ''

            try {
                this.loading = true
                const qr = await this.loadQrCommand.run()

                this.qrCode = btoa(qr)
                this.enableFlowStep++
            } catch (err) {
                await this.notificationDispatcher.error(err.message)
            } finally {
                this.loading = false
            }
        }

        async confirmQrCode() {
            try {
                this.loading = true
                await this.confirmTwoFactorCommand.run(this.qrConfirmationCode)
                this.twoFactorEnabled = true
                this.enableFlowStep = 0
            } catch (err) {
                await this.notificationDispatcher.error(err.message)
            } finally {
                this.loading = false
            }
        }

        async confirmDisable() {
            this.showDisableDialog = false

            try {
                this.loading = true
                await this.disableTwoFactorCommand.run(this.qrConfirmationCode)
                this.twoFactorEnabled = false
            } catch (err) {
                await this.notificationDispatcher.error(err.message)
            } finally {
                this.loading = false
            }
        }
    }
