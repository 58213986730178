import { GetterTree } from 'vuex'
import { RootState } from '@/app/common/types/root-state.type'
import { MortgageState } from '@/app/mortgage/store/state'
import { Mortgage } from '@/app/mortgage/entities/mortgage.entity'

export const getters: GetterTree<MortgageState, RootState> = {

    getByMortgageId(state: MortgageState): (id: string) => Mortgage | undefined {
        return (id: string) => {
            const mortgages = state.mortgages.filter(m => m.id === id)
            return mortgages?.[0]
        }
    }

}
