
    import { Vue, Component } from 'vue-property-decorator'
    import Layout from '../../common/components/layout/layout.vue'
    import Twofactor from '../components/twofactor.vue'
    import { mapState } from 'vuex'
    import { Language } from '@/app/license/entities/language.entity'

    @Component({
        components: { Layout, Twofactor },
        computed: {
            ...mapState('license', ['languages'])
        }
    })
    export default class Settings extends Vue {
        languages!: Language[]
    }
