import { render, staticRenderFns } from "./notification.vue?vue&type=template&id=fc228704&scoped=true"
import script from "./notification.vue?vue&type=script&lang=ts"
export * from "./notification.vue?vue&type=script&lang=ts"
import style0 from "./notification.vue?vue&type=style&index=0&id=fc228704&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc228704",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
