import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import router from '@/plugins/router'
import Env from '@/env'

if (Env.sentry.enabled) {
    Sentry.init({
        Vue,
        environment: Env.environment,
        release: `cp-front-${Env.version}`,
        dsn: Env.sentry.dsn,
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: ['*', /^\//]
            })
        ],
        logErrors: Env.isDevelopment,
        attachProps: true,
        attachStacktrace: true,
        tracesSampleRate: .3
    })
}
