import { MutationTree } from 'vuex'
import { BankguaranteeState } from './state'
import { Bankguarantee } from '@/app/bankguarantee/entities/bankguarantee.entity'

export const mutations: MutationTree<BankguaranteeState> = {

    setBankguarantees(state: BankguaranteeState, bankguarantees: Bankguarantee[]) {
        state.bankguarantees = bankguarantees
    }

}
