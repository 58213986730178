interface GoogleFont {
    variant: string
    imported: string
}

export default class GoogleFontResolver {
    private readonly name: string
    private font?: GoogleFont
    private readonly meta: { [key: string]: GoogleFont } = {
        'Roboto': {
            variant: 'sans-serif',
            imported: 'Roboto:ital,wght@0,400;0,700;1,400;1,700'
        },
        'Open Sans': {
            variant: 'sans-serif',
            imported: 'Open+Sans:ital,wght@0,400;0,700;1,400;1,700'
        },
        'Lato': {
            variant: 'sans-serif',
            imported: 'Lato:ital,wght@0,400;0,700;1,400;1,700'
        },
        'Montserrat': {
            variant: 'sans-serif',
            imported: 'Montserrat:ital,wght@0,400;0,700;1,400;1,700'
        },
        'Oswald': {
            variant: 'sans-serif',
            imported: 'Oswald:wght@400;700'
        },
        'Raleway': {
            variant: 'sans-serif',
            imported: 'Raleway:ital,wght@0,400;0,700;1,400;1,700'
        },
        'Noto Sans': {
            variant: 'sans-serif',
            imported: 'Noto+Sans:ital,wght@0,400;0,700;1,400;1,700'
        },
        'Fira Sans': {
            variant: 'sans-serif',
            imported: 'Fira+Sans:ital,wght@0,400;0,700;1,400;1,700'
        },
        'Lora': {
            variant: 'serif',
            imported: 'Lora:ital,wght@0,400;0,700;1,400;1,700'
        },
        'Merriweather': {
            variant: 'serif',
            imported: 'Merriweather:ital,wght@0,400;0,700;1,400;1,700'
        },
        'Libre Baskerville': {
            variant: 'serif',
            imported: 'Libre+Baskerville:ital,wght@0,400;0,700;1,400'
        }
    }

    constructor(name: string) {
        this.name = name

        if (this.meta[name] === undefined) {
            throw new Error('Font not supported.')
        }

        this.font = this.meta[name]
    }

    getImportUrl(): string {
        return `https://fonts.googleapis.com/css2?family=${this.font!.imported}&display=swap`
    }

    getCssFontFamily(): string {
        return `'${this.name}', ${this.font!.variant}`
    }
}
