import {Command} from "@/app/common/base/command";
import {WoongarantInsurance} from "@/app/woongarant-insurance/entities/woongarant-insurance.entity";

export class GetAllWoongarantInsurancesCommand extends Command
{
    public async run()
    {
        const response = await this.client.get('/api/products/woongarant-insurances')
        const woongarantInsurances = response.asEntities(WoongarantInsurance)

        await this.store.dispatch('woongarantInsurance/setWoongarantInsurances', woongarantInsurances)
    }
}
