
    import { mapState } from 'vuex'
    import { Component, Inject, Vue } from 'vue-property-decorator'
    import Layout from '../../common/components/layout/layout.vue'
    import { PurchaseIntention } from '@/app/purchase-intention/entities/purchase-intention.entity'
    import { GetAllPurchaseIntentionsCommand } from '@/app/purchase-intention/commands/get-all-purchase-intentions.command'

    @Component({
        components: { Layout },
        metaInfo() {
            return {
                title: 'Purchase Intentions'
            }
        },
        computed: {
            ...mapState('purchaseIntention', ['purchaseIntentions'])
        }
    })
    export default class PurchaseIntentionList extends Vue {
        purchaseIntentions!: PurchaseIntention[]

        @Inject() getAllPurchaseIntentionsCommand!: GetAllPurchaseIntentionsCommand

        mounted() {
            this.getAllPurchaseIntentionsCommand.run()
        }
    }
