import { Command } from '@/app/common/base/command'

export class DownloadImportantDocumentCommand extends Command {
    public async run(id: string, filename: string, locale: string) {
        const response = await this.client.download(`/api/documents/${id}/download`, {
            params: {
                locale
            },
            responseType: 'blob',
            timeout: 30000
        })

        const fileURL = window.URL.createObjectURL(response.data)
        const fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute('download', filename)
        document.body.appendChild(fileLink)
        fileLink.click()
    }
}
