import { GetterTree } from 'vuex'
import { RootState } from '@/app/common/types/root-state.type'
import { CreditState } from './state'
import { Credit } from '@/app/credit/entities/credit.entity'

export const getters: GetterTree<CreditState, RootState> = {

    getByCreditId(state: CreditState): (id: string) => Credit | undefined {
        return (id: string) => {
            const credits = state.credits.filter(c => c.id === id)
            return credits?.[0]
        }
    }

}
