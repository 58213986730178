import { ProductPhase } from '@/app/api-entity/entities/product-phase.entity'
import { IncomeType } from '@/app/api-entity/entities/income-type.entity'

export interface ApiEntityState {
    productPhases: ProductPhase[]
    incomeTypes: IncomeType[]
}

export const state: ApiEntityState = {
    productPhases: [],
    incomeTypes: []
}
