import { Command } from '@/app/common/base/command'
import { IncomeType } from '@/app/api-entity/entities/income-type.entity'

export class GetAllIncomeTypesCommand extends Command {
    public async run() {
        const response = await this.client.get('/api/entities/income-types')
        const incomeTypes = response.asEntities(IncomeType)

        await this.store.dispatch('apiEntity/setIncomeTypes', incomeTypes)
    }
}
