import { AxiosRequestConfig } from 'axios'
import store from '@/plugins/store'

// These URLs don't need an authorization header.
const ignoredUrls = [
    '/api/auth/login',
    '/api/auth/hypotheek-check',
    '/api/auth/refresh',
    '/api/auth/forgot-password',
    '/api/auth/start-password-reset',
    '/api/auth/reset-password',
    '/api/multiple-domains',
    '/api/maintenance',
    '/api/languages',
    '/api/licenses',
    '/api/create-dossier',
    '/api/entities/income-types',
    '/api/entities/product-phases',
    '/api/dictionary'
]

export default (onFinish?: () => void) => {
    return (config: AxiosRequestConfig) => {
        const accessToken = store.state.auth.accessToken
        const domain = store.state.license.domain

        config.headers = config.headers || {}

        // Add the authorization header
        if (!ignoredUrls.includes(config.url ?? '') && accessToken){
            config.headers.Authorization = `Bearer ${accessToken}`
        }

        // Add the domain in case of a multiple domain setting.
        if (domain) {
            config.headers['X-AccelerateDomain'] = domain
        }

        if (onFinish) {
            onFinish()
        }

        return config
    }
}
