import { Expose } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'

export class PaymentStatus extends Entity<PaymentStatus> {
    @Expose()
    status!: string

    @Expose()
    url!: string

    readonly options = {
        created: 'CREATED',
        expired: 'EXPIRED',
        cancelled: 'CANCELLED',
        failed: 'FAILED',
        paid: 'PAID',
        pending: 'PENDING'
    }

    canBePaid(): boolean {
        const statuses = [
            this.options.created,
            this.options.expired,
            this.options.cancelled,
            this.options.failed
        ]

        return statuses.includes(this.status)
    }

    isPaid(): boolean {
        return this.status === this.options.paid
    }

    isCancelled(): boolean {
        return this.status === this.options.cancelled
    }

    isPending(): boolean {
        return this.status === this.options.pending
    }
}
