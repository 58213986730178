
    import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
    import { Consumer } from '@/app/identity'
    import { ConsumerData } from '@/app/inventory-form/types/consumer-data'
    import DatePicker from '@/app/common/components/date-picker.vue'
    import validation from '@/app/common/helpers/validation'

    @Component({
        components: { DatePicker }
    })
    export default class ConsumerForm extends Vue {
        @Prop({ type: Object }) user!: Consumer
        @Prop({ type: String, default: 'Your User' }) title!: string
        @Prop({ type: Boolean, default: false }) isPartner!: string

        rules = validation

        formData: ConsumerData = {
            gender: this.user?.gender,
            initials: this.user?.initials,
            firstName: this.user?.firstName,
            preposition: this.user?.preposition,
            lastName: this.user?.lastName,
            streetName: this.user?.streetName,
            houseNumber: this.user?.houseNumber,
            houseNumberAddition: this.user?.houseNumberAddition,
            zipcode: this.user?.zipcode,
            city: this.user?.city,
            birthCountry: this.user?.birthCountry ?? 'NL Nederland',
            birthDate: this.user?.dateOfBirth,
            birthLastName: this.user?.birthLastName,
            birthPreposition: this.user?.birthPreposition,
            phoneHome: this.user?.phoneHome,
            phoneMobile: this.user?.phoneMobile,
            email: this.user?.email,
            maritalState: this.user?.maritalState,
            degree: this.user?.degree,
            smoker: this.user?.smoker,
            inPaidEmployment: this.user?.inPaidEmployment,
            grossMonthlyIncome: '',
            everDivorced: this.user?.everDivorced,
            children: '',
            hasPartner: false,
        }

        maritalStates = [
            { value: '', text: this.$t('page.inventory_form.consumer_marital_states.0') },
            { value: '01 Gehuwd (gemeenschap van goederen)', text: this.$t('page.inventory_form.consumer_marital_states.1') },
            { value: '02 Gehuwd met huwelijkse voorwaarden', text: this.$t('page.inventory_form.consumer_marital_states.2') },
            { value: '03 Alleenstaand', text: this.$t('page.inventory_form.consumer_marital_states.3') },
            { value: '04 Samenwonend met samenlevingscontract', text: this.$t('page.inventory_form.consumer_marital_states.4') },
            { value: '05 Samenwonend zonder samenlevingscontract', text: this.$t('page.inventory_form.consumer_marital_states.5') },
            { value: '06 Partnerregistratie in gemeenschap van goederen', text: this.$t('page.inventory_form.consumer_marital_states.6') },
            { value: '07 Partnerregistratie onder registratievoorwaarden', text: this.$t('page.inventory_form.consumer_marital_states.7') },
            { value: '08 Gehuwd volgens buitenlands recht', text: this.$t('page.inventory_form.consumer_marital_states.8') },
        ]
        degrees = [
            { value: '' , text: this.$t('page.inventory_form.consumer_degrees.0') },
            { value: '7 (post)Academisch' , text: this.$t('page.inventory_form.consumer_degrees.7') },
            { value: '6 Hoger beroepsonderwijs' , text: this.$t('page.inventory_form.consumer_degrees.6') },
            { value: '4 Lager beroepsonderwijs' , text: this.$t('page.inventory_form.consumer_degrees.4') },
            { value: '1 Lager onderwijs' , text: this.$t('page.inventory_form.consumer_degrees.1') },
            { value: '5 Middelbaar beroepsonderwijs' , text: this.$t('page.inventory_form.consumer_degrees.5') },
            { value: '8 Onbekend' , text: this.$t('page.inventory_form.consumer_degrees.8') },
            { value: '2 Voorgezet onderwijs (MAVO/HAVO)' , text: this.$t('page.inventory_form.consumer_degrees.2') },
            { value: '3 VWO' , text: this.$t('page.inventory_form.consumer_degrees.3') },
        ]
        countryOptions = [
            { value: '', text: this.$t('common.empty_select') },
            { value: 'AD Andorra', text: this.$t('countries.AD') },
            { value: 'AL Albanië', text: this.$t('countries.AL') },
            { value: 'AR Argentinië', text: this.$t('countries.AR') },
            { value: 'AT Oostenrijk', text: this.$t('countries.AT') },
            { value: 'AU Australië', text: this.$t('countries.AU') },
            { value: 'AW Aruba', text: this.$t('countries.AW') },
            { value: 'BA Bosnie Herzegovina', text: this.$t('countries.BA') },
            { value: 'BE België', text: this.$t('countries.BE') },
            { value: 'BG Bulgarije', text: this.$t('countries.BG') },
            { value: 'BO Bolivia', text: this.$t('countries.BO') },
            { value: 'BR Brazilië', text: this.$t('countries.BR') },
            { value: 'CA Canada', text: this.$t('countries.CA') },
            { value: 'CH Zwitserland', text: this.$t('countries.CH') },
            { value: 'CL Chili', text: this.$t('countries.CL') },
            { value: 'CN China', text: this.$t('countries.CN') },
            { value: 'CO Colombia', text: this.$t('countries.CO') },
            { value: 'CU Cuba', text: this.$t('countries.CU') },
            { value: 'CY Cyprus', text: this.$t('countries.CY') },
            { value: 'CZ Tsjechië', text: this.$t('countries.CZ') },
            { value: 'DE Duitsland', text: this.$t('countries.DE') },
            { value: 'DK Denemarken', text: this.$t('countries.DK') },
            { value: 'EE Estland', text: this.$t('countries.EE') },
            { value: 'ES Spanje', text: this.$t('countries.ES') },
            { value: 'ET Ethiopië', text: this.$t('countries.ET') },
            { value: 'FI Finland', text: this.$t('countries.FI') },
            { value: 'FR Frankrijk', text: this.$t('countries.FR') },
            { value: 'GB Engeland', text: this.$t('countries.GB') },
            { value: 'GH Ghana', text: this.$t('countries.GH') },
            { value: 'GI Gibraltar', text: this.$t('countries.GI') },
            { value: 'GR Griekenland', text: this.$t('countries.GR') },
            { value: 'HR Kroatië', text: this.$t('countries.HR') },
            { value: 'HU Hongarije', text: this.$t('countries.HU') },
            { value: 'ID Indonesië', text: this.$t('countries.ID') },
            { value: 'IE Ierland', text: this.$t('countries.IE') },
            { value: 'IL Israël', text: this.$t('countries.IL') },
            { value: 'IN India', text: this.$t('countries.IN') },
            { value: 'IQ Irak', text: this.$t('countries.IQ') },
            { value: 'IR Iran', text: this.$t('countries.IR') },
            { value: 'IS IJsland', text: this.$t('countries.IS') },
            { value: 'IT Italië', text: this.$t('countries.IT') },
            { value: 'JP Japan', text: this.$t('countries.JP') },
            { value: 'KE Kenya', text: this.$t('countries.KE') },
            { value: 'KP Noord Korea', text: this.$t('countries.KP') },
            { value: 'KR Zuid Korea', text: this.$t('countries.KR') },
            { value: 'LI Liechtenstein', text: this.$t('countries.LI') },
            { value: 'LK Sri Lanka', text: this.$t('countries.LK') },
            { value: 'LT Litouwen', text: this.$t('countries.LT') },
            { value: 'LU Luxemburg', text: this.$t('countries.LU') },
            { value: 'LV Letland', text: this.$t('countries.LV') },
            { value: 'MA Marokko', text: this.$t('countries.MA') },
            { value: 'MC Monaco', text: this.$t('countries.MC') },
            { value: 'MM Myanmar', text: this.$t('countries.MM') },
            { value: 'MT Malta', text: this.$t('countries.MT') },
            { value: 'MY Maleisië', text: this.$t('countries.MY') },
            { value: 'NG Nigeria', text: this.$t('countries.NG') },
            { value: 'NL Nederland', text: this.$t('countries.NL') },
            { value: 'NO Noorwegen', text: this.$t('countries.NO') },
            { value: 'PH Filipijnen', text: this.$t('countries.PH') },
            { value: 'PK Pakistan', text: this.$t('countries.PK') },
            { value: 'PL Polen', text: this.$t('countries.PL') },
            { value: 'PT Portugal', text: this.$t('countries.PT') },
            { value: 'RO Roemenië', text: this.$t('countries.RO') },
            { value: 'RU Rusland', text: this.$t('countries.RU') },
            { value: 'SD Soedan', text: this.$t('countries.SD') },
            { value: 'SE Zweden', text: this.$t('countries.SE') },
            { value: 'SI Slovenië', text: this.$t('countries.SI') },
            { value: 'SK Slowakije', text: this.$t('countries.SK') },
            { value: 'SR Suriname', text: this.$t('countries.SR') },
            { value: 'ST St. Tomé en Principe', text: this.$t('countries.ST') },
            { value: 'SY Syrië', text: this.$t('countries.SY') },
            { value: 'TH Thailand', text: this.$t('countries.TH') },
            { value: 'TN Tunesië', text: this.$t('countries.TN') },
            { value: 'TR Turkije', text: this.$t('countries.TR') },
            { value: 'TZ Tanzania', text: this.$t('countries.TZ') },
            { value: 'US Amerika', text: this.$t('countries.US') },
            { value: 'VN Vietnam', text: this.$t('countries.VN') },
            { value: 'ZZ anders', text: this.$t('countries.ZZ') },
        ]

        mounted() {
            // Broadcast the pre-populated data so they get picked by
            // the parent component, even if the user doesn't change something.
            this.$emit('input', this.formData)
        }

        @Watch('formData', { deep: true })
        watchFormData() {
            this.$emit('input', this.formData)
        }
    }
