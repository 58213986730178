import { Expose, Type } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'
import { InvoiceLine } from '@/app/invoice/entities/invoice-line.entity'

export class Invoice extends Entity<Invoice> {
    @Expose()
    id!: string

    @Expose({ name: 'public_number' })
    publicNumber!: string

    @Expose({ name: 'date_created' })
    dateCreated!: string

    @Expose()
    total!: number

    @Expose({ name: 'product_action' })
    productAction!: string

    @Expose()
    @Type(() => InvoiceLine)
    lines!: InvoiceLine[]
}
