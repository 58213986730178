
    import { mapGetters, mapState } from 'vuex'
    import { Component, Vue, Inject } from 'vue-property-decorator'
    import Layout from '../../common/components/layout/layout.vue'
    import MortgageStatus from '../components/mortgage-status.vue'
    import MortgageOverview from '../components/mortgage-overview.vue'
    import { Mortgage as MortgageEntity } from '@/app/mortgage/entities/mortgage.entity'
    import { GetAllMortgagesCommand } from '@/app/mortgage/commands/get-all-mortgages.command'
    import { License } from '@/app/license/entities/license.entity'
    import ProductLayout from '@/app/product/components/product-layout.vue'

    @Component({
        components: { MortgageOverview, Layout, MortgageStatus, ProductLayout },
        metaInfo() {
            return {
                title: 'Mortgage'
            }
        },
        computed: {
            ...mapGetters('mortgage', ['getByMortgageId']),
            ...mapState('mortgage', ['mortgages']),
            ...mapState('license', ['license'])
        }
    })
    export default class Mortgage extends Vue {
        mortgage: MortgageEntity | null = null
        mortgages!: MortgageEntity[]
        license!: License
        getByMortgageId!: (id: string) => MortgageEntity | undefined

        @Inject() getAllMortgagesCommand!: GetAllMortgagesCommand

        get showTimeline() {
            return this.license.features.showMortgageTimeline && this.mortgage?.hasTimeline && Array.isArray(this.mortgage?.timeline)
        }

        async mounted() {
            await this.getAllMortgages()

            const id = this.$route.params.id
            this.mortgage = this.getByMortgageId(id) ?? null
        }

        async getAllMortgages() {
            if (!this.mortgages.length) {
                await this.getAllMortgagesCommand.run()
            }
        }
    }
