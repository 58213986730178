
import {Component, Inject, Vue} from 'vue-property-decorator'
import {mapGetters} from 'vuex'
import Layout from '../../common/components/layout/layout.vue'
import DatePicker from '@/app/common/components/date-picker.vue'
import {ProductAction} from '@/app/product/entities/product-action.entity'
import {GetAllActionsCommand} from '@/app/product/commands/get-all-actions.command'
import {
    UpdateDateConditionPrecedentCommand
} from '@/app/product/commands/update-date-condition-precedent.command'
import {NotificationDispatcher} from '@/app/common/services/notification-dispatcher.service'
import {VForm} from '@/app/common/types/v-form.type'
import validation from '@/app/common/helpers/validation'
import SimpleTable from '@/app/common/components/simple-table.vue'

@Component({
    components: {Layout, DatePicker, SimpleTable},
    metaInfo() {
        return {
            title: 'Aanleveren datum ontbindende voorwaarden'
        }
    },
    computed: {
        ...mapGetters('product', ['getByProductActionId'])
    }
})
export default class UpdateDateConditionsPrecedent extends Vue {
    productAction: ProductAction | null = null
    dateConditionsPrecedent = ''
    saving = false
    rules = validation
    getByProductActionId!: (id: string) => ProductAction | undefined

    @Inject() getAllActionsCommand!: GetAllActionsCommand
    @Inject() updateDateConditionPrecedentCommand!: UpdateDateConditionPrecedentCommand
    @Inject() notificationDispatcher!: NotificationDispatcher

    get form(): VForm {
        return this.$refs.form as VForm
    }

    async mounted() {
        await this.getAllActionsCommand.run()
        const id = this.$route.params.action
        this.productAction = this.getByProductActionId(id) ?? null
    }

    validate(): boolean {
        return this.form.validate()
    }

    async save() {
        try {
            this.saving = true
            await this.updateDateConditionPrecedentCommand.run(this.productAction!.id, this.dateConditionsPrecedent)
            await this.notificationDispatcher.success(this.$t('productaction.REQUEST_DATE_CONDITIONS_PRECEDENT.submit_success').toString())
            await this.$router.push({name: 'dashboard'})
        } catch (err) {
            await this.notificationDispatcher.error(err.message)
        } finally {
            this.saving = false
        }
    }

}
