import i18n from '@/plugins/i18n'

export default {
    required(message?: string) {
        return (v: string) => !!v || (message ?? i18n.t('page.validation.required').toString())
    },

    requiredNoMessage(message?: string) {
        return (v: string) => !!v || (message ?? i18n.t('page.validation.no_message').toString())
    },

    email(message?: string) {
        return (v: string) => (!!v && !/.+@.+\..+/.test(v)) ? (message ?? i18n.t('page.validation.email').toString()) : true
    },

    time(message?: string) {
        return (v: string) => (!!v && !/^(?:[01]\d|2[0-3]):[0-5]\d$/.test(v)) ? (message ?? i18n.t('page.validation.time').toString()) : true
    },

    password(message?: string, length = 5) {
        return (v: string) => (v && v.length >= (length)) || (message ?? i18n.t('page.validation.password', { length }).toString())
    },

    matches(value: string, message: string) {
        return (v: string) => !!v && !!value && v === value || message
    },

    filesRequired(message?: string) {
        return (value?: File[]) => !value || value.length > 0 || (message ?? i18n.t('page.validation.files_required').toString())
    },

    numberRange(from: number, to: number, message?: string) {
        return (v: string) => !!v && parseFloat(v) >= from && parseFloat(v) <= to || (message ?? i18n.t('page.validation.number_range', { from, to }).toString())
    },

    filesSize(message?: string, maxSize = 25) {
        return (value?: File[]) => {
            const maxSizeBytes = maxSize * 1024 * 1024
            const error = message ?? i18n.t('page.validation.filesize', { maxSize }).toString()

            if (!Array.isArray(value)) {
                return true
            }

            const size = value.reduce((acc: number, item: File) => {
                return acc + item.size
            }, 0)

            return size < maxSizeBytes || error
        }
    },

    selectAmount(amount: number, message?: string) {
        return (v: string[]) => v.length >= amount || (message ?? amount > 1 ? i18n.t('page.validation.minimum_amount.plural', { amount }).toString() : i18n.t('page.validation.minimum_amount.singular').toString())
    },

    currency(message?: string) {
        return (v: string) => {
            // if string is not empty check if only consists of valid characters
            if (!!v && !/[0-9.,]/.test(v)) {
                return message ?? i18n.t('page.validation.currency').toString()
            }

            return true
        }
    }
}
