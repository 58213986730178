
    import { mapState } from 'vuex'
    import { Component, Vue, Inject } from 'vue-property-decorator'
    import Layout from '../../common/components/layout/layout.vue'
    import { GetAllMortgagesCommand } from '@/app/mortgage/commands/get-all-mortgages.command'
    import { Mortgage } from '@/app/mortgage/entities/mortgage.entity'

    @Component({
        components: { Layout },
        metaInfo() {
            return {
                title: 'Mortgages'
            }
        },
        computed: {
            ...mapState('mortgage', ['mortgages'])
        }
    })
    export default class MortgageList extends Vue {
        mortgages!: Mortgage[]

        @Inject() getAllMortgagesCommand!: GetAllMortgagesCommand

        mounted() {
            this.getAllMortgagesCommand.run()
        }
    }
