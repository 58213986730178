import moment from 'moment'
import { GetterTree } from 'vuex'
import { RootState } from '@/app/common/types/root-state.type'
import { AppointmentState } from './state'
import { Appointment } from '@/app/appointment/entities/appointment.entity'

export const getters: GetterTree<AppointmentState, RootState> = {

    getByAppointmentId(state: AppointmentState): (id: string) => Appointment | undefined {
        return (id: string) => {
            const appointments = state.appointments.filter(c => c.id === id)
            return appointments?.[0]
        }
    },

    getFirstUpcomingAppointment(state: AppointmentState): Appointment | undefined {
        const today = moment().format('YYYY-MM-DD')
        const appointments = state.appointments.filter((appointment: Appointment) => {
            const appointmentDate = moment(appointment.date)
            return appointmentDate.isSameOrAfter(today)
        })

        return appointments?.[0]
    }
}
