
    import { mapGetters } from 'vuex'
    import { Component, Vue, Inject } from 'vue-property-decorator'
    import Layout from '../../common/components/layout/layout.vue'
    import { CardifAo } from '@/app/cardifao/entities/cardifao'
    import { GetAllCardifAosCommand } from '@/app/cardifao/commands/get-all-cardifaos.command'
    import { GetAllActionsCommand } from '@/app/product/commands/get-all-actions.command'

    @Component({
        components: { Layout },
        metaInfo() {
            return {
                title: 'Cardif AO'
            }
        },
        computed: {
            ...mapGetters('cardifAo', ['cardifAosWithActionPoints'])
        }
    })
    export default class CardifAoList extends Vue {
        cardifAos: CardifAo[] = []
        cardifAosWithActionPoints!: CardifAo[]

        @Inject() getAllCardifAosCommand!: GetAllCardifAosCommand
        @Inject() getAllActionsCommand!: GetAllActionsCommand

        async mounted() {
            await this.getAllCardifAosCommand.run()
            await this.getAllActionsCommand.run()

            this.cardifAos = this.cardifAosWithActionPoints
        }
    }
