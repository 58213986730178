
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
    import localizeDate from '@/app/common/helpers/localize-date'

    @Component
    export default class DatePicker extends Vue {
        @Prop({ type: String }) readonly value!: string|null
        @Prop({ type: Boolean, default: false }) readonly solo!: boolean
        @Prop({ type: String }) readonly label!: string|null
        @Prop({ type: Boolean, default: false }) readonly noIcon!: boolean
        @Prop({ type: Boolean, default: true }) readonly clearable!: boolean
        @Prop({ default: false }) readonly hideDetails!: boolean|'auto'
        @Prop() readonly rules!: any[]
        @Prop({ type: String }) readonly maxDate!: string|null

        menu = false
        date: string|null = null
        dateFormatted: string|null = null

        @Watch('value')
        watchValue(value: string) {
            if (value !== this.date) {
                this.date = value
            }
        }

        @Watch('date')
        watchDate(value: string) {
            this.dateFormatted = value ? localizeDate(value) : null
            this.$emit('input', value)
        }

        @Watch('dateFormatted')
        watchDateFormatted(value: string) {
            if (!value) {
                this.date = null
            }
        }

        mounted() {
            this.date = this.value
        }

        getIcon() {
            return this.noIcon ? undefined : 'fal fa-calendar-day'
        }
    }
