import { Expose } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'

export class LicenseFeature extends Entity<LicenseFeature> {
    @Expose({ 'name': 'show_appointment_form' })
    showAppointmentForm!: boolean

    @Expose({ 'name': 'do_show_incometype_field' })
    showIncomeTypeField!: boolean

    @Expose({ 'name': 'do_show_productphase_field' })
    showProductPhaseField!: boolean

    @Expose({ 'name': 'do_show_mortgage_timeline' })
    showMortgageTimeline!: boolean

    @Expose({ 'name': 'do_show_purchaseintention_timeline' })
    showPurchaseIntentionTimeline!: boolean

    @Expose({ 'name': 'can_cancel_appointments' })
    canCancelAppointments!: boolean

    @Expose({ 'name': 'show_dossier_number' })
    showDossierNumber!: boolean

    @Expose({ 'name': 'default_address_style' })
    defaultAddressTyle!: string

    @Expose({ 'name': 'show_appointment_type' })
    showAppointmentType!: boolean
}
