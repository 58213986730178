import { ActionTree, Commit } from 'vuex'
import { RootState } from '@/app/common/types/root-state.type'
import { CardifAoState } from '@/app/cardifao/store/state'
import { CardifAo } from '@/app/cardifao/entities/cardifao'

export const actions: ActionTree<CardifAoState, RootState> = {

    setCardifAos({ commit }: { commit: Commit }, cardifAos: CardifAo[]) {
        commit('setCardifAos', cardifAos)
    }

}
