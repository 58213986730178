import { Expose, Type } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'
import { ImportantDocumentCategory } from '@/app/important-documents/entities/document-category.entity'

export class Document extends Entity<Document> {
    @Expose()
    id!: string

    @Expose()
    label!: string

    @Expose()
    filename!: string

    @Expose()
    description!: string

    @Expose()
    language!: string

    @Expose()
    @Type(() => ImportantDocumentCategory)
    importantDocumentCategory!: ImportantDocumentCategory
}
