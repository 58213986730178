import { MutationTree } from 'vuex'
import { SiteNotificationState } from '@/app/notifications/store/state'
import { Notification } from '@/app/notifications/entities/notification.entity'

export const mutations: MutationTree<SiteNotificationState> = {

    setNotifications(state: SiteNotificationState, notifications: Notification[]) {
        state.notifications = notifications
    }

}
