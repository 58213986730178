import { Command } from '@/app/common/base/command'
import { DomainLicense } from '@/app/license/entities/domain-license.entity'

export class GetLicensesCommand extends Command {
    public async run(): Promise<DomainLicense[]> {
        const response = await this.client.get('/api/licenses')
        const licences = response.asEntities(DomainLicense)
        this.store.dispatch('license/setLicense', licences)
        return licences
    }
}
