import { ActionTree, Commit } from 'vuex'
import { RootState } from '@/app/common/types/root-state.type'
import { ApiEntityState } from '@/app/api-entity/store/state'
import { ProductPhase } from '@/app/api-entity/entities/product-phase.entity'
import { IncomeType } from '@/app/api-entity/entities/income-type.entity'

export const actions: ActionTree<ApiEntityState, RootState> = {

    setProductPhases({ commit }: { commit: Commit }, productPhases: ProductPhase[]) {
        commit('setProductPhases', productPhases)
    },

    setIncomeTypes({ commit }: { commit: Commit }, incomeTypes: IncomeType[]) {
        commit('setIncomeTypes', incomeTypes)
    }

}
