import { Entity } from '@/app/common/base/entity'
import { Expose, Type } from 'class-transformer'
import { ProductActionCounter } from '@/app/product/entities/product-action-counter.entity'
import { Wills } from '@/app/will_writing_advice/entities/wills.entity'

export class WillWritingAdvice extends Entity<WillWritingAdvice> {

    @Expose()
    id!: string

    @Expose()
    label!: string

    @Expose()
    notary!: string

    @Expose()
    wozAmount!: number

    @Expose()
    supplier!: string

    @Expose()
    status!: string

    @Expose({ name: 'action_counter' })
    @Type(() => ProductActionCounter)
    actionCounter!: ProductActionCounter

    @Expose({ name: 'wills' })
    @Type(() => Wills)
    wills!: Wills[]
}
