import { GetterTree } from 'vuex'
import { RootState } from '@/app/common/types/root-state.type'
import { WillWritingAdviceState } from '@/app/will_writing_advice/store/state'
import { WillWritingAdvice } from '@/app/will_writing_advice/entities/will-writing-advice.entity'
export const getters: GetterTree<WillWritingAdviceState, RootState> = {

    getByWillWritingAdviceId(state: WillWritingAdviceState): (id: string) => WillWritingAdvice | undefined {
        return (id: string) => {
            const willWritingAdvices = state.willWritingAdvices.filter(will => will.id === id)
            return willWritingAdvices?.[0]
        }
    }

}
