
    import { Component, Vue, Prop } from 'vue-property-decorator'
    import TruemeetingButton from './truemeeting-button.vue'
    import { Appointment } from '@/app/appointment/entities/appointment.entity'

    @Component({
        components: { TruemeetingButton }
    })
    export default class AppointmentListItem extends Vue {
        @Prop({ type: Object, required: true }) appointment!: Appointment
    }
