import { Command } from '@/app/common/base/command'
import { GeneralInsurance } from '@/app/general-insurance/entities/general-insurance.entity'

export class GetAllGeneralInsurancesCommand extends Command {
    public async run() {
        const response = await this.client.get('/api/products/general-insurances')
        const generalInsurances = response.asEntities(GeneralInsurance)

        await this.store.dispatch('generalInsurance/setGeneralInsurances', generalInsurances)
    }
}
