
    import { mapGetters, mapState } from 'vuex'
    import { Component, Inject, Vue } from 'vue-property-decorator'
    import Layout from '../../common/components/layout/layout.vue'
    import BankguaranteeOverview from '../components/bankguarantee-overview.vue'
    import BnpBankguaranteeOverview from '@/app/bankguarantee/components/bnp-bankguarantee-overview.vue'
    import { GetAllBankguaranteesCommand } from '@/app/bankguarantee/commands/get-all-bankguarantees.command'
    import ProductLayout from '@/app/product/components/product-layout.vue'
    import { Bankguarantee as BankguaranteeEntity } from '@/app/bankguarantee/entities/bankguarantee.entity'

    @Component({
    components: { Layout, BnpBankguaranteeOverview, BankguaranteeOverview, ProductLayout },
    metaInfo() {
        return {
            title: 'Bank garantie'
        }
    },
        computed: {
            ...mapGetters('bankguarantee', ['getByBankguaranteeId']),
            ...mapState('bankguarantee', ['bankguarantees'])
        }
})
    export default class Bankguarantee extends Vue {
        bankguarantee: BankguaranteeEntity | null = null
        bankguarantees!: BankguaranteeEntity[]
        getByBankguaranteeId!: (id: string) => BankguaranteeEntity | undefined

        @Inject() getAllBankguaranteesCommand!: GetAllBankguaranteesCommand

        async mounted() {
            await this.getAllBankguarantees()

            const id = this.$route.params.id
            this.bankguarantee = this.getByBankguaranteeId(id) ?? null
        }

        async getAllBankguarantees() {
            if (!this.bankguarantees.length) {
                await this.getAllBankguaranteesCommand.run()
            }
        }
    }
