import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import storePersist from './store-persist'
import { RootState } from '@/app/common/types/root-state.type'
import { globalStore } from '@/app/store'
import { authStore } from '@/app/auth/store'
import { notificationStore } from '@/app/common/components/notifications/notification.store'
import { importantDocumentStore } from '@/app/important-documents/store'
import { productStore } from '@/app/product/store'
import { creditStore } from "@/app/credit/store"
import { mortgageStore } from '@/app/mortgage/store'
import { bankguaranteeStore } from '@/app/bankguarantee/store'
import { lifeInsuranceStore } from '@/app/life-insurance/store'
import { fgvStore } from '@/app/fgv/store'
import { appointmentStore } from "@/app/appointment/store"
import { generalInsuranceStore } from "@/app/general-insurance/store"
import { dossierStore } from "@/app/dossier/store"
import { licenseStore } from '@/app/license/store'
import { apiEntityStore } from '@/app/api-entity/store'
import { cardifAoStore } from '@/app/cardifao/store'
import { otdStore } from "@/app/otd/store"
import { maintenanceStore } from '@/app/maintenance/store'
import { purchaseIntentionStore } from '@/app/purchase-intention/store'
import { siteNotificationStore } from '@/app/notifications/store'
import { invoiceStore } from '@/app/invoice/store'
import { businessIncomeStore } from '@/app/business-income/store'
import {willWritingAdviceStore} from "@/app/will_writing_advice/store";
import {woongarantInsuranceStore} from "@/app/woongarant-insurance/store";

Vue.use(Vuex)

const store: StoreOptions<RootState> = {
    plugins: [storePersist.plugin],
    modules: {
        global: globalStore,
        auth: authStore,
        notification: notificationStore,
        importantDocuments: importantDocumentStore,
        product: productStore,
        credit: creditStore,
        mortgage: mortgageStore,
        appointment: appointmentStore,
        bankguarantee: bankguaranteeStore,
        businessIncome: businessIncomeStore,
        lifeInsurance: lifeInsuranceStore,
        fgv: fgvStore,
        generalInsurance: generalInsuranceStore,
        dossier: dossierStore,
        license: licenseStore,
        apiEntity: apiEntityStore,
        maintenance: maintenanceStore,
        cardifAo: cardifAoStore,
        otd: otdStore,
        purchaseIntention: purchaseIntentionStore,
        siteNotification: siteNotificationStore,
        invoice: invoiceStore,
        willWritingAdvice: willWritingAdviceStore,
        woongarantInsurance: woongarantInsuranceStore,
    }
}

export default new Vuex.Store<RootState>(store)
