import { Command } from "@/app/common/base/command"
import { Consumer } from "@/app/identity"
import { ProductActionAuthEntity } from "@/app/auth/entities/product-action-auth.entity"

export class ConsumerDataNoLoginCommand extends Command {

    public async run(productActionId: string, auth: ProductActionAuthEntity): Promise<Consumer> {
        const response = await this.client.get(`/api/consumer-no-login/${productActionId}/${auth.id}`, {
            headers: {
                'Token': auth.token
            }
        })

        return response.asEntity(Consumer)
    }

}
