
    import { Vue, Component, Watch } from 'vue-property-decorator'
    import { mapGetters, mapState } from 'vuex'
    import { License } from '@/app/license/entities/license.entity'
    import { Language } from '@/app/license/entities/language.entity'
    import moment from 'moment'

    @Component({
        computed: {
            ...mapState('license', ['license', 'languages']),
            ...mapGetters('auth', ['isAuthenticated'])
        }
    })
    export default class TopMenu extends Vue {
        license!: License
        locale = this.$i18n.locale
        languages!: Language[]
        isAuthenticated!: boolean

        @Watch('locale')
        updateLocale(locale: string) {
            this.$i18n.locale = locale
            this.$vuetify.lang.current = locale
            moment.locale(locale)

            this.$store.dispatch('global/setLocale', locale)
        }

        flag(locale: string) {
            // eslint-disable-next-line import/no-dynamic-require,global-require
            return require(`../../../../assets/flags/${locale}.svg`)
        }
    }
