
    import { Component, Inject, Prop, Vue } from "vue-property-decorator"
    import { VForm } from "@/app/common/types/v-form.type"
    import { NotificationDispatcher } from "@/app/common/services/notification-dispatcher.service"
    import { Consumer } from "@/app/identity"
    import validation from "@/app/common/helpers/validation"
    import { mapState } from "vuex"

    @Component({
        computed: {
            ...mapState('auth', ['consumer'])
        }
    })
    export default class Savings extends Vue {
        formData = {
            currentCostsAffordable: '',
            monthlySavings: null as number | null,
            totalSavings: null as number | null,
            maxInputSavings: null as number | null
        }
        @Inject() notificationDispatcher!: NotificationDispatcher
        consumer!: Consumer
        rules = validation
        @Prop({ default: () => ({}) }) readonly externalFormData!: any
        mounted() {
            this.formData = { ...this.formData, ...this.externalFormData }
        }
        get form(): VForm {
            return this.$refs.form as VForm
        }
        validate(): boolean {
            return this.form.validate()
        }
        goToPreviousStep() {
            this.$emit('changeStep', 4)
        }
        goToNextStep() {
            if (this.validate()) {
                this.formData = {
                    currentCostsAffordable: this.formData.currentCostsAffordable || '',
                    monthlySavings: this.formData.monthlySavings ?? 0,
                    totalSavings: this.formData.totalSavings ?? 0,
                    maxInputSavings: this.formData.maxInputSavings ?? 0,
                }
                this.$emit('updateData', this.formData)
                this.$emit('changeStep', 6)
            }
        }
    }
