import { MutationTree } from 'vuex'
import { ImportantDocumentState } from './state'
import { Document } from '@/app/important-documents/entities/document.entity'

export const mutations: MutationTree<ImportantDocumentState> = {
    updateDocuments(state: ImportantDocumentState, documents: Document[]) {
        state.documents = documents

        const documentCategories: any = {}

        documents.forEach((document: Document) => {
            const category = document.importantDocumentCategory

            if (!documentCategories[category.id]) {
                documentCategories[category.id] = category
                documentCategories[category.id].documents = []
            }

            documentCategories[category.id].documents.push(document)
        })

        state.documentsByCategory = documentCategories
    }
}
