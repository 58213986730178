import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'
import routes from '../app/routes'
import { jwtDecode, JwtPayload } from "jwt-decode"

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        return savedPosition || { x: 0, y: 0 }
    }
})

interface PortalJwtPayload extends JwtPayload {
    twoFactorSetup: boolean
}

router.beforeEach((to, from, next) => {
    const isAuthenticated = store.getters['auth/isAuthenticated']
    const guest = to?.meta?.guest || false

    if (to?.query?.accessToken !== undefined) {
        next()
    } else if (!isAuthenticated && !guest) {
        next({ name: 'login' })
    } else if (isAuthenticated && guest) {
        next({ name: 'dashboard' })
    } else {
        const token = store.state.auth?.accessToken

        if (to.name !== 'settings' && token !== undefined && (jwtDecode(token) as PortalJwtPayload).twoFactorSetup) {
            if (to.name === 'logout') {
                next()
            } else {
                next({ name: 'settings' })
            }
        } else {
            next()
        }
    }
})


export default router
