import { Command } from '@/app/common/base/command'

export class UpdatePresentOpportunityToConsumerCommand extends Command {
    public async run(productApiId: string, productActionId: string, isAgreed: string, customAmount: number, customPeriod: string, futureDate: string ) {
        await this.client.post(`/api/products/mortgages/${productApiId}/update-present-opportunity-to-consumer`, {
            productActionId,
            futureDate,
            isAgreed,
            customAmount,
            customPeriod
        })
    }
}
