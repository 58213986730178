
    import { mapGetters, mapState } from 'vuex'
    import { Component, Vue, Inject } from 'vue-property-decorator'
    import Layout from '../../common/components/layout/layout.vue'
    import GeneralInsuranceOverview from '@/app/general-insurance/components/general-insurance-overview.vue'
    import { GeneralInsurance as GeneralInsuranceEntity } from '@/app/general-insurance/entities/general-insurance.entity'
    import { GetAllGeneralInsurancesCommand } from '@/app/general-insurance/commands/get-all-general-insurances.command'
    import SimpleTable from '@/app/common/components/simple-table.vue'
    import ProductLayout from '@/app/product/components/product-layout.vue'

    @Component({
        components: { GeneralInsuranceOverview, Layout, SimpleTable, ProductLayout },
        metaInfo() {
            return {
                title: 'General Insurance'
            }
        },
        computed: {
            ...mapGetters('generalInsurance', ['getByGeneralInsuranceId']),
            ...mapState('generalInsurance', ['generalInsurances'])
        }
    })
    export default class GeneralInsurance extends Vue {
        generalInsurance: GeneralInsuranceEntity | null = null
        getByGeneralInsuranceId!: (id: string) => GeneralInsuranceEntity | undefined
        generalInsurances!: GeneralInsuranceEntity[]

        @Inject() getAllGeneralInsurancesCommand!: GetAllGeneralInsurancesCommand

        get productId(): string {
            return this.generalInsurance?.productId ?? ''
        }

        get generalInsuranceId(): string {
            return this.$route.params.id
        }

        async mounted() {
            await this.getAllGeneralInsurances()
            this.generalInsurance = this.getByGeneralInsuranceId(this.generalInsuranceId) ?? null
        }

        async getAllGeneralInsurances() {
            // Fetch the insurances if needed. This won't happen
            // if they navigate from the insurance list page, but will
            // happen if they visit this page directly.
            if (!this.generalInsurances.length) {
                await this.getAllGeneralInsurancesCommand.run()
            }
        }
    }
