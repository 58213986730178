import { HttpClient } from '@/app/common/services/http/http-client.interface'

export abstract class Command {
    protected client: HttpClient
    protected store: any

    public abstract run(...data: any): any

    constructor(client: HttpClient, store: any) {
        this.client = client
        this.store = store
    }
}
