export enum SmartcareSavingPeriod {
    monthly = 'MONTHLY',
    annually = 'ANNUALLY',
    remainingDuration = 'REMAINING_DURATION'
}

export const presentSmartcareSavingsPeriods = [
    { text: 'common.per_month', value: SmartcareSavingPeriod.monthly },
    { text: 'common.per_year', value: SmartcareSavingPeriod.annually },
    { text: 'common.per_remaining_duration', value: SmartcareSavingPeriod.remainingDuration }
]

export const smartcareSavingPeriods = Object.values(SmartcareSavingPeriod) as string[]
