import Vue from 'vue'
import Vuei18n from 'vue-i18n'
import localeMessages from '@/locale'

Vue.use(Vuei18n)

export default new Vuei18n({
    locale: 'nl',
    fallbackLocale: 'nl',
    silentTranslationWarn: true,
    silentFallbackWarn: true,
    messages: {
        ...localeMessages
    }
})
