import VuexPersistence from 'vuex-persist'
import { RootState } from '@/app/common/types/root-state.type'
import { classToPlain, plainToClass } from 'class-transformer'
import { Consumer } from '@/app/identity'

export default new VuexPersistence<RootState>({
    key: 'accelerate',
    storage: window.localStorage,
    reducer: (state: RootState) => ({
        global: {
            version: state.global.version,
            locale: state.global.locale
        },
        auth: {
            consumer: classToPlain(state.auth.consumer),
            accessToken: state.auth.accessToken,
            refreshToken: state.auth.refreshToken,
            tempLogin: state.auth.tempLogin,
        },
        license: {
            domain: state.license.domain
        }
    }),
    restoreState: (key: string, storage?: Storage): RootState => {
        const state = storage?.getItem(key)
        const data = JSON.parse(state ?? '{}')

        if (data?.auth?.consumer) {
            data.auth.consumer = plainToClass(Consumer, data.auth.consumer)
        }

        return data
    }
})
