import { GetterTree } from 'vuex'
import { RootState } from '@/app/common/types/root-state.type'
import { PurchaseIntentionState } from '@/app/purchase-intention/store/state'
import { PurchaseIntention } from '@/app/purchase-intention/entities/purchase-intention.entity'

export const getters: GetterTree<PurchaseIntentionState, RootState> = {

    getByPurchaseIntentionId(state: PurchaseIntentionState): (id: string) => PurchaseIntention | undefined {
        return (id: string) => {
            const purchaseIntentions = state.purchaseIntentions.filter(p => p.id === id)
            return purchaseIntentions?.[0]
        }
    }

}
