
    import { mapState } from 'vuex'
    import { Vue, Component, Inject } from 'vue-property-decorator'
    import { ProductDefinition } from '@/app/product/entities/product-definition.entity'
    import { ProductDefinitionMeta } from '@/app/product/entities/product-definition-meta.entity'
    import { GetProductDefinitionsCommand } from '@/app/product/commands/get-product-definitions.command'

    @Component({
        computed: {
            ...mapState('product', ['definitions'])
        }
    })
    export default class MainMenu extends Vue {
        definitions!: ProductDefinition

        @Inject() getProductDefinitionsCommand!: GetProductDefinitionsCommand

        buildRoute(definition: ProductDefinitionMeta, listRoute: string, detailRoute: string): object {
            if (definition.counter > 1) {
                return { name: listRoute }
            }

            return { name: detailRoute, params: { id: definition.firstProductId } }
        }

        async mounted() {
            if (!this.definitions) {
                await this.getProductDefinitionsCommand.run()
            }
        }
    }
