
    import { Component, Inject, Vue } from 'vue-property-decorator'
    import { mapGetters, mapState } from 'vuex'
    import Layout from '@/app/common/components/layout/layout.vue'
    import SimpleTable from '@/app/common/components/simple-table.vue'
    import { ProductAction } from '@/app/product/entities/product-action.entity'
    import { GetAllActionsCommand } from '@/app/product/commands/get-all-actions.command'
    import { NotificationDispatcher } from '@/app/common/services/notification-dispatcher.service'
    import { Consumer } from '@/app/identity'
    import { DownloadInvoiceCommand } from '@/app/invoice/commands/download-invoice.command'
    import { GetInvoicePaymentCommand } from '@/app/invoice/commands/get-invoice-payment.command'

    @Component({
        components: { Layout, SimpleTable },
        metaInfo() {
            return {
                title: 'Pay for invoice'
            }
        },
        computed: {
            ...mapState('auth', ['consumer']),
            ...mapGetters('product', ['getByProductActionId'])
        }
    })
    export default class InvoicePayment extends Vue {
        productAction?: ProductAction | null = null
        consumer!: Consumer
        getByProductActionId!: (id: string) => ProductAction | undefined
        downloadLoading = false
        paymentLoading = false

        @Inject() getAllActionsCommand!: GetAllActionsCommand
        @Inject() downloadInvoiceCommand!: DownloadInvoiceCommand
        @Inject() getInvoicePaymentCommand!: GetInvoicePaymentCommand
        @Inject() notificationDispatcher!: NotificationDispatcher

        async mounted() {
            await this.getAllActionsCommand.run()
            const id = this.$route.params.action
            this.productAction = this.getByProductActionId(id) ?? null
        }

        async downloadInvoice(id: string) {
            try {
                this.downloadLoading = true
                await this.downloadInvoiceCommand.run(id)
            } catch (err) {
                await this.notificationDispatcher.error(err.message)
            } finally {
                this.downloadLoading = false
            }
        }

        async makePayment() {
            try {
                this.paymentLoading = true

                const status = await this.getInvoicePaymentCommand.run({
                    productId: this.productAction!.product.id,
                    productActionId: this.productAction!.id,
                    invoiceId: this.productAction!.invoice.id,
                })

                if (status.canBePaid()) {
                    window.location.href = status.url
                } else if (status.isPaid()) {
                    this.paymentLoading = false
                    await this.notificationDispatcher.success(this.$t('page.ideal_payment.notifications.thankyou.body').toString())
                    await this.$router.push({ name: 'dashboard' })
                } else if (status.isPending()) {
                    this.paymentLoading = false
                    await this.notificationDispatcher.error(this.$t('page.ideal_payment.notifications.payment_pending.body').toString())
                    await this.$router.push({ name: 'dashboard' })
                } else {
                    await this.notificationDispatcher.error(this.$t('page.ideal_payment.notifications.payment_unknown_status.body').toString())
                }
            } catch (err) {
                this.paymentLoading = false
                await this.notificationDispatcher.error(err.message)
            }
        }
    }
