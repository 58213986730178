import { Expose, Type } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'
import { ProductActionCounter } from '@/app/product/entities/product-action-counter.entity'
import { FoundHousesCollection } from '@/app/purchase-intention/entities/found-houses-collection.entity'
import {PurchaseIntentionTimeline} from "@/app/purchase-intention/entities/purchase-intention-timeline.entity";

export class PurchaseIntention extends Entity<PurchaseIntention> {
    @Expose()
    id!: string

    @Expose()
    state!: string

    @Expose({ name: 'buyer_profile' })
    buyerProfile!: string

    @Expose({ name: 'object_type' })
    objectType!: string

    @Expose({ name: 'has_timeline' })
    hasTimeline!: boolean

    @Expose({ name: 'budget_from' })
    budgetFrom!: string

    @Expose({ name: 'budget_to' })
    budgetTo!: string

    @Expose({ name: 'action_counter' })
    @Type(() => ProductActionCounter)
    actionCounter!: ProductActionCounter

    @Expose()
    @Type(() => PurchaseIntentionTimeline)
    timeline!: PurchaseIntentionTimeline[]

    @Expose({ name: 'found_houses_collection' })
    @Type(() => FoundHousesCollection)
    foundHousescollection!: FoundHousesCollection[]
}
