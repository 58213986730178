import { Command } from '@/app/common/base/command'
import { Notification } from '@/app/notifications/entities/notification.entity'

export class GetNotificationsCommand extends Command {
    public async run() {
        const response = await this.client.get('/api/notifications')
        const notifications = response.asEntities(Notification)

        await this.store.dispatch('siteNotification/setNotifications', notifications)
    }
}
