import { Command } from '@/app/common/base/command'
import { LifeInsurance } from '@/app/life-insurance/entities/life-insurance.entity'

export class GetAllLifeInsurancesCommand extends Command {
    public async run() {
        const response = await this.client.get('/api/products/life-insurances')
        const lifeInsurances = response.asEntities(LifeInsurance)

        await this.store.dispatch('lifeInsurance/setLifeInsurances', lifeInsurances)
    }
}
