
    import { Component, Vue } from 'vue-property-decorator'

    @Component({
        metaInfo() {
            return {
                title: 'Not found'
            }
        }
    })
    export default class NotFound extends Vue {

    }
