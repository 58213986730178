import { Command } from '@/app/common/base/command'
import { Fgv } from '@/app/fgv/entities/fgv.entity'

export class GetAllFgvsCommand extends Command {
    public async run() {
        const response = await this.client.get('/api/products/fgvs')
        const fgvs = response.asEntities(Fgv)

        await this.store.dispatch('fgv/setFgvs', fgvs)
    }
}
