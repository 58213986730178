import { Entity } from '@/app/common/base/entity'
import { Expose } from 'class-transformer'

export class FoundHousesCollection extends Entity<FoundHousesCollection> {
    @Expose()
    id!: string

    @Expose({ name: 'date_created' })
    dateCreated!: string

    @Expose({ name: 'has_seen' })
    hasSeen!: boolean

    @Expose({ name: 'buildtype_label' })
    buildtypeLabel!: string

    @Expose({ name: 'objecttype_label' })
    objecttypeLabel!: string

    @Expose()
    address!: string

    @Expose()
    zipcode!: string

    @Expose()
    city!: string

    @Expose({ name: 'external_url' })
    externalUrl!: string

    @Expose()
    price!: string

    @Expose({ name: 'object_surface_m2' })
    objectSurfaceM2!: string

    @Expose({ name: 'ground_surface_m2' })
    groundSurfaceM2!: string

    @Expose({ name: 'raw_base64_thumbnail' })
    rawBase64Thumbnail!: string

}
