import { Command } from '@/app/common/base/command'

export class DownloadOtdCommand extends Command {
    public async run(actionId: string, filename = 'otd.pdf') {
        const response = await this.client.download(`/api/products/otds/actions/${actionId}/download`, {
            responseType: 'blob',
            timeout: 30000
        })

        const fileURL = window.URL.createObjectURL(response.data)
        const fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute('download', filename)
        document.body.appendChild(fileLink)
        fileLink.click()
    }
}
