
    import { Component, Vue, Inject, Prop } from "vue-property-decorator"
    import { VForm } from "@/app/common/types/v-form.type"
    import { NotificationDispatcher } from "@/app/common/services/notification-dispatcher.service"
    import validation from "@/app/common/helpers/validation"
    import { Consumer } from "@/app/identity"

    @Component
    export default class RiskTolerance extends Vue {
        formData = {
            riskInterestIncrease: '',
            yearsNoInterestIncrease: null as number | null,
            maxCostsIncrease: null as number | null
        }
        @Inject() notificationDispatcher!: NotificationDispatcher
        consumer!: Consumer
        rules = validation
        @Prop({ default: () => ({}) }) readonly externalFormData!: any
        mounted() {
            this.formData = { ...this.formData, ...this.externalFormData }
        }
        get form(): VForm {
            return this.$refs.form as VForm
        }
        validate(): boolean {
            return this.form.validate()
        }
        goToPreviousStep() {
            this.$emit('changeStep', 6)
        }
        goToNextStep() {
            if (this.validate()) {
                this.formData = {
                    riskInterestIncrease: this.formData.riskInterestIncrease || '',
                    yearsNoInterestIncrease: this.formData.yearsNoInterestIncrease ?? 0,
                    maxCostsIncrease: this.formData.maxCostsIncrease ?? 0,
                }
                this.$emit('updateData', this.formData)
                this.$emit('changeStep', 8)
            }
        }
    }
