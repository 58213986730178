export enum ProductActionType {
    downloadQuoteIndicativeProposal = 'DOWNLOAD_QUOTE_INDICATIVE_PROPOSAL',
    downloadQuoteFinalOffer = 'DOWNLOAD_QUOTE_FINAL_OFFER',
    downloadQuoteDocument = 'DOWNLOAD_QUOTE_DOCUMENT',
    downloadDocumentManagementFile = 'DOWNLOAD_DOCUMENTMANAGEMENT_FILE',
    downloadAddedDocument = 'DOWNLOAD_ADDED_DOCUMENT',
    downloadQuote = 'DOWNLOAD_QUOTE',
    uploadDocumentmanagementFile = 'UPLOAD_DOCUMENTMANAGEMENT_FILE',
    reuploadDocumentmanagementFile = 'REUPLOAD_DOCUMENTMANAGEMENT_FILE',
    fillInventoryForm = 'FILL_INVENTORY_FORM',
    fillInventoryIwize = 'FILL_INVENTORY_IWIZE',
    fillInventoryOckto = 'FILL_INVENTORY_OCKTO',
    requestDateConditionsPrecedent = 'REQUEST_DATE_CONDITIONS_PRECEDENT',
    signOtd = 'SIGN_OTD',
    downloadQuoteAfterPayment = 'DOWNLOAD_QUOTE_AFTER_PAYMENT',
    downloadOtd = 'DOWNLOAD_OTD',
    downloadSignedOtd = 'DOWNLOAD_SIGNED_OTD',
    downloadUnsignedOtd = 'DOWNLOAD_UNSIGNED_OTD',
    cardifaoStart = 'CARDIFAO_START',
    approveDocument = 'APPROVE_DOCUMENT',
    bnpBankguarantee = 'BNP_BANKGUARANTEE',
    presentOpportunityToConsumer = 'PRESENT_OPPORTUNITY_TO_CONSUMER'
}

export const productActionType = Object.values(ProductActionType) as string[]
