import { Command } from '@/app/common/base/command'

export class EditMarketValueCommand extends Command {
    public async run(productId: string, productActionId: string, marketValue: number, marketValueDate: string, basedOn: string) {
        await this.client.post(`/api/products/mortgages/${productId}/edit-market-value`, {
            productActionId,
            marketValue,
            marketValueDate,
            basedOn,
        })
    }
}
