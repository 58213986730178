
    import { Component, Prop, Vue } from 'vue-property-decorator'
    import { Appointment } from '@/app/appointment/entities/appointment.entity'
    import SimpleTable from '@/app/common/components/simple-table.vue'

    @Component({
        components: { SimpleTable }
    })
    export default class AppointmentCard extends Vue {
        @Prop({ type: Appointment, default: Appointment }) appointment!: Appointment
    }
