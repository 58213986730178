import { MutationTree } from 'vuex'
import { PurchaseIntentionState } from '@/app/purchase-intention/store/state'
import { PurchaseIntention } from '@/app/purchase-intention/entities/purchase-intention.entity'

export const mutations: MutationTree<PurchaseIntentionState> = {

    setPurchaseIntentions(state: PurchaseIntentionState, purchaseIntentions: PurchaseIntention[]) {
        state.purchaseIntentions = purchaseIntentions
    }

}
