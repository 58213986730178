
    import { Vue, Component, Prop } from 'vue-property-decorator'
    import SimpleTable from '@/app/common/components/simple-table.vue'
    import { UserPanel } from '@/app/dossier/entities/user-panel.entity'
    import { mapState } from 'vuex'
    import { Consumer } from '@/app/identity'

    @Component({
        components: { SimpleTable },
        computed: {
            ...mapState('auth', ['consumer'])
        }
    })
    export default class AdvisorCard extends Vue {
        consumer!: Consumer

        @Prop({ type: UserPanel, required: true }) advisor!: UserPanel
    }
