import { MutationTree } from 'vuex'
import { ProductState } from './state'
import { ProductAction } from '@/app/product/entities/product-action.entity'
import { ProductDefinition } from '@/app/product/entities/product-definition.entity'

export const mutations: MutationTree<ProductState> = {

    setProductActions(state: ProductState, productActions: ProductAction[]) {
        state.productActions = productActions
    },

    setDefinitions(state: ProductState, definitions: ProductDefinition) {
        state.definitions = definitions
    }

}
