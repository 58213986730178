
    import { mapState } from 'vuex'
    import { Component, Inject, Vue } from 'vue-property-decorator'
    import Layout from '../../common/components/layout/layout.vue'
    import AppointmentCard from '@/app/dashboard/components/appointment-card.vue'
    import { GetAllActionsCommand } from '@/app/product/commands/get-all-actions.command'
    import { GetImportantDocumentsCommand } from '@/app/important-documents/commands/get-important-documents.command'
    import { DownloadImportantDocumentCommand } from '@/app/important-documents/commands/download-important-document.command'
    import { NotificationDispatcher } from '@/app/common/services/notification-dispatcher.service'
    import { License } from '@/app/license/entities/license.entity'
    import hasLocalizedContent from '@/app/common/helpers/has-localized-content'
    import localizeContent from '@/app/common/helpers/localize-content'
    import { ImportantDocumentCategory } from '@/app/important-documents/entities/document-category.entity'
    import { Document as DocumentEntity } from '@/app/important-documents/entities/document.entity'

    @Component({
        components: { AppointmentCard, Layout },
        metaInfo() {
            return {
                title: 'Important documents'
            }
        },
        computed: {
            ...mapState('importantDocuments', ['documentsByCategory']),
            ...mapState('license', ['license'])
        }
    })
    export default class ImportantDocuments extends Vue {
        documentsByCategory!: ImportantDocumentCategory
        license!: License
        /**
         * Loading status is kept as an array to track multiple documents.
         * A document is being loaded when it's ID is present in this array.
         */
        loading: string[] = []
        hasLocalizedContent = hasLocalizedContent
        localizeContent = localizeContent

        @Inject() getAllActionsCommand!: GetAllActionsCommand
        @Inject() getImportantDocumentsCommand!: GetImportantDocumentsCommand
        @Inject() downloadImportantDocumentCommand!: DownloadImportantDocumentCommand
        @Inject() notificationDispatcher!: NotificationDispatcher

        async mounted() {
            await this.getImportantDocumentsCommand.run()
        }

        getUniqueDocuments(documents: any[]): any[] {
            const uniqueIds = new Set()
            return documents.filter(({ id }) => uniqueIds.has(id) ? false : uniqueIds.add(id))
        }

        getDocumentByLanguage(document: DocumentEntity): DocumentEntity | null {
            const currentLocale = this.$i18n.locale
            if (document.language === currentLocale) {
                return document
            }
            return Object.values(this.documentsByCategory)
                .flatMap((category: ImportantDocumentCategory) => category.documents)
                .find((doc: DocumentEntity) => doc.id === document.id && doc.language === currentLocale) || null
        }

        downloadDocumentForLanguage(document: DocumentEntity) {
            const localizedDoc = this.getDocumentByLanguage(document)

            if (localizedDoc) {
                this.download(localizedDoc.id, localizedDoc.filename, localizedDoc.language)
            }
        }

        async download(id: string, filename: string, language: string) {
            try {
                this.loading.push(id)
                await this.downloadImportantDocumentCommand.run(id, filename, language)
            } catch (err) {
                await this.notificationDispatcher.error(err.message)
            } finally {
                this.loading = this.loading.filter(item => item !== id)
            }
        }
    }
