import { Expose } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'
import localizeContent from '@/app/common/helpers/localize-content'

export class Banner extends Entity<Banner> {
    @Expose()
    title!: string

    @Expose()
    url!: string

    @Expose()
    image!: string

    shouldShow(): boolean {
        const url = localizeContent(this.url)
        const image = localizeContent(this.image)

        return url !== undefined && url !== null && url !== '' &&
            image !== undefined && image !== null && image !== ''
    }
}
