import { MutationTree } from 'vuex'
import { DossierState } from './state'
import { Dossier } from '@/app/dossier/entities/dossier.entity'

export const mutations: MutationTree<DossierState> = {

    setDossier(state: DossierState, dossier: Dossier) {
        state.dossier = dossier
    },

}
