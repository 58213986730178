
    import { mapState } from 'vuex'
    import { Component, Inject, Vue } from 'vue-property-decorator'
    import Layout from '../../common/components/layout/layout.vue'
    import { GetAllOtdsCommand } from '@/app/otd/commands/get-all-otds.command'
    import { Otd } from '@/app/otd/entities/otd.entity'

    @Component({
        components: { Layout },
        metaInfo() {
            return {
                title: 'OTDs'
            }
        },
        computed: {
            ...mapState('otd', ['otds'])
        }
    })
    export default class OtdList extends Vue {
        otds!: Otd[]

        @Inject() getAllOtdsCommand!: GetAllOtdsCommand

        mounted() {
            this.getAllOtdsCommand.run()
        }
    }
