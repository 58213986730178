import { Expose } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'

export class Product extends Entity<Product> {
    @Expose()
    id!: string

    @Expose()
    label!: string

    @Expose()
    type!: string
}
