import { MutationTree } from 'vuex'
import { WoongarantInsuranceState } from "@/app/woongarant-insurance/store/state"
import { WoongarantInsurance } from "@/app/woongarant-insurance/entities/woongarant-insurance.entity"

export const mutations: MutationTree<WoongarantInsuranceState> = {

    setWoongarantInsurances(state: WoongarantInsuranceState, woongarantInsurances: WoongarantInsurance[]) {
        state.woongarantInsurances = woongarantInsurances
    }

}
