import store from '@/plugins/store'
import { AxiosClient } from '@/app/common/services/http/axios-client.service'
import { LoadQrCommand } from '@/app/settings/commands/load-qr.command'
import { ConfirmTwoFactorCommand } from '@/app/settings/commands/confirm-two-factor.command'
import { DisableTwoFactorCommand } from '@/app/settings/commands/disable-two-factor.command'

const httpClient = new AxiosClient()

export const loadQrCommand = new LoadQrCommand(httpClient, store)
export const confirmTwoFactorCommand = new ConfirmTwoFactorCommand(httpClient, store)
export const disableTwoFactorCommand = new DisableTwoFactorCommand(httpClient, store)
