
    import { Component, Vue, Prop } from 'vue-property-decorator'
    import { LifeInsurance } from '@/app/life-insurance/entities/life-insurance.entity'
    import SimpleTable from '@/app/common/components/simple-table.vue'
    import { mapState } from 'vuex'
    import { Consumer } from '@/app/identity'

    @Component({
        components: { SimpleTable },
        computed: {
            ...mapState('auth', ['consumer'])
        }
    })
    export default class LifeInsuranceOverview extends Vue {
        @Prop({ type: LifeInsurance, required: true }) lifeInsurance!: LifeInsurance

        consumer!: Consumer
    }
