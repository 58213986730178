
    import { mapState } from 'vuex'
    import { Component, Vue } from 'vue-property-decorator'
    import Layout from '../../common/components/layout/layout.vue'
    import { License } from '@/app/license/entities/license.entity'
    import localizeContent from '@/app/common/helpers/localize-content'
    import ProductLayout from '@/app/product/components/product-layout.vue'

    @Component({
        components: { Layout, ProductLayout },
        metaInfo() {
            return {
                title: 'Cardif AO'
            }
        },
        computed: {
            ...mapState('license', ['license'])
        }
    })
    export default class CardifAo extends Vue {
        license!: License
        localizeContent = localizeContent
    }
