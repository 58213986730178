import { Entity } from '@/app/common/base/entity'
import { Expose } from 'class-transformer'

export class Wills extends Entity<Wills> {

    @Expose()
    id!: string

    @Expose()
    title!: string

    @Expose()
    owner!: string

    @Expose()
    startingDate!: string

}
