import { Expose, Type } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'
import { ProductActionCounter } from '@/app/product/entities/product-action-counter.entity'

export class Otd extends Entity<Otd> {
    @Expose()
    id!: string

    @Expose()
    label!: string

    @Expose({ name: 'grand_total' })
    grandTotal!: number

    @Expose({ name: 'total_at_once' })
    totalAtOnce!: number

    @Expose({ name: 'total_monthly' })
    totalMonthly!: number

    @Expose({ name: 'service_name' })
    serviceName!: string

    @Expose({ name: 'subservice_name' })
    subServiceName!: string

    @Expose({ name: 'sepa_payment_reference' })
    sepaPaymentReference?: string

    @Expose({ name: 'sepa_creditor_name' })
    sepaCreditorName!: string

    @Expose({ name: 'sepa_creditor_id' })
    sepaCreditorId!: number

    @Expose({ name: 'vat_percentage' })
    vatPercentage!: number

    @Expose({ name: 'vat_total_monthly' })
    vatTotalMonthly!: number

    @Expose({ name: 'has_automatic_debit_fees' })
    hasAutomaticDebitFees!: boolean

    @Expose({ name: 'sepa_accountholder_iban_account' })
    sepaAccountholderIbanAccount?: string

    @Expose({ name: 'sepa_accountholder_iban_bic' })
    sepaAccountholderIbanBic?: string

    @Expose({ name: 'sepa_accountholder_iban_name' })
    sepaAccountholderIbanName?: string

    @Expose({ name: 'action_counter' })
    @Type(() => ProductActionCounter)
    actionCounter!: ProductActionCounter

    @Expose({ name: 'is_cancelled' })
    isCancelled!: boolean

    @Expose({ name: 'is_signed' })
    isSigned!: boolean

    @Expose({ name: 'date_finalised' })
    dateFinalised!: string
}
