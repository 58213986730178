
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
    import { License } from "@/app/license/entities/license.entity"
    import { mapState } from "vuex"
    import localizeContent from '@/app/common/helpers/localize-content'
    import validation from '@/app/common/helpers/validation'

    @Component({
        computed: {
            ...mapState('license', ['license'])
        }
    })
    export default class OtdTerms extends Vue {
        @Prop() value!: undefined
        @Prop() agreeToTerms!: boolean

        license!: License
        localizeContent = localizeContent

        model = false
        scrolledToEnd = false

        rules = validation

        @Watch('value')
        updateModel(value: boolean) {
            this.model = value
        }

        onScroll() {
            const contentArea = this.$refs.contentArea as HTMLElement

            if (contentArea) {
                const { scrollTop, scrollHeight, clientHeight } = contentArea

                this.scrolledToEnd = (scrollHeight <= clientHeight || scrollTop + clientHeight >= scrollHeight - 1)
            }
        }

        loadInitialScrollCheck() {
            this.scrolledToEnd = false

            // the v-html has an internal load time (actually the translation part of it), so to make sure it's not a false positive we do have to wait a bit :(
            setTimeout(() => {
                this.onScroll()
            }, 500)
        }

        agree() {
            this.$emit('input', false)
            this.$emit("update:agreeToTerms", true)
        }

        close() {
            this.$emit('input', false)
        }
    }
