import { Expose } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'

export class InsurancePayment extends Entity<InsurancePayment> {
    @Expose()
    amount!: number

    @Expose()
    term!: string
}
