import { License } from '@/app/license/entities/license.entity'
import { Language } from '@/app/license/entities/language.entity'

export interface LicenseState {
    license?: License
    domain?: string
    languages?: Language[]
}

export const state: LicenseState = {
    license: undefined,
    domain: undefined,
    languages: undefined
}
