
    import { mapState } from 'vuex'
    import { Component, Inject, Vue } from 'vue-property-decorator'
    import Layout from '../../common/components/layout/layout.vue'
    import { GetAllBankguaranteesCommand } from '@/app/bankguarantee/commands/get-all-bankguarantees.command'
    import { Bankguarantee } from '@/app/bankguarantee/entities/bankguarantee.entity'

@Component({
    components: { Layout },
    metaInfo() {
        return {
            title: 'Bankgarantie'
        }
    },
    computed: {
        ...mapState('bankguarantee', ['bankguarantees'])
    }
})
    export default class BankguaranteeList extends Vue {
        bankguarantees!: Bankguarantee[]

        @Inject() getAllBankguaranteesCommand!: GetAllBankguaranteesCommand

        mounted() {
            this.getAllBankguaranteesCommand.run()
        }
    }
