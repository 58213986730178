import { Expose, Type } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'
import { Product } from './product.entity'
import { Document } from './document.entity'
import { ProductActionQuote } from '@/app/product/entities/product-action-quote.entity'
import { Invoice } from '@/app/invoice/entities/invoice.entity'
import { Payment } from '@/app/invoice/entities/payment.entity'
import { PaymentIssuer } from '@/app/invoice/entities/payment-issuer.entity'
import { DownloadableDocument } from '@/app/product/entities/downloadable-document.entity'
import { Woongarant } from '@/app/woongarant-insurance/entities/woongarant.entity'

export class ProductAction extends Entity<ProductAction> {
    @Expose()
    id!: string

    @Expose()
    label!: string

    @Expose()
    type!: string

    @Expose({ name: 'date_created' })
    dateCreated!: string

    @Expose({ name: 'date_executed' })
    dateExecuted!: string

    @Expose({ name: 'is_done' })
    isDone!: boolean

    @Expose()
    comment!: string

    @Expose({ name: 'mollie_invalid' })
    mollieInvalid!: boolean

    @Expose({ name: 'smartcare_show_amount' })
    smartcareShowAmount!: boolean

    @Expose({ name: 'smartcare_min_savings_amount' })
    smartcareMinSavingsAmount!: number

    @Expose({ name: 'smartcare_savings_period' })
    smartcareSavingPeriod!: string

    @Expose({ name: 'smartcare_explain' })
    smartcareExplain!: string

    @Expose()
    @Type(() => Product)
    product!: Product

    @Expose()
    @Type(() => Woongarant)
    woongarant!: Woongarant

    @Expose()
    @Type(() => Invoice)
    invoice!: Invoice

    @Expose()
    @Type(() => Payment)
    payment!: Payment

    @Expose()
    @Type(() => Document)
    document!: Document

    @Expose({ name: 'downloadable_document' })
    @Type(() => DownloadableDocument)
    downloadableDocument!: DownloadableDocument

    @Expose()
    @Type(() => ProductActionQuote)
    quotes!: ProductActionQuote[]

    @Expose({ name: 'consumer_name' })
    consumerName!: string

    @Expose({ name: 'public_number' })
    publicNumber!: string
}
