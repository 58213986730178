import { Expose } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'

export class LicenseModule extends Entity<LicenseModule> {
    @Expose({ 'name': 'has_ockto_enabled' })
    hasOcktoEnabled!: boolean

    @Expose({ 'name': 'has_iwize_enabled' })
    hasIwizeEnabled!: boolean

    @Expose({ 'name': 'has_generalinsurance_enabled' })
    hasGeneralInsuranceEnabled!: boolean

    @Expose({ 'name': 'has_truemeeting_enabled' })
    hasTrueMeetingEnabled!: boolean
}
