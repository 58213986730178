import { Expose, Type } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'
import { ProductActionCounter } from '@/app/product/entities/product-action-counter.entity'

export class Woongarant extends Entity<Woongarant> {
    @Expose({ name: 'woongarant_api_id' })
    woongarantApiId!: string

    @Expose({ name: 'insurance_number' })
    insuranceNumber!: string

    @Expose()
    product!: string

    @Expose()
    coverage!: string

    @Expose()
    premium!: number

    @Expose({ name: 'risico_address' })
    risicoAddress!: string

    @Expose({ name: 'proposal_code' })
    proposalCode!: string

    @Expose({ name: 'deductible_contents' })
    deductibleContents!: number

    @Expose({ name: 'deductible_property' })
    deductibleProperty!: number

    @Expose({ name: 'renovation_premium' })
    renovationPremium!: number

    @Expose({ name: 'conditions_url' })
    conditionsUrl!: string

    @Expose({ name: 'approval_url' })
    approvalUrl!: string

    @Expose({ name: 'action_counter' })
    @Type(() => ProductActionCounter)
    actionCounter!: ProductActionCounter
}
