import { GetterTree } from 'vuex'
import { RootState } from '@/app/common/types/root-state.type'
import { GeneralInsuranceState } from '@/app/general-insurance/store/state'
import { GeneralInsurance } from '@/app/general-insurance/entities/general-insurance.entity'

export const getters: GetterTree<GeneralInsuranceState, RootState> = {

    getByGeneralInsuranceId(state: GeneralInsuranceState): (id: string) => GeneralInsurance | undefined {
        return (id: string) => {
            const generalInsurances = state.generalInsurances.filter(m => m.id === id)
            return generalInsurances?.[0]
        }
    }

}
