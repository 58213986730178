import { MutationTree } from 'vuex'
import { CardifAoState } from './state'
import { CardifAo } from '@/app/cardifao/entities/cardifao'

export const mutations: MutationTree<CardifAoState> = {

    setCardifAos(state: CardifAoState, cardifAos: CardifAo[]) {
        state.cardifAos = cardifAos
    }

}
