import {Expose, Type} from 'class-transformer'
import { Entity } from '@/app/common/base/entity'
import {BasedOnEnumTypes} from '@/app/mortgage/entities/based-on-enum-types'

export class MortgageCollateral extends Entity<MortgageCollateral> {
    @Expose({ name: 'market_value' })
    marketValue!: number

    @Expose({ name: 'market_value_date' })
    marketValueDate!: string

    @Expose({ name: 'based_on' })
    basedOn!: string

    @Expose({ name: 'based_on_options' })
    @Type(() => BasedOnEnumTypes)
    basedOnValues!: BasedOnEnumTypes[]

    @Expose({ name: 'house_number' })
    houseNumber!: number

    @Expose({ name: 'street_name' })
    streetName!: string

    @Expose({ name: 'zip_code' })
    zipCode!: string

    @Expose({ name: 'city' })
    city!: string
}
