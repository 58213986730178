import { Expose, Type } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'
import { LoanPart } from '@/app/mortgage/entities/loan-part.entity'
import { MortgageTimeline } from '@/app/mortgage/entities/mortgage-timeline.entity'
import { ProductActionCounter } from '@/app/product/entities/product-action-counter.entity'
import { MortgageCollateral } from '@/app/mortgage/entities/mortgage-collateral.entity'
import { Smartcare } from '@/app/product/entities/smartcare.entity.'
import { LoanPartType } from '@/app/mortgage/entities/loan-part-type.entity'

export class Mortgage extends Entity<Mortgage> {
    @Expose()
    id!: string

    @Expose()
    label!: string

    @Expose()
    lender!: string

    @Expose()
    amount!: number

    @Expose()
    arrangement!: string

    @Expose({ name: 'has_timeline' })
    hasTimeline!: boolean

    @Expose({ name: 'loan_parts' })
    @Type(() => LoanPart)
    loanParts!: LoanPart[]

    @Expose()
    @Type(() => MortgageCollateral)
    collateral!: MortgageCollateral

    @Expose()
    @Type(() => Smartcare)
    smartcare!: Smartcare

    @Expose({ name: 'loan_part_types' })
    @Type(() => LoanPartType)
    loanPartTypes!: LoanPartType[]

    @Expose()
    @Type(() => MortgageTimeline)
    timeline!: MortgageTimeline[]

    @Expose({ name: 'action_counter' })
    @Type(() => ProductActionCounter)
    actionCounter!: ProductActionCounter
}
