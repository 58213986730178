import '@fortawesome/fontawesome-pro/css/fontawesome.css'
import '@fortawesome/fontawesome-pro/css/light.css'
import '@fortawesome/fontawesome-pro/css/solid.css'
import Vue from 'vue'
import Vuetify from 'vuetify'
import i18n from './i18n'
import { nl, en } from 'vuetify/lib/locale'

Vuetify.config.silent = true

Vue.use(Vuetify)

export default new Vuetify({
    icons: {
        iconfont: 'fa'
    },
    lang: {
        current: i18n.locale,
        locales: { nl, en }
    },
    theme: {
        themes: {
            light: {
                primary: '#0A2E65',
                secondary: '#39549E',
                accent: '#ff5d00'
            }
        },
        options: {
            customProperties: true
        }
    }
})
