
    import { Component, Inject, Vue, Watch } from 'vue-property-decorator'
    import { mapGetters, mapState } from "vuex"
    import { Mortgage as MortgageEntity } from "@/app/mortgage/entities/mortgage.entity"
    import Layout from "@/app/common/components/layout/layout.vue"
    import { GetAllMortgagesCommand } from "@/app/mortgage/commands/get-all-mortgages.command"
    import { NotificationDispatcher } from "@/app/common/services/notification-dispatcher.service"
    import { SubmitMortgageCheckCommand } from "@/app/mortgage-check/commands/submit-mortgage-check"
    import validation from "@/app/common/helpers/validation"
    import DatePicker from "@/app/common/components/date-picker.vue"
    import { License } from "@/app/license/entities/license.entity"
    import hasLocalizedContent from "@/app/common/helpers/has-localized-content"
    import localizeContent from "../../common/helpers/localize-content"
    import { GetCurrentLicenseCommand } from "@/app/license/commands/get-current-license-command"
    import { VForm } from "@/app/common/types/v-form.type"
    import { Consumer } from "@/app/identity"
    import { LoanPartFormItem } from "@/app/mortgage/support/loan-part-form-item"
    import MortgageCollateral from "@/app/mortgage/components/mortgage-collateral.vue"
    import MortgageLoanParts from "@/app/mortgage/components/mortgage-loan-parts.vue"
    import { MortgageCollateral as CollateralData } from "@/app/mortgage/entities/mortgage-collateral.entity"
    import formalText from "../../common/helpers/formal-text"

    @Component({
        methods: { formalText },
        components: { MortgageCollateral, DatePicker, MortgageLoanParts, Layout },
        metaInfo() {
            return {
                title: 'Mortgage Check'
            }
        },
        computed: {
            ...mapGetters('mortgage', ['getByMortgageId']),
            ...mapState('mortgage', ['mortgages']),
            ...mapState('license', ['license']),
            ...mapState('auth', ['consumer']),
        }
    })
    export default class MortgageCheck extends Vue {
        rules = validation
        license!: License
        mortgages!: MortgageEntity[]
        mortgage: MortgageEntity | null = null
        consumer!: Consumer
        getByMortgageId!: (id: string) => MortgageEntity | undefined
        hasLocalizedContent = hasLocalizedContent
        localizeContent = localizeContent

        currentStep = 1
        formal = true
        submitProcessing = false
        score = 0
        result = ''

        mortgageIsRunning: null|string = null
        mortgageEndDate: null|string = null
        loanParts: LoanPartFormItem[] = []
        currentFamilySituation: null|string = null
        expectedChanges: null|string = null
        financialChanges: null|string = null
        homeSuitsFamilySituation: null|string = null
        futurePlans: string[] = []
        futurePlansUpcoming10Years: string[] = []
        whenMoving: null|string = null
        homeDevelopedSinceMortgage: null|string = null
        collateral: null|CollateralData = null
        reasonMortgageChange: string[] = []
        expectMortgageCostReduction: null|string = null
        isEverythingClear: null|string = null
        otherQuestions: null|string = null
        callMe: null|string = null
        phone: null|string = null

        @Inject() getAllMortgagesCommand!: GetAllMortgagesCommand
        @Inject() notificationDispatcher!: NotificationDispatcher
        @Inject() submitMortgageCheckCommand!: SubmitMortgageCheckCommand
        @Inject() getCurrentLicenseCommand!: GetCurrentLicenseCommand

        async mounted() {
            await this.getAllMortgages()
            await this.getLicenseData()

            this.formal = this.consumer.addressStyle === 'formal'
            this.phone = this.getConsumerPhone()

            const id = this.$route.params.id
            this.mortgage = this.getByMortgageId(id) ?? null
        }

        async getAllMortgages() {
            if (!this.mortgages.length) {
                await this.getAllMortgagesCommand.run()
            }
        }

        async getLicenseData() {
            await this.getCurrentLicenseCommand.run()
        }

        calculateScore(): number {
            const scoreMapping = new Map<string, number>()

            // Question 3
            scoreMapping.set('living_together', 40)
            scoreMapping.set('marry', 30)
            scoreMapping.set('divorce', 40)
            scoreMapping.set('partnership', 30)
            scoreMapping.set('child', 40)
            scoreMapping.set('other', 40)

            // Question 4
            scoreMapping.set('incapacitated', 5)
            scoreMapping.set('retired', 15)
            scoreMapping.set('unemployed', 5)
            scoreMapping.set('better_paid', 15)
            scoreMapping.set('parttime', 5)

            // Question 5
            scoreMapping.set('too_small', 40)
            scoreMapping.set('other_reason', 40)
            scoreMapping.set('other_location', 40)
            scoreMapping.set('renovations', 40)
            scoreMapping.set('spare_space', 20)

            // Question 6 (consumer age 56 and lower)
            scoreMapping.set('future_plans_renovate', 40)
            scoreMapping.set('future_plans_energy_efficient', 40)
            scoreMapping.set('future_plans_moving', 60)

            // Question 6 (consumer age 57 and higher)
            scoreMapping.set('future_plans_upcoming_10_years_renovate', 40)
            scoreMapping.set('future_plans_upcoming_10_years_energy_efficient', 40)
            scoreMapping.set('future_plans_upcoming_10_years_second_house', 30)
            scoreMapping.set('future_plans_upcoming_10_years_stop_working_early', 30)
            scoreMapping.set('future_plans_upcoming_10_years_help_children', 20)
            scoreMapping.set('future_plans_upcoming_10_years_moving', 60)

            // Question 6c
            scoreMapping.set('when_moving', 60)

            // Question 7
            scoreMapping.set('increased', 30)

            // Question 8
            scoreMapping.set('reason_mortgage_change_cheaper', 30)
            scoreMapping.set('reason_mortgage_change_fixed_interest_ending', 60)
            scoreMapping.set('reason_mortgage_change_home_improvements', 60)
            scoreMapping.set('reason_mortgage_change_renovation_plans', 60)
            scoreMapping.set('reason_mortgage_change_energy_saving_plans', 60)

            // Question 9
            scoreMapping.set('expect_mortgage_cost_reduction', 30)

            // Question 10
            scoreMapping.set('financial_product_questions', 50)
            scoreMapping.set('mortgage_questions', 50)
            scoreMapping.set('doubts', 50)

            let score = 0

            score += scoreMapping.get(this.expectedChanges ?? '') ?? 0
            score += scoreMapping.get(this.financialChanges ?? '') ?? 0
            score += scoreMapping.get(this.homeSuitsFamilySituation ?? '') ?? 0
            this.futurePlans.forEach((s: string) => {score += scoreMapping.get(s) ?? 0})
            this.futurePlansUpcoming10Years.forEach((s: string) => {score += scoreMapping.get(s) ?? 0})
            if(this.whenMoving !== null) score += scoreMapping.get('when_moving') ?? 0
            score += scoreMapping.get(this.homeDevelopedSinceMortgage ?? '') ?? 0
            this.reasonMortgageChange.forEach((s: string) => {score += scoreMapping.get(s) ?? 0})
            if(this.expectMortgageCostReduction === '1') score += scoreMapping.get('expect_mortgage_cost_reduction') ?? 0
            score += scoreMapping.get(this.isEverythingClear ?? '') ?? 0

            return score
        }

        determineResult(score: number): string {
            if (score >= 75) {
                return hasLocalizedContent(this.license.content.mortgageCheckOutcomeAdviceNecessary)
                    ? localizeContent(this.license.content.mortgageCheckOutcomeAdviceNecessary)
                    : 'Wij adviseren om een afspraak met de hypotheekadviseur te maken.'
            }

            if (score >= 45) {
                return hasLocalizedContent(this.license.content.mortgageCheckOutcomeAdviceDesirable)
                    ? localizeContent(this.license.content.mortgageCheckOutcomeAdviceDesirable)
                    : 'Indien gewenst kunnen de gegeven antwoorden besproken worden met de hypotheekadviseur.'
            }

            return hasLocalizedContent(this.license.content.mortgageCheckOutcomeAdviceNotNeeded)
                ? localizeContent(this.license.content.mortgageCheckOutcomeAdviceNotNeeded)
                : 'Wij zien geen reden om een afspraak met de adviseur te maken, maar het mag altijd!'
        }

        formatFormData(): Record<string, any> {
            const data: Record<string, any> = {
                'mortgage_running': this.mortgageIsRunning,
                'call_me': this.callMe,
            }

            if (this.mortgageIsRunning === '1') {
                data['current_family_situation'] = this.currentFamilySituation
                data['expected_changes'] = this.expectedChanges
                data['financial_changes'] = this.financialChanges
                data['home_suits_family_situation'] = this.homeSuitsFamilySituation
                data['home_developed_since_mortgage'] = this.homeDevelopedSinceMortgage
                data['expect_mortgage_cost_reduction'] = this.expectMortgageCostReduction
                data['is_everything_clear'] = this.isEverythingClear
                data['other_questions'] = this.otherQuestions

                this.futurePlans.forEach((s: string) => { data[s] = '1' })
                this.futurePlansUpcoming10Years.forEach((s: string) => { data[s] = '1' })
                this.reasonMortgageChange.forEach((s: string) => { data[s] = '1' })

                if (this.isPlanningToMove()) {
                    data['when_moving'] = this.whenMoving
                }

                if (this.isHomeDevelopedSinceMortgage()) {
                    data['market_value_amount_increased'] = this.collateral?.marketValue
                    data['market_value_date_increased'] = this.collateral?.marketValueDate
                    data['based_on_increased'] = this.collateral?.basedOn
                }

            } else {
                data['mortgage_end_date'] = this.mortgageEndDate
            }

            if (this.callMe === '1' && this.phone !== this.getConsumerPhone()) {
                data['phone'] = this.phone
            }

            return data
        }

        formatLoanPartData(loanPartData: LoanPartFormItem[]): [Record<string, any>, Record<string, any>] {
            const parts: Record<string, any>[] = []
            const newParts: Record<string, any>[] = []

            loanPartData.forEach((l: LoanPartFormItem) => {
                const formatEntry: Record<string, any> = {
                    'id': l.id,
                    'loan_type': l.type,
                    'amount': l.amount,
                    'current_amount': l.currentAmount,
                    'interest': l.interest,
                    'date': l.date,
                    'start_of_fixed_rate_period': l.startOfFixedRatePeriod,
                    'end_of_fixed_rate_period': l.endOfFixedRatePeriod,
                }

                if (l.isNew) {
                    newParts.push(formatEntry)
                } else {
                    parts.push(formatEntry)
                }
            })

            return [parts, newParts]
        }

        goToStep(step: number, form?: VForm) {
            if (form !== undefined && !form.validate()) {
                this.notificationDispatcher.error('Vul alle verplichte velden in')
                return
            }

            this.currentStep = Math.min(Math.max(step, 1), 6)
        }

        previous(form?: VForm) {
            this.goToStep( this.currentStep - 1, form)
        }

        next(form?: VForm) {
            this.goToStep(this.currentStep + 1, form)
        }

        get formClientDetail(): VForm {
            return this.$refs.formClientDetail as VForm
        }

        get formFamilyIncome(): VForm {
            return this.$refs.formFamilyIncome as VForm
        }

        get formResidence(): VForm {
            return this.$refs.formResidence as VForm
        }

        get formMortgage(): VForm {
            return this.$refs.formMortgage as VForm
        }

        get formOther(): VForm {
            return this.$refs.formOther as VForm
        }

        get formAdvice(): VForm {
            return this.$refs.formAdvice as VForm
        }

        getConsumerPhone(): string {
            return this.consumer.phoneMobile ?? this.consumer.phoneHome
        }

        isConsumerInAgeRange(): boolean {
            const birthday = new Date(this.consumer.dateOfBirth)
            const now = new Date()
            return now.getFullYear() - birthday.getFullYear() <= 56
        }

        isPlanningToMove(): boolean {
            return this.futurePlans.includes('future_plans_moving') || this.futurePlansUpcoming10Years.includes('future_plans_upcoming_10_years_moving')
        }

        isHomeDevelopedSinceMortgage(): boolean {
            return this.license.smartcare.question7BCEnabled && ['increased', 'decreased'].some(value => value === this.homeDevelopedSinceMortgage)
        }

        @Watch('currentStep')
        onStepChange() {
            if (this.currentStep === 6) {
                this.score = this.calculateScore()
                this.result = this.determineResult(this.score)
            }
        }

        async onSubmitForm() {
            try {
                this.submitProcessing = true

                if (!this.formAdvice.validate()) {
                    await this.notificationDispatcher.error('Vul alle verplichte velden in')
                    return
                }

                const formData = this.formatFormData()

                if (this.mortgageIsRunning === '1') {
                    formData['calculated_score'] = this.score

                    if (this.license.smartcare.question1BEnabled) {
                        const [parts, newParts] = this.formatLoanPartData(this.loanParts)
                        formData['parts'] = Object.keys(parts).length > 0 ? parts : undefined
                        formData['new_parts'] = Object.keys(newParts).length > 0 ? newParts : undefined
                    }
                } else {
                    formData['calculated_score'] = 0
                }

                const productActionId = this.$route.params.action
                await this.submitMortgageCheckCommand.run(productActionId, formData)

                await this.$router.push({ name: 'dashboard' })
                await this.notificationDispatcher.success('Formulier is verstuurd')
            } catch (e) {
                await this.notificationDispatcher.error(e.message)
            } finally {
                this.submitProcessing = false
            }
        }
    }
