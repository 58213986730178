import { Expose, Transform } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'

export class AuthData extends Entity<AuthData> {
    @Expose()
    accessToken!: string

    @Expose()
    refreshToken!: string

    @Expose()
    @Transform(value => value || false)
    twoFactor!: boolean

    @Expose()
    @Transform(value => value || false)
    twoFactorSetup!: boolean

    @Expose()
    consumerId!: string
}
