import { Expose } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'

export class Language extends Entity<Language> {
    @Expose()
    name!: string

    @Expose({ name: 'iso_code' })
    isoCode!: string

    @Expose({ name: 'is_default' })
    isDefault!: boolean
}
