class Env {
    environment: string = process.env.NODE_ENV
    version: string = process.env.VUE_APP_VERSION
    apiUrl: string = process.env.VUE_APP_API_URL
    sentry: { enabled: boolean, dsn?: string } = {
        enabled: this.parse(process.env.VUE_APP_SENTRY_ENABLED, false),
        dsn: process.env.VUE_APP_SENTRY_DSN
    }

    get isDevelopment(): boolean {
        return this.environment === 'development'
    }

    get isProduction(): boolean {
        return this.environment === 'production'
    }

    private parse(value: any, fallback?: any): any {
        if (typeof value === 'undefined') {
            return fallback
        }

        return JSON.parse(value)
    }
}

export default new Env()
