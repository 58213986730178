import { Expose, Type } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'
import localizeContent from '@/app/common/helpers/localize-content'

export class Document extends Entity<Document> {
    @Expose()
    label!: string

    @Expose()
    description!: string

    @Expose({ name: 'consumer_name' })
    consumerName!: string

    @Expose({ name: 'is_consumer_specific' })
    isConsumerSpecific!: string

    @Expose({ name: 'client_approval_explanation' })
    clientApprovalExplanation!: string

    @Expose({ name: 'upload_requirements' })
    uploadRequirements!: string

    get hasUploadedRequirements(): boolean {
        return this.uploadRequirements !== undefined &&
            this.uploadRequirements !== null &&
            this.uploadRequirements !== '' &&
            this.uploadRequirements.length > 0 &&
            localizeContent(this.uploadRequirements) !== ''
    }

    get uploadRequirementsAsList(): string[] {
        if (!this.hasUploadedRequirements) {
            return []
        }

        return localizeContent(this.uploadRequirements).split('\n')
    }

    get hasClientApprovalExplanation(): boolean {
        return this.clientApprovalExplanation !== undefined &&
            this.clientApprovalExplanation !== null &&
            this.clientApprovalExplanation !== '' &&
            localizeContent(this.clientApprovalExplanation) !== ''
    }
}
