import { Command } from '@/app/common/base/command'
import { ProductAction } from '@/app/product/entities/product-action.entity'

export class GetAllActionsCommand extends Command {
    public async run() {
        const response = await this.client.get('/api/actions')
        const productActions = response.asEntities(ProductAction)

        await this.store.dispatch('product/setProductActions', productActions)
    }
}
