import { ActionTree, Commit } from 'vuex'
import { RootState } from '@/app/common/types/root-state.type'
import { SiteNotificationState } from '@/app/notifications/store/state'
import { Notification } from '@/app/notifications/entities/notification.entity'

export const actions: ActionTree<SiteNotificationState, RootState> = {

    setNotifications({ commit }: { commit: Commit }, notifications: Notification[]) {
        commit('setNotifications', notifications)
    }

}
