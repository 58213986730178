
    import { mapState, mapGetters } from 'vuex'
    import { Component, Vue, Inject } from 'vue-property-decorator'
    import Layout from '../../common/components/layout/layout.vue'
    import { ProductAction } from '@/app/product/entities/product-action.entity'
    import { GetAllActionsCommand } from '@/app/product/commands/get-all-actions.command'
    import { License } from '@/app/license/entities/license.entity'
    import { GetCardifAoPremiumCommand } from '@/app/cardifao/commands/get-cardifao-premium.command'
    import { NotificationDispatcher } from '@/app/common/services/notification-dispatcher.service'
    import { CardifAoPremium as CardifAoPremiumEntity } from '@/app/cardifao/entities/cardifao-premium'
    import { AcceptCardifPremiumCommand } from '@/app/cardifao/commands/accept-cardif-premium.command'
    import { DeclineCardifPremiumCommand } from '@/app/cardifao/commands/decline-cardif-premium.command'
    import SimpleTable from '@/app/common/components/simple-table.vue'
    import localizeContent from '@/app/common/helpers/localize-content'

    @Component({
        components: { Layout, SimpleTable },
        metaInfo() {
            return {
                title: 'Cardif AO Premium Calculation'
            }
        },
        computed: {
            ...mapState('product', ['productActions']),
            ...mapState('license', ['license']),
            ...mapGetters('product', ['getByProductActionId'])
        }
    })
    export default class CardifAoPremium extends Vue {
        license!: License
        productActions!: ProductAction[]
        getByProductActionId!: (id: string) => ProductAction | undefined
        productAction: ProductAction | null = null
        premium: CardifAoPremiumEntity | null = null
        acceptLoading = false
        declineLoading = false
        localizeContent = localizeContent

        @Inject() getAllActionsCommand!: GetAllActionsCommand
        @Inject() getCardifAoPremiumCommand!: GetCardifAoPremiumCommand
        @Inject() acceptCardifPremiumCommand!: AcceptCardifPremiumCommand
        @Inject() declineCardifPremiumCommand!: DeclineCardifPremiumCommand
        @Inject() notificationDispatcher!: NotificationDispatcher

        async mounted() {
            await this.getAllProductActions()

            const actionId = this.$route.params.action

            this.productAction = this.getByProductActionId(actionId) ?? null
            await this.getPremium()
        }

        async getAllProductActions() {
            if (!this.productActions.length) {
                await this.getAllActionsCommand.run()
            }
        }

        async getPremium() {
            try {
                this.premium = await this.getCardifAoPremiumCommand.run(this.productAction!.id)
            } catch (err) {
                await this.notificationDispatcher.error(err.message)
            }
        }

        async acceptPremium() {
            try {
                this.acceptLoading = true
                await this.acceptCardifPremiumCommand.run(this.productAction!.id)
                await this.notificationDispatcher.success(this.$t("messages.cardif.accept_premium").toString())
                await this.$router.push({ name: 'dashboard' })
            } catch (err) {
                await this.notificationDispatcher.error(err.message)
            } finally {
                this.acceptLoading = false
            }
        }

        async declinePremium() {
            try {
                this.declineLoading = true
                await this.declineCardifPremiumCommand.run(this.productAction!.id)
                await this.$router.push({ name: 'dashboard' })
            } catch (err) {
                await this.notificationDispatcher.error(this.$t("messages.cardif.decline_premium").toString())
            } finally {
                this.declineLoading = false
            }
        }
    }
