import i18n from '@/plugins/i18n'

export default class ErrorTranslator {
    private readonly errors: { [key: string]: string } = {
        AUTH_LOGIN_FAILED: i18n.t('error.auth_login_failed').toString(),
        AUTH_REFRESH_INVALID_TOKEN: i18n.t('error.auth_refresh_invalid_token').toString(),
        AUTH_REQUEST_NEW_PASSWORD_FAILED: i18n.t('error.auth_request_new_password_failed').toString(),
        AUTH_START_PASSWORD_RESET_FAILED: i18n.t('error.auth_start_password_reset_failed').toString(),
        BANK_GUARANTEE_PROPOSAL_FAILED: i18n.t('error.bank_guarantee_proposal_failed').toString(),
        UPLOAD_DOCUMENTS_NOT_PROVIDED: i18n.t('error.upload_documents_not_provided').toString(),
        KNOWLEDGE_FORM_NO_ANSWERS_PROVIDED: i18n.t('error.knowledge_form_no_answers_provided').toString(),
        CONSUMER_NOT_FOUND: i18n.t('error.consumer_not_found').toString(),
        PRODUCT_NOT_FOUND: i18n.t('error.product_not_found').toString(),
        PRODUCT_ACTION_NOT_FOUND: i18n.t('error.product_action_not_found').toString(),
        PRODUCT_OWNERSHIP_MISMATCH: i18n.t('error.product_ownership_mismatch').toString(),
        BRAND_COULD_NOT_BE_DETERMINED: i18n.t('error.brand_could_not_be_determined').toString(),
        IMPORTANT_DOCUMENT_NOT_FOUND: i18n.t('error.important_document_not_found').toString(),
        FILE_NOT_FOUND: i18n.t('error.file_not_found').toString(),
        DOSSIER_NOT_FOUND: i18n.t('error.dossier_not_found').toString(),
        MORTGAGE_NOT_FOUND: i18n.t('error.mortgage_not_found').toString(),
        OTD_NOT_FOUND: i18n.t('error.otd_not_found').toString(),
        ACCESS_DENIED: i18n.t('error.access_denied').toString(),
        APPOINTMENT_NOT_FOUND: i18n.t('error.appointment_not_found').toString(),
        APPOINTMENT_ALREADY_CANCELED: i18n.t('error.appointment_already_canceled').toString(),
        APPOINTMENT_CANCELLATION_FAILED: i18n.t('error.appointment_cancellation_failed').toString(),
        KNOWLEDGE_FORM_NO_PDF: i18n.t('error.knowledge_form_no_pdf').toString(),
        KNOWLEDGE_FORM_FAILED_CREATING_PDF: i18n.t('error.knowledge_form_failed_creating_pdf').toString(),
        GENERAL_INSURANCE_NOT_FOUND: i18n.t('error.general_insurance_not_found').toString(),
        DAMAGE_REPORT_FAILED_VALIDATION: i18n.t('error.damage_report_failed_validation').toString(),
        DAMAGE_REPORT_PHOTO_FAILED_VALIDATION: i18n.t('error.damage_report_photo_failed_validation').toString(),
        INVENTORY_FORM_OCTKO_FAILED: i18n.t('error.inventory_form_octko_failed').toString(),
        INVENTORY_FORM_OCTKO_DECODE_FAILED: i18n.t('error.inventory_form_octko_decode_failed').toString(),
        AUTH_REFRESH_MISSING_DATA: i18n.t('error.auth_refresh_missing_data').toString(),
        AUTH_REFRESH_MISSING_TOKEN: i18n.t('error.auth_refresh_missing_token').toString(),
        AUTH_REFRESH_EXPIRED_TOKEN: i18n.t('error.auth_refresh_expiredT_token').toString(),
        NO_PASSWORD_PROVIDED: i18n.t('error.no_password_provided').toString(),
        CONSUMER_PASSWORD_IS_COMMON: i18n.t('error.consumer_password_is_common').toString(),
        UNABLE_TO_MARK_TOKEN_AS_USED_EXCEPTION: i18n.t('error.unable_to_mark_token_as_used_exception').toString(),
        INVALID_OTP: i18n.t('error.invalid_otp').toString(),
        CARDIF_MORTGAGE_MISSING: i18n.t('error.cardif_mortgage_missing').toString(),
        CARDIF_CONSUMER_MISSING: i18n.t('error.cardif_consumer_missing').toString(),
        CARDIF_API_ERROR: i18n.t('error.cardif_api_error').toString(),
        CARDIF_ERROR_CREATING_LX: i18n.t('error.cardif_error_creating_lx').toString(),
        CARDIF_ERROR_STORING_LX: i18n.t('error.cardif_error_storing_lx').toString(),
        CONSUMER_ID_NOT_PROVIDED: i18n.t('error.consumer_id_not_provided').toString(),
        PRODUCTACTION_ID_NOT_PROVIDED: i18n.t('error.productaction_id_not_provided').toString(),
        ATTACHMENT_ID_NOT_PROVIDED: i18n.t('error.attachment_id_not_provided').toString(),
        UKNOWN_DOCUMENT_BUNDLE: i18n.t('error.uknown_document_bundle').toString(),
        IMAGE_TYPE_NOT_SUPPORTED: i18n.t('error.image_type_not_supported').toString(),
        UPLOAD_PDF_ENCRYPTION_ERROR: i18n.t('error.upload_pdf_encryption_error').toString(),
        COMBINED_PDF_TO_LARGE: i18n.t('error.combined_pdf_to_large').toString(),
        INVALID_DATE: i18n.t('error.invalid_date').toString(),
        INVALID_ACTION_TYPE: i18n.t('error.invalid_action_type').toString(),
        SEPA_INVALID_IBAN: i18n.t('error.sepa_invalid_iban').toString(),
        SEPA_CONSUMER_DATA_MISSING: i18n.t('error.sepa_consumer_data_missing').toString(),
        UNKNOWN_QUOTE_ATTACHMENT: i18n.t('error.unknown_quote_attachment').toString(),
        DOWNLOADABLE_DOCUMENT_NOT_FOUND: i18n.t('error.downloadable_document_not_found').toString(),
        NODE_MISSING: i18n.t('error.node_missing').toString(),
        OUT_OF_INDEX: i18n.t('error.out_of_index').toString(),
        DOSSIER_EXTERNAL_ID_EXISTS: i18n.t('error.dossier_external_id_exists').toString(),
        PERSON_EXTERNAL_ID_EXISTS: i18n.t('error.person_external_id_exists').toString(),
        INCOME_TYPE_NOT_FOUND: i18n.t('error.income_type_not_found').toString(),
        CONSUMER_PROFILE_NOT_FOUND: i18n.t('error.consumer_profile_not_found').toString(),
        PERSON_GENDER_INVALID: i18n.t('error.person_gender_invalid').toString(),
        PERSON_DOB_INVALID: i18n.t('error.person_dob_invalid').toString(),
        PERSON_COUNTRY_INVALID: i18n.t('error.person_country_invalid').toString(),
        PROJECT_NOT_FOUND: i18n.t('error.project_not_found').toString(),
        LEADSOURCE_NOT_FOUND: i18n.t('error.leadsource_not_found').toString(),
        CAMPAIGN_NOT_FOUND: i18n.t('error.campaign_not_found').toString(),
        ADVISOR_NOT_FOUND: i18n.t('error.advisor_not_found').toString(),
        DOSSIER_OWNER_NOT_FOUND: i18n.t('error.dossier_owner_not_found').toString(),
        PRODUCT_PHASE_NOT_FOUND: i18n.t('error.product_phase_not_found').toString(),
        LOCATION_NOT_FOUND: i18n.t('error.location_not_found').toString(),
        BRAND_NOT_FOUND: i18n.t('error.brand_not_found').toString(),
        LANGUAGE_NOT_FOUND: i18n.t('error.language_not_found').toString(),
        UNDER_OFFER_OPTION_NOT_FOUND: i18n.t('error.under_offer_option_not_found').toString(),
        MYSQL_ERROR: i18n.t('error.mysql_error').toString(),
        INVOICE_NOT_FOUND: i18n.t('error.invoice_not_found').toString(),
        COULD_NOT_GENERATE_INVOICE_PDF: i18n.t('error.couldnt_generate_invoice_pdf').toString(),
        INVOICE_CANT_BE_DOWNLOADED: i18n.t('error.invoice_cant_be_downloaded').toString(),
        UNABLE_TO_RESET_PASSWORD: i18n.t('error.unable_to_reset_password').toString(),
        PASSWORD_NOT_CONFORM_SECURITY_GUIDELINES: i18n.t('error.password_not_conform_security_guidelines').toString(),
    }
    private readonly genericError = i18n.t('error.mysql_error').toString()

    public constructor(private readonly code: string|undefined|null) {}

    public translate(): string {
        if (this.code === undefined || this.code === null) {
            return this.genericError
        }

        if (!Object.keys(this.errors).includes(this.code)) {
            return this.genericError
        }

        return this.errors[this.code]
    }
}
