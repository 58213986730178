import ApiErrorNL from './nl/api-error.json'
import ApiErrorEN from './en/api-error.json'
import AppointmentNL from './nl/appointment.json'
import AppointmentEN from './en/appointment.json'
import AuthNL from './nl/auth.json'
import AuthEN from './en/auth.json'
import BankGuaranteeNL from './nl/bank-guarantee.json'
import BankGuaranteeEN from './en/bank-guarantee.json'
import CardifNL from './nl/cardif.json'
import CardifEN from './en/cardif.json'
import CommonNL from './nl/common.json'
import CommonEN from './en/common.json'
import CreditNL from './nl/credit.json'
import CreditEN from './en/credit.json'
import DashboardNL from './nl/dashboard.json'
import DashboardEN from './en/dashboard.json'
import DomainChooserNL from './nl/domain-chooser.json'
import DomainChooserEN from './en/domain-chooser.json'
import DossierNL from './nl/dossier.json'
import DossierEN from './en/dossier.json'
import ErrorPageNL from './nl/error-page.json'
import ErrorPageEN from './en/error-page.json'
import FgvNL from './nl/fgv.json'
import FgvEN from './en/fgv.json'
import GeneralInsuranceNL from './nl/general-insurance.json'
import GeneralInsuranceEN from './en/general-insurance.json'
import ImportantDocumentsNL from './nl/important-documents.json'
import ImportantDocumentsEN from './en/important-documents.json'
import InventoryFormNL from './nl/inventory-form.json'
import InventoryFormEN from './en/inventory-form.json'
import KnowledgeFormNL from './nl/knowledge-form.json'
import KnowledgeFormEN from './en/knowledge-form.json'
import LifeInsuranceNL from './nl/life-insurance.json'
import LifeInsuranceEN from './en/life-insurance.json'
import MaintenanceNL from './nl/maintenance.json'
import MaintenanceEN from './en/maintenance.json'
import MortgageNL from './nl/mortgage.json'
import MortgageEN from './en/mortgage.json'
import OtdNL from './nl/otd.json'
import OtdEN from './en/otd.json'
import ProductNL from './nl/product.json'
import ProductEN from './en/product.json'
import PurchaseIntentionNL from './nl/purchase-intention.json'
import PurchaseIntentionEN from './en/purchase-intention.json'
import SettingsNL from './nl/settings.json'
import SettingsEN from './en/settings.json'
import InvoiceNL from './nl/invoice.json'
import InvoiceEN from './en/invoice.json'
import BusinessIncomeEN from './en/business-income.json'
import BusinessIncomeNL from './nl/business-income.json'
import WillWritingAdviceEN from './en/will-writing-advice.json'
import WillWritingAdviceNL from './nl/will-writing-advice.json'
import AdviceEN from './en/advice.json'
import AdviceNL from './nl/advice.json'

export default {
    nl: {
        apiError: { ...ApiErrorNL },
        appointment: { ...AppointmentNL },
        auth: { ...AuthNL },
        bankGuarantee: { ...BankGuaranteeNL },
        cardif: { ...CardifNL },
        common: { ...CommonNL },
        credit: { ...CreditNL },
        dashboard: { ...DashboardNL },
        domainChooser: { ...DomainChooserNL },
        dossier: { ...DossierNL },
        errorPage: { ...ErrorPageNL },
        fgv: { ...FgvNL },
        generalInsurance: { ...GeneralInsuranceNL },
        importantDocuments: { ...ImportantDocumentsNL },
        inventoryForm: { ...InventoryFormNL },
        knowledgeForm: { ...KnowledgeFormNL },
        lifeInsurance: { ...LifeInsuranceNL },
        maintenance: { ...MaintenanceNL },
        mortgage: { ...MortgageNL },
        otd: { ...OtdNL },
        product: { ...ProductNL },
        purchaseIntention: { ...PurchaseIntentionNL },
        settings: { ...SettingsNL },
        invoice: { ...InvoiceNL },
        businessIncome: { ...BusinessIncomeNL },
        willWritingAdvice: { ...WillWritingAdviceNL },
        advice: { ...AdviceNL }
    },
    en: {
        apiError: { ...ApiErrorEN },
        appointment: { ...AppointmentEN },
        auth: { ...AuthEN },
        bankGuarantee: { ...BankGuaranteeEN },
        cardif: { ...CardifEN },
        common: { ...CommonEN },
        credit: { ...CreditEN },
        dashboard: { ...DashboardEN },
        domainChooser: { ...DomainChooserEN },
        dossier: { ...DossierEN },
        errorPage: { ...ErrorPageEN },
        fgv: { ...FgvEN },
        generalInsurance: { ...GeneralInsuranceEN },
        importantDocuments: { ...ImportantDocumentsEN },
        inventoryForm: { ...InventoryFormEN },
        knowledgeForm: { ...KnowledgeFormEN },
        lifeInsurance: { ...LifeInsuranceEN },
        maintenance: { ...MaintenanceEN },
        mortgage: { ...MortgageEN },
        otd: { ...OtdEN },
        product: { ...ProductEN },
        purchaseIntention: { ...PurchaseIntentionEN },
        settings: { ...SettingsEN },
        invoice: { ...InvoiceEN },
        businessIncome: { ...BusinessIncomeEN },
        willWritingAdvice: { ...WillWritingAdviceEN },
        advice: { ...AdviceEN }
    }
}
