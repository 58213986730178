import { Expose, Type } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'
import { ProductActionCounter } from '@/app/product/entities/product-action-counter.entity'
import { InsurancePayment } from '@/app/life-insurance/entities/insurance-payment.entity'
import { InsuredConsumer } from '@/app/life-insurance/entities/insured-consumer.entity'

export class LifeInsurance extends Entity<LifeInsurance> {
    @Expose()
    id!: string

    @Expose()
    label!: string

    @Expose({ name: 'insurer_name' })
    insurerName!: string

    @Expose({ name: 'policy_number' })
    policyNumber!: string

    @Expose({ name: 'action_counter' })
    @Type(() => ProductActionCounter)
    actionCounter!: ProductActionCounter

    @Expose()
    @Type(() => InsurancePayment)
    payments!: InsurancePayment[]

    @Expose({ name: 'insured_consumers' })
    @Type(() => InsuredConsumer)
    insuredConsumers!: InsuredConsumer[]
}
