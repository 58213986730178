import { Expose, Type } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'
import { ProductActionCounter } from '@/app/product/entities/product-action-counter.entity'

export class Fgv extends Entity<Fgv> {
    @Expose()
    id!: string

    @Expose()
    label!: string

    @Expose({ name: 'action_counter' })
    @Type(() => ProductActionCounter)
    actionCounter!: ProductActionCounter
}
