import { MutationTree } from 'vuex'
import { BusinessIncomeState } from '@/app/business-income/store/state'
import { BusinessIncome } from '@/app/business-income/entities/business-income.entity'

export const mutations: MutationTree<BusinessIncomeState> = {

    setBusinessIncomes(state: BusinessIncomeState, businessIncomes: BusinessIncome[]) {
        state.businessIncomes = businessIncomes
    }

}
