

    import { Vue, Component, Inject } from "vue-property-decorator"
    import { mapState } from "vuex"
    import TwoFactor from "@/app/auth/components/twofactor.vue"
    import TopMenu from "@/app/common/components/layout/top-menu.vue"
    import MaintenanceNotice from "@/app/maintenance/components/maintenance-notice.vue"
    import { Maintenance } from "@/app/maintenance/entities/maintenance.entity"
    import { NotificationDispatcher } from "@/app/common/services/notification-dispatcher.service"
    import { GetCurrentLicenseCommand } from "@/app/license/commands/get-current-license-command"
    import validation from "@/app/common/helpers/validation"
    import { VForm } from "@/app/common/types/v-form.type"
    import DatePicker from "@/app/common/components/date-picker.vue"
    import { ProductActionLoginCommand } from "@/app/auth/commands/product-action-login.command"


    @Component({
        components: { DatePicker, MaintenanceNotice, TopMenu, TwoFactor },
        computed: {
            ...mapState('maintenance', ['maintenance']),
        }
    })
    export default class ProductActionLogin extends Vue {
        email = ''
        birthday = ''
        loading = false
        domain!: string
        maintenance!: Maintenance

        @Inject() productActionLoginCommand!: ProductActionLoginCommand
        @Inject() notificationDispatcher!: NotificationDispatcher
        @Inject() getCurrentLicenseCommand!: GetCurrentLicenseCommand

        rules = validation
        get form(): VForm {
            return this.$refs.form as VForm
        }
        validate(): boolean {
            return this.form.validate()
        }
        async login() {
            try {
                this.loading = true
                const productActionAuthEntity = await this.productActionLoginCommand.run(this.email, this.birthday, this.$route.params.action)

                if (productActionAuthEntity.code !== null) {
                    const slug = productActionAuthEntity.code === 'CONSUMER_LOCKED' ? 'page.mortgage_check.lockedforamountoftime' : 'page.mortgage_check.error.auth_login_failed'
                    await this.notificationDispatcher.error(this.$t(slug, { 'time': productActionAuthEntity.lockedTimeRemaining }).toString())

                    this.loading = false
                    return
                }

                await this.getLicenseData()
                this.$emit('reloadThemeEvent')

                await this.$router.push({ name: 'mortgage-check-no-login', params: { action: this.$route.params.action } })
                await this.notificationDispatcher.success(this.$t('messages.youreauthenticated').toString())
            } catch (err) {
                await this.notificationDispatcher.error(err.message)
            } finally {
                this.loading = false
            }
        }

        async getLicenseData() {
            await this.getCurrentLicenseCommand.run()
        }
    }
