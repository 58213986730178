import { Expose, Type } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'
import { LicenseFeature } from '@/app/license/entities/license-feature.entity'
import { LicenseContent } from '@/app/license/entities/license-content.entity'
import { LicenseModule } from '@/app/license/entities/license-module.entity'
import { LicenseSmartcare } from '@/app/license/entities/license-smartcare'

export class License extends Entity<License> {
    @Expose()
    @Type(() => LicenseFeature)
    features!: LicenseFeature

    @Expose()
    @Type(() => LicenseModule)
    modules!: LicenseModule

    @Expose()
    @Type(() => LicenseContent)
    content!: LicenseContent

    @Expose()
    @Type(() => LicenseSmartcare)
    smartcare!: LicenseSmartcare
}
