
    import { mapState } from 'vuex'
    import { Component, Inject, Vue } from 'vue-property-decorator'
    import Layout from '../../common/components/layout/layout.vue'
    import { Consumer } from '@/app/identity'
    import { GetInventoryFormRequirementsCommand } from '@/app/inventory-form/commands/get-inventory-form-requirements.command'
    import { InventoryFormRequirement } from '@/app/inventory-form/entities/inventory-form-requirement.entity'
    import { SubmitIwizeInventoryFormCommand } from '@/app/inventory-form/commands/submit-iwize-inventory-form-.command'
    import { NotificationDispatcher } from '@/app/common/services/notification-dispatcher.service'

    @Component({
        components: { Layout },
        metaInfo() {
            return {
                title: 'Iwize form'
            }
        },
        computed: {
            ...mapState('auth', ['consumer']),
        }
    })
    export default class IwizeForm extends Vue {
        requirements: InventoryFormRequirement|null = null
        collecteddata = {}
        events: { date: string, event: any }[] = []
        origin = ''
        consumer!: Consumer

        @Inject() getInventoryFormRequirementsCommand!: GetInventoryFormRequirementsCommand
        @Inject() submitIwizeInventoryFormCommand!: SubmitIwizeInventoryFormCommand
        @Inject() notificationDispatcher!: NotificationDispatcher

        async mounted() {
            try {
                this.requirements = await this.getInventoryFormRequirementsCommand.run(this.$route.params.action)
            } catch (err) {
                await this.notificationDispatcher.error(err.message)
            }

            window.addEventListener('message', this.handleMessageEvent)
        }

        handleMessageEvent(event: any) {
            if (event.data) {
                if (!this.isValidEvent(event)) {
                    return
                }
                if (event.data) {
                    if (event.data.type) {
                        // Received event
                        this.handleEvent(event.data)
                    } else {
                        // Received collected data
                        this.origin = event.origin
                        this.collecteddata = event.data
                        this.handleEvent('Data received from frame')
                        this.submitForm()
                    }
                }
            }
        }

        isValidEvent(event: any) {
            return event?.origin && event.origin === 'https://iwize.nl'
        }

        handleEvent(data: any) {
            this.events.push({
                date: new Date().toISOString(),
                event: data
            })
        }

        async submitForm() {
            const formData = new FormData()

            if (!Object.keys(this.collecteddata).length) {
                return
            }

            try {
                formData.set('iwize-data', new Blob([JSON.stringify(this.collecteddata)]), 'iwize-data.json')
            } catch (e) {
                this.handleEvent(e.message)
            }

            formData.set('iwize-data-origin', this.origin)

            try {
                formData.set('iwize-events', new Blob([JSON.stringify(this.events)]), 'iwize-events.json')
            } catch (e) {
                formData.set('iwize-events', new Blob(['Events array could not be serialized: ', e.message]), 'iwize-events.json')
            }

            try {
                await this.submitIwizeInventoryFormCommand.run(formData, this.$route.params.action)
                await this.$router.push({ name: 'dashboard' })
                await this.notificationDispatcher.success(this.$t('portal.general_notifications.changes_saved.title').toString())
            } catch (err) {
                await this.notificationDispatcher.error(err.message)
            }
        }

    }
