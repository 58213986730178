import { GetterTree } from 'vuex'
import { RootState } from '@/app/common/types/root-state.type'
import { BankguaranteeState } from './state'
import { Bankguarantee } from '@/app/bankguarantee/entities/bankguarantee.entity'

export const getters: GetterTree<BankguaranteeState, RootState> = {

    getByBankguaranteeId(state: BankguaranteeState): (id: string) => Bankguarantee | undefined {
        return (id: string) => {
            const bankguarantees = state.bankguarantees.filter(b => b.id === id)
            return bankguarantees?.[0]
        }
    }

}
