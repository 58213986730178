
    import { Component, Vue, Prop } from 'vue-property-decorator'
    import { GeneralInsurance } from '@/app/general-insurance/entities/general-insurance.entity'
    import SimpleTable from '@/app/common/components/simple-table.vue'

    @Component({
        components: { SimpleTable }
    })
    export default class GeneralInsuranceOverview extends Vue {
        @Prop({ type: GeneralInsurance, required: true }) generalInsurance!: GeneralInsurance
    }
