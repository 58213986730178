import { MutationTree } from 'vuex'
import { OtdState } from './state'
import { Otd } from '@/app/otd/entities/otd.entity'

export const mutations: MutationTree<OtdState> = {

    setOtds(state: OtdState, otds: Otd[]) {
        state.otds = otds
    }

}
