import { ActionTree, Commit } from 'vuex'
import { BusinessIncomeState } from '@/app/business-income/store/state'
import { RootState } from '@/app/common/types/root-state.type'
import BusinessIncome from '@/app/business-income/pages/business-income.vue'

export const actions: ActionTree<BusinessIncomeState, RootState> = {

    setBusinessIncomes({ commit }: { commit: Commit }, businessIncomes: BusinessIncome[]) {
        commit('setBusinessIncomes', businessIncomes)
    }

}
