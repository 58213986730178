
    import { Component, Inject, Prop, Vue } from "vue-property-decorator"
    import { mapState } from "vuex"
    import validation from "@/app/common/helpers/validation"
    import { VForm } from "@/app/common/types/v-form.type"
    import { NotificationDispatcher } from "@/app/common/services/notification-dispatcher.service"

    @Component({
        components: {},
        computed: {
            ...mapState('auth', ['consumer'])
        },
        metaInfo() {
            return {
                title: 'Knowledge experience'
            }
        }
    })
    export default class KnowledgeExperience extends Vue {
        formData = {
            isStarter: false,
            riskKnowSavingsMortgage: false,
            riskKnowInvestmentMortgage: false,
            riskKnowHowFinancialLeaflets: false,
            riskKnowHowAdvisor: false,
            riskKnowHowFamily: false,
            riskKnowHowLiterature: false,
            deductibilityKnowCapping: false,
            deductibilityKnowBorrowing: false,
            deductibilityKnowHomeReserve: false,
            deductibilityKnowDifference: false,
            deductibilityKnowHowFinancialLeaflets: false,
            deductibilityKnowHowAdvisor: false,
            deductibilityKnowHowFamily: false,
            deductibilityKnowHowLiterature: false,
            knowIncomeRetirement: '',
            knowIncomeDeath: '',
            knowIncomeDisability: '',
            knowBuyFinancialProduct: '',
            experienceMortgage: '',
            riskKnowMortgageInterest: false,
            riskKnowNorepaymentMortgage: false,
            riskKnowLinearMortgage: false,
            riskKnowAnnuityMortgage: false
        }
        @Inject() notificationDispatcher!: NotificationDispatcher
        @Prop({ default: () => ({}) }) readonly externalFormData!: any
        mounted() {
            this.formData = { ...this.formData, ...this.externalFormData }
        }
        rules = validation
        get form(): VForm {
            return this.$refs.form as VForm
        }
        validate(): boolean {
            return this.form.validate()
        }
        goToNextStep() {
            if (this.validate()) {
                this.$emit('updateData', this.formData)
                this.$emit('changeStep', 2)
            }
        }
    }
