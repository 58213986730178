import { Expose } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'

export class InventoryFormRequirement extends Entity<InventoryFormRequirement> {
    @Expose()
    id!: string

    @Expose()
    data!: {
        iwize?: { iframe_url: string }
        ockto?: { ockto_qrcode: { qrCode: string|null, url: string|null, errors: object }, ockto_debug: boolean }
    }
}
