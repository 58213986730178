import i18n from '@/plugins/i18n'

export default (value?: any): boolean => {
    const locale = i18n.locale
    let content = value

    if (content === null || value === undefined) {
        return false
    }

    if (typeof value === 'string') {
        content = JSON.parse(value)
    }

    const text = content[locale]

    return text !== undefined && text !== ''
}
