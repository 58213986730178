
    import { Vue, Component, Inject } from 'vue-property-decorator'
    import { ForgotCommand } from '../commands/forgot.command'
    import { NotificationDispatcher } from '../../common/services/notification-dispatcher.service'
    import i18n from '@/plugins/i18n'
    import TopMenu from '@/app/common/components/layout/top-menu.vue'

    @Component({
        components: { TopMenu },
        metaInfo() {
            return {
                title: 'Forgot Password'
            }
        }
    })
    export default class ForgotPassword extends Vue {
        email = ''
        loading = false

        @Inject() forgotCommand!: ForgotCommand
        @Inject() notificationDispatcher!: NotificationDispatcher

        async confirm() {
            try {
                this.loading = true
                await this.forgotCommand.run(this.email)
                await this.notificationDispatcher.success(this.$t('notifications.forgot_password.success').toString())
                await this.$router.push({ name: 'login' })
            } catch (err) {
                // Treat consumer not found errors as successful for security reasons.
                // This way an attacker can't start guessing emails.
                if (err.message === i18n.t('error.consumer_not_found').toString()) {
                    await this.notificationDispatcher.success(this.$t('notifications.forgot_password.success').toString())
                    await this.$router.push({ name: 'login' })
                } else {
                    await this.notificationDispatcher.error(err.message)
                }
            } finally {
                this.loading = false
            }
        }
    }
