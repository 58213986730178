import { AxiosClient } from '@/app/common/services/http/axios-client.service'
import store from '@/plugins/store'
import { GetAllCardifAosCommand } from '@/app/cardifao/commands/get-all-cardifaos.command'
import { GetCardifAoPremiumCommand } from '@/app/cardifao/commands/get-cardifao-premium.command'
import { AcceptCardifPremiumCommand } from '@/app/cardifao/commands/accept-cardif-premium.command'
import { DeclineCardifPremiumCommand } from '@/app/cardifao/commands/decline-cardif-premium.command'

const httpClient = new AxiosClient()

export const getAllCardifAosCommand = new GetAllCardifAosCommand(httpClient, store)
export const getCardifAoPremiumCommand = new GetCardifAoPremiumCommand(httpClient, store)
export const acceptCardifPremiumCommand = new AcceptCardifPremiumCommand(httpClient, store)
export const declineCardifPremiumCommand = new DeclineCardifPremiumCommand(httpClient, store)
