
    import { mapGetters, mapState } from 'vuex'
    import { Component, Inject, Vue } from 'vue-property-decorator'
    import Layout from '../../common/components/layout/layout.vue'
    import { GetAllBankguaranteesCommand } from '@/app/bankguarantee/commands/get-all-bankguarantees.command'
    import { ProductAction } from '@/app/product/entities/product-action.entity'
    import { GetAllActionsCommand } from '@/app/product/commands/get-all-actions.command'
    import ProductActionList from '@/app/product/components/product-action-list.vue'
    import { Bankguarantee } from '@/app/bankguarantee/entities/bankguarantee.entity'
    import { DownloadBankguaranteeDocumentCommand } from '@/app/bankguarantee/commands/download-bankguarantee-document.command'
    import { SubmitBnpChoiceCommand } from '@/app/bankguarantee/commands/submit-bnp-choice.command'
    import { NotificationDispatcher } from '@/app/common/services/notification-dispatcher.service'
    import validation from '@/app/common/helpers/validation'
    import SimpleTable from '@/app/common/components/simple-table.vue'

    @Component({
        components: { Layout, ProductActionList, SimpleTable },
        metaInfo() {
            return {
                title: 'Bank garantie'
            }
        },
        computed: {
            ...mapGetters('bankguarantee', ['getByBankguaranteeId']),
            ...mapState('bankguarantee', ['bankguarantees']),
            ...mapState('product', ['productActions']),
            ...mapGetters('product', ['getByProductActionId'])
        }
    })
    export default class BnpBankguaranteeProposal extends Vue {
        bankguarantee: Bankguarantee | null = null
        getByBankguaranteeId!: (id: string) => Bankguarantee | undefined
        bankguarantees!: Bankguarantee[]
        productActions!: ProductAction[]
        getByProductActionId!: (id: string) => ProductAction | undefined
        productAction: ProductAction | null = null
        loading = false
        agree = false
        rules = validation

        @Inject() getAllBankguaranteesCommand!: GetAllBankguaranteesCommand
        @Inject() getAllActionsCommand!: GetAllActionsCommand
        @Inject() downloadBankguaranteeDocumentCommand!: DownloadBankguaranteeDocumentCommand
        @Inject() submitBnpChoiceCommand!: SubmitBnpChoiceCommand
        @Inject() notificationDispatcher!: NotificationDispatcher

        async mounted() {
            await this.getAllBankguarantees()
            await this.getAllProductActions()

            const product = this.$route.params.id
            const productActionId = this.$route.params.action
            this.bankguarantee = this.getByBankguaranteeId(product) ?? null
            this.productAction = this.getByProductActionId(productActionId) ?? null
        }

        async getAllBankguarantees() {
            // Fetch the bankguarantees if needed. This won't happen
            // if they navigate from the bankguarantee list page, but will
            // happen if they visit this page directly.
            if (!this.bankguarantees.length) {
                await this.getAllBankguaranteesCommand.run()
            }
        }

        async getAllProductActions() {
            if (!this.productActions.length) {
                await this.getAllActionsCommand.run()
            }
        }

        async submitChoice(accepted: boolean) {
            try {
                this.loading = true
                await this.submitBnpChoiceCommand.run(this.productAction!.id, accepted)
                if (accepted) {
                    await this.notificationDispatcher.success(this.$t('page.bnp_bankguarantee.bnp_request_success').toString())
                } else {
                    await this.notificationDispatcher.success(this.$t('page.bnp_bankguarantee.bnp_request_reject').toString())
                }
                await this.$router.push({ name: 'dashboard' })
            } catch (err) {
                await this.notificationDispatcher.error(err.message)
            } finally {
                this.loading = false
            }
        }

        async download(id: string, filename: string, type = '') {
            try {
                this.loading = true
                await this.downloadBankguaranteeDocumentCommand.run(id, filename, type)
            } catch (err) {
                await this.notificationDispatcher.error(err.message)
            } finally {
                this.loading = false
            }
        }
    }
