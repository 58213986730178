
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
    import validation from "@/app/common/helpers/validation"

    @Component
    export default class CurrencyField extends Vue {
        @Prop() readonly value!: string|number|null
        @Prop({ type: String, default: '€' }) prefix!: string
        @Prop({ default: 'auto' }) readonly hideDetails!: boolean|'auto'
        @Prop({ type: Array, required: false, default: [] }) readonly rules!: any[]

        formatter = Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' })
        currencyString = ''
        currencyValue: number|null = null

        @Watch('currencyValue')
        updateValue() {
            this.$emit('input', this.currencyValue)
        }

        mounted() {
            if (this.value) {
                this.formatCurrencyInput(this.value.toString())
            }
        }

        formatCurrencyInput(input: string|null) {
            // allow null input
            if (!input) {
                this.currencyValue = null
                return
            }

            // convert from number format
            let value = +(input)

            // if conversion failed try to convert from EUR format
            if (Number.isNaN(value)) {
                value = this.euroToNumber(input)
            }

            this.currencyValue = value
            this.currencyString = this.formatter.format(value).replace(/€\s+/, '')
        }

        euroToNumber(value: string): number {
            if (/^(\d{1,3}(\.\d{3})*)|(\d*)(?:,\d*)?$/.test(value)) {
                const sanitized = value.replace(/[^0-9,]/, '').replace(',', '.')
                return parseFloat(sanitized)
            }

            return NaN
        }

        getRules(): any[] {
            return [validation.currency()].concat(this.rules)
        }
    }
