export interface ParseSmartcareExplain {
    frontText: string
    euroSymbol: string
    amount: string
    periodText: string
}

export function smartcareExplainParts(explainLine: string): ParseSmartcareExplain {
    const explainMatcher = /^(.*?)€\s*(-?[\d.,]+)\s*(.*)$/.exec(explainLine)

    if (!explainMatcher) {
        return {
            frontText: '',
            euroSymbol: '',
            amount: '',
            periodText: ''
        }
    }

    return {
        frontText: explainMatcher[1].trim(),
        euroSymbol: '€',
        amount: explainMatcher[2],
        periodText: explainMatcher[3]
    }
}

export function translatePeriodText(periodText: string): string {
    if (periodText.includes('maand')) {
        return 'common.per_month'
    } if (periodText.includes('jaar')) {
        return 'common.per_year'
    } if (periodText.includes('overige duur')) {
        return 'common.per_remaining_duration'
    }

    return periodText
}

export function translateFrontText(frontText: string): string {
    if (frontText.includes('Ongeveer')) {
        return 'common.about'
    } if (frontText.includes('ten hoogste')) {
        return 'common.at_most'
    }

    return frontText
}
