
    import { mapGetters, mapState } from 'vuex'
    import { Component, Inject, Vue } from 'vue-property-decorator'
    import Layout from '../../common/components/layout/layout.vue'
    import { PurchaseIntention as PurchaseIntentionEntity } from '@/app/purchase-intention/entities/purchase-intention.entity'
    import { GetAllPurchaseIntentionsCommand } from '@/app/purchase-intention/commands/get-all-purchase-intentions.command'
    import PurchaseIntentionOverview from '@/app/purchase-intention/components/purchase-intention-overview.vue'
    import ProductLayout from '@/app/product/components/product-layout.vue'
    import PurchaseIntentionsHouses from '@/app/purchase-intention/components/purchase-intentions-houses.vue'
    import PurchaseIntentionStatus from '../components/purchase-intention-status.vue'
    import { License } from '@/app/license/entities/license.entity'

    @Component({
        components: { PurchaseIntentionsHouses, Layout, PurchaseIntentionOverview, PurchaseIntentionStatus, ProductLayout },
        metaInfo() {
            return {
                title: 'Purchase Intention'
            }
        },
        computed: {
            ...mapGetters('purchaseIntention', ['getByPurchaseIntentionId']),
            ...mapState('purchaseIntention', ['purchaseIntentions']),
            ...mapState('license', ['license'])
        }
    })
    export default class PurchaseIntention extends Vue {
        purchaseIntention: PurchaseIntentionEntity | null = null
        purchaseIntentions!: PurchaseIntentionEntity[]
        license!: License
        getByPurchaseIntentionId!: (id: string) => PurchaseIntentionEntity | undefined

        @Inject() getAllPurchaseIntentionsCommand!: GetAllPurchaseIntentionsCommand

        get showTimeline() {
            return this.license.features.showPurchaseIntentionTimeline && this.purchaseIntention?.hasTimeline && Array.isArray(this.purchaseIntention?.timeline)
        }

        async mounted() {
            await this.getAllPurchaseIntentions()

            const id = this.$route.params.id
            this.purchaseIntention = this.getByPurchaseIntentionId(id) ?? null
        }

        async getAllPurchaseIntentions() {
            if (!this.purchaseIntentions.length) {
                await this.getAllPurchaseIntentionsCommand.run()
            }
        }
    }
