import { Command } from '@/app/common/base/command'
import { Credit } from '@/app/credit/entities/credit.entity'

export class GetAllCreditsCommand extends Command {
    public async run() {
        const response = await this.client.get('/api/products/credits')
        const credits = response.asEntities(Credit)

        await this.store.dispatch('credit/setCredits', credits)
    }
}
