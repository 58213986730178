import { Expose } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'

export class Theme extends Entity<Theme> {
    @Expose({ name: "color_primary" })
    colorPrimary?: string

    @Expose({ name: "color_accent" })
    colorAccent?: string

    @Expose({ name: "color_buttons" })
    colorButtons?: string

    @Expose({ name: "color_links" })
    colorLinks?: string

    @Expose({ name: "color_headers" })
    colorHeaders?: string

    @Expose({ name: "color_subheaders" })
    colorSubheaders?: string

    @Expose({ name: "color_small_headers" })
    colorSmallHeaders?: string

    @Expose({ name: "color_menu_background" })
    colorMenuBackground?: string

    @Expose({ name: "color_menu_active" })
    colorMenuActive?: string

    @Expose({ name: "color_menu_text" })
    colorMenuText?: string

    @Expose({ name: "color_footer_background" })
    colorFooterBackground?: string

    @Expose({ name: "color_footer_text" })
    colorFooterText?: string

    @Expose({ name: "font_family" })
    fontFontFamily?: string

    @Expose({ name: "font_size" })
    fontFontSize?: number;
}
