
    import { mapGetters, mapState } from 'vuex'
    import { Component, Vue, Inject } from 'vue-property-decorator'
    import Layout from '../../common/components/layout/layout.vue'
    import { SubmitInventoryFormCommand } from '@/app/inventory-form/commands/submit-inventory-form.command'
    import { NotificationDispatcher } from '@/app/common/services/notification-dispatcher.service'
    import ConsumerForm from '@/app/inventory-form/components/consumer-form.vue'
    import { ConsumerData } from '@/app/inventory-form/types/consumer-data'
    import DatePicker from '@/app/common/components/date-picker.vue'
    import { VForm } from '@/app/common/types/v-form.type'
    import { Consumer } from '@/app/identity'
    import { GetPartnerDataCommand } from '@/app/inventory-form/commands/get-partner-data.command'
    import { License } from '@/app/license/entities/license.entity'
    import validation from '@/app/common/helpers/validation'

    @Component({
        components: { ConsumerForm, Layout, DatePicker },
        metaInfo() {
            return {
                title: 'Inventory form'
            }
        },
        computed: {
            ...mapState('license', ['license']),
            ...mapState('auth', ['consumer']),
            ...mapGetters('product', ['getByProductActionId'])
        }
    })
    export default class InventoryForm extends Vue {
        loading = false
        license!: License
        consumer!: Consumer
        partner: Consumer|null = null

        rules = validation

        @Inject() submitInventoryFormCommand!: SubmitInventoryFormCommand
        @Inject() getPartnerDataCommand!: GetPartnerDataCommand
        @Inject() notificationDispatcher!: NotificationDispatcher

        consumerData: ConsumerData = {}
        partnerData: ConsumerData = {}

        // Additional Personal Details
        appointmentGoal = ''
        housingType = ''
        estimatedValue = ''
        existingOrNew = ''
        purchasePrice = ''
        addressData = ''

        // Mortgage Details
        hasMortgage = false
        lender = ''
        mortgageStartDate = ''
        mortgageEndDate = ''
        mortgageTotal = ''
        WOZ = ''
        currentMortgageAmount = ''
        currentMonthlyMortgageAmount = ''
        hasSavingsOrInvestingLinked = false
        savingsOrInvestingAmount = ''
        monthlySavings = ''
        currentSavingsAmount = ''
        currentDebtAmount = ''
        maxNetMonthlyAmount = ''

        // Pension Details
        pensionDate = ''
        partnerPensionDate = ''

        // Extra Details
        hasOrHadCoCRegistration = false
        extraRemarksOrQuestions = ''

        loanPart = { percentage: undefined, type: undefined, lender: undefined, startDate: undefined, endDate: undefined }
        loanParts: { percentage?: number, type?: string, lender?: string, startDate?: string, endDate?: string }[] = []

        pension = { company: undefined, ageStartPayment: undefined, amount: undefined, relativesPensionBeforeAOW: undefined, relativesPensionAfterAOW: undefined }
        pensions: { company?: string, ageStartPayment?: string, amount?: number, relativesPensionBeforeAOW?: string, relativesPensionAfterAOW?: string }[] = []

        partnerPension = { company: undefined, ageStartPayment: undefined, amount: undefined, relativesPensionBeforeAOW: undefined, relativesPensionAfterAOW: undefined }
        partnerPensions: { company?: string, ageStartPayment?: string, amount?: number, relativesPensionBeforeAOW?: string, relativesPensionAfterAOW?: string }[] = []

        async created() {
            try {
                this.partner = await this.getPartnerDataCommand.run()
            } catch (err) {
                await this.notificationDispatcher.error(this.$t('page.inventory_form.error_failed_to_retrieve_partner').toString())
            }
        }

        addLoanPart() {
            this.loanParts.push({ ...this.loanPart })
        }

        removeLoanPart(index: number) {
            this.loanParts.splice(index, 1)
        }

        addPension() {
            this.pensions.push({ ...this.pension })
        }

        removePensionPart(index: number) {
            this.pensions.splice(index, 1)
        }

        addPartnerPension() {
            this.partnerPensions.push({ ...this.partnerPension })
        }

        removePartnerPensionPart(index: number) {
            this.partnerPensions.splice(index, 1)
        }

        formatFormData() {
            return {
                consumer: { ...this.consumerData },
                partner: { ...this.partnerData },
                additionalData: {
                    appointmentGoal: this.appointmentGoal,
                    housingType: this.housingType,
                    purchasePrice: this.purchasePrice,
                    existingOrNew: this.existingOrNew,
                    addressData: this.addressData
                },
                mortgage: {
                    hasMortgage: this.hasMortgage,
                    lender: this.lender,
                    mortgageStartDate: this.mortgageStartDate,
                    mortgageTotal: this.mortgageTotal,
                    loanParts: this.loanParts,
                    WOZ: this.WOZ,
                    currentMortgageAmount: this.currentMortgageAmount,
                    currentMonthlyMortgageAmount: this.currentMonthlyMortgageAmount,
                    hasSavingsOrInvestingLinked: this.hasSavingsOrInvestingLinked,
                    savingsOrInvestingAmount: this.savingsOrInvestingAmount,
                    monthlySavings: this.monthlySavings,
                    currentSavingsAmount: this.currentSavingsAmount,
                    currentDebtAmount: this.currentDebtAmount,
                    maxNetMonthlyAmount: this.maxNetMonthlyAmount
                },
                pension: {
                    pensionDate: this.pensionDate,
                    pensions: this.pensions,
                },
                partnerPension: {
                    partnerPensionDate: this.pensionDate,
                    partnerPensions: this.partnerPensions,
                },
                general: {
                    hasOrHadCoCRegistration: this.hasOrHadCoCRegistration,
                    extraRemarksOrQuestions: this.extraRemarksOrQuestions
                }
            }
        }

        get form(): VForm {
            return this.$refs.form as VForm
        }

        validate(): boolean {
            const isValid = this.form.validate()

            if (!isValid) {
                this.notificationDispatcher.error(this.$t('page.inventory_form.error_validation').toString())
            }

            return isValid
        }

        async submitInventoryForm() {
            try {
                this.loading = true
                const productActionId = this.$route.params.action
                await this.submitInventoryFormCommand.run(this.formatFormData(), productActionId)
                await this.$router.push({ name: 'dashboard' })
                await this.notificationDispatcher.success(this.$t('page.inventory_form.submit_success').toString())
            } catch (err) {
                await this.notificationDispatcher.error(err.message)
            } finally {
                this.loading = false
            }
        }
    }
