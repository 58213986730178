
    import { Component, Prop, Vue } from 'vue-property-decorator'
    import { Otd } from '@/app/otd/entities/otd.entity'
    import SimpleTable from '@/app/common/components/simple-table.vue'

    @Component({
        components: { SimpleTable }
    })
    export default class OtdOverview extends Vue {
        @Prop({ type: Otd, required: true }) otd!: Otd
    }
