import { Command } from '@/app/common/base/command'

export interface UpdateMortgageDetailsData {
    marketValue?: number
    marketValueDate?: string
    basedOn?: string
    loanParts?: object[]
    newLoanParts?: object[]
    smartcareMinimumSavingsAmount?: number
    smartcareMinimumSavingsPeriod?: string
    smartcareAbstainFromCalculationTill?: string
}

export class UpdateMortgageDetailsCommand extends Command {
    public async run(productId: string, productActionId: string, data: UpdateMortgageDetailsData) {
        await this.client.post(`/api/products/mortgages/${productId}/update-details`, {
            productActionId,
            data
        })
    }
}
