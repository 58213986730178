import { GetterTree } from 'vuex'
import { RootState } from '@/app/common/types/root-state.type'
import { FgvState } from '@/app/fgv/store/state'
import { Fgv } from '@/app/fgv/entities/fgv.entity'

export const getters: GetterTree<FgvState, RootState> = {

    getByFgvId(state: FgvState): (id: string) => Fgv | undefined {
        return (id: string) => {
            const fgvs = state.fgvs.filter(f => f.id === id)
            return fgvs?.[0]
        }
    }

}
