import { Command } from '@/app/common/base/command'
import { AuthData } from '@/app/auth/entities/auth-data.entity'

interface LoginCommandResponse {
    twoFactor: boolean
    twoFactorSetup: boolean
    consumerId: string
}

export class LoginCommand extends Command {
    public async run(email: string, password: string): Promise<LoginCommandResponse> {
        const response = await this.client.post('/api/auth/login', { email, password })
        const authData = response.asEntity(AuthData)

        // Store the actual data either on a temporary structure for use
        // later in the 2fa process, or as actual login credentials.
        const action = authData.twoFactor ? 'auth/pendingLogin' : 'auth/login'
        await this.store.dispatch(action, { accessToken: authData.accessToken, refreshToken: authData.refreshToken })
        return { twoFactor: authData.twoFactor, twoFactorSetup: authData.twoFactorSetup, consumerId: authData.consumerId }
    }
}
