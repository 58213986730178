
    import { Vue, Component, Inject } from 'vue-property-decorator'
    import MainMenu from './main-menu.vue'
    import { mapState } from 'vuex'
    import { License } from '@/app/license/entities/license.entity'
    import { Notification } from '@/app/notifications/entities/notification.entity'
    import { GetNotificationsCommand } from '@/app/notifications/commands/get-notifications.command'

    @Component({
        components: { MainMenu },
        computed: {
            ...mapState('license', ['license']),
            ...mapState('siteNotification', ['notifications'])
        }
    })
    export default class MainHeader extends Vue {
        license!: License
        notifications!: Notification[]

        @Inject() getNotificationsCommand!: GetNotificationsCommand

        mounted () {
            this.getNotifications()
        }

        async getNotifications() {
            if (!this.notifications.length) {
                await this.getNotificationsCommand.run()
            }
        }
    }
