import { MutationTree } from 'vuex'
import { AppointmentState } from './state'
import { Appointment } from '@/app/appointment/entities/appointment.entity'

export const mutations: MutationTree<AppointmentState> = {

    setAppointments(state: AppointmentState, appointments: Appointment[]) {
        state.appointments = appointments
    },

    deleteAppointment(state: AppointmentState, appointmentId: string) {
        state.appointments = state.appointments.filter(appointment => {
            return appointment.id !== appointmentId
        })
    }
}
