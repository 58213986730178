import { Command } from '@/app/common/base/command'
import { PurchaseIntention } from '@/app/purchase-intention/entities/purchase-intention.entity'

export class GetAllPurchaseIntentionsCommand extends Command {
    public async run() {
        const response = await this.client.get('/api/products/purchase-intentions')
        const purchaseIntentions = response.asEntities(PurchaseIntention)

        await this.store.dispatch('purchaseIntention/setPurchaseIntentions', purchaseIntentions)
    }
}
