
    import { Component, Inject, Vue } from 'vue-property-decorator'
    import { mapGetters, mapState } from 'vuex'
    import Layout from '../../common/components/layout/layout.vue'
    import { ProductAction } from '@/app/product/entities/product-action.entity'
    import { GetAllActionsCommand } from '@/app/product/commands/get-all-actions.command'
    import { ProductActionMeta, productActionMeta } from '@/app/product/support/product-action-meta'
    import { License } from '@/app/license/entities/license.entity'
    import { Consumer } from '@/app/identity'
    import { VForm } from '@/app/common/types/v-form.type'
    import { UploadDocumentCommand } from '@/app/product/commands/upload-document.command'
    import { NotificationDispatcher } from '@/app/common/services/notification-dispatcher.service'
    import validation from '@/app/common/helpers/validation'
    import localizeContent from '@/app/common/helpers/localize-content'

    @Component({
        components: { Layout },
        metaInfo() {
            return {
                title: 'Upload document'
            }
        },
        computed: {
            ...mapState('license', ['license']),
            ...mapState('auth', ['consumer']),
            ...mapGetters('product', ['getByProductActionId'])
        }
    })
    export default class UploadDocument extends Vue {
        productAction?: ProductAction | null = null
        getByProductActionId!: (id: string) => ProductAction | undefined
        license!: License
        consumer!: Consumer
        uploadLoading = false

        files: File[] = []
        filesModel: File[] = []
        comment: string | null = null

        rules = validation
        hasSubmitted = false
        localizeContent = localizeContent

        @Inject() getAllActionsCommand!: GetAllActionsCommand
        @Inject() uploadDocumentCommand!: UploadDocumentCommand
        @Inject() notificationDispatcher!: NotificationDispatcher

        get productActionText(): ProductActionMeta {
            return productActionMeta(this.productAction!, this.license)
        }

        get form(): VForm {
            return this.$refs.form as VForm
        }

        get isPensionXml(): boolean {
            return this.productAction?.type === 'REQUEST_PENSION_XML'
        }

        get pensionXmlDescription(): string {
            const descriptionKey = this.consumer.hasFormalAddressStyle()
                ? 'productaction.UPLOAD_PENSION_XML.description.formal'
                : 'productaction.UPLOAD_PENSION_XML.description.informal'

            return String(
                this.$t(descriptionKey, {
                    document: this.localizeContent(this.productAction?.document?.label) || '',
                    consumerName: this.productAction?.document?.consumerName || ''
                })
            )
        }

        async mounted() {
            await this.getAllActionsCommand.run()

            const id = this.$route.params.action
            this.productAction = this.getByProductActionId(id) ?? null
        }

        validate(): boolean {
            this.hasSubmitted = true
            return this.form.validate()
        }

        fileInputChanged(file: File | File[]) {
            const uploadedFiles = Array.isArray(file) ? file : [file]
            // The input will report all selected files. Do an array difference to
            // filter the existing files.
            const freshUploads = uploadedFiles.filter((f: File) => !this.files.includes(f))
            this.files = [...this.files, ...freshUploads]
            this.filesModel = this.files
        }

        fileInputCleared() {
            this.files = []
            this.filesModel = []
        }

        getFileInputLabel(): string {
            return this.consumer.hasFormalAddressStyle()
                ? String(this.$t('pages.do_action.uploaddoc.label.formal'))
                : String(this.$t('pages.do_action.uploaddoc.label.informal'))
        }

        getFileInputHint(): string {
            return this.isPensionXml
                ? String(this.$t('pages.do_action.uploaddoc.label.hint.xml'))
                : String(this.$t('pages.do_action.uploaddoc.label.hint'))
        }

        getFileInputAccept(): string {
            return this.isPensionXml
                ? 'application/xml, text/xml'
                : 'image/png, image/jpeg, image/bmp, image/gif, application/pdf'
        }

        async upload() {
            if (!this.validate()) {
                return
            }
            try {
                this.uploadLoading = true

                const data = new FormData()
                data.append('productActionId', this.productAction!.id)

                if (this.comment) {
                    data.append('comment', this.comment)
                }

                this.files.forEach((file: File) => {
                    data.append('file[]', file)
                })

                await this.uploadDocumentCommand.run(data)
                await this.notificationDispatcher.success(this.$t('page.do_action.upload.notification.success').toString())
                await this.$router.push({ name: 'dashboard' })
            } catch (err) {
                await this.notificationDispatcher.error(err.message)
            } finally {
                this.uploadLoading = false
            }
        }
    }
