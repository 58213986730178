
    import { Component, Vue, Inject } from 'vue-property-decorator'
    import { mapGetters, mapState } from 'vuex'
    import Layout from '@/app/common/components/layout/layout.vue'
    import DossierProgress from '@/app/dashboard/components/dossier-progress.vue'
    import { Consumer } from '@/app/identity'
    import { Dossier as DossierEntity } from '@/app/dossier/entities/dossier.entity'
    import { Appointment } from '@/app/appointment/entities/appointment.entity'
    import AppointmentCard from '@/app/dashboard/components/appointment-card.vue'
    import { GetAllActionsCommand } from '@/app/product/commands/get-all-actions.command'
    import ProductActionList from '@/app/product/components/product-action-list.vue'
    import { GetDossierDataCommand } from '@/app/dossier/commands/get-dossier-data.command'
    import { GetAllAppointmentsCommand } from '@/app/appointment/commands/get-all-appointments.command'
    import AdvisorCard from '@/app/identity/components/advisor-card.vue'
    import { License } from '@/app/license/entities/license.entity'
    import hasLocalizedContent from '@/app/common/helpers/has-localized-content'
    import localizeContent from '@/app/common/helpers/localize-content'
    import { GetBannerCommand } from '@/app/banner/commands/get-banner.command'
    import { Banner } from '@/app/banner/entities/banner.entity'

    @Component({
        components: { ProductActionList, AppointmentCard, DossierProgress, AdvisorCard, Layout },
        metaInfo() {
            return {
                title: 'My Overview'
            }
        },
        computed: {
            ...mapState('dossier', ['dossier']),
            ...mapState('license', ['license']),
            ...mapState('auth', ['consumer']),
            ...mapState('appointment', ['appointments']),
            ...mapGetters('product', [
                'productActionsGroupedByProduct',
                'downloadableProductActionsGroupedByProduct'
            ]),
            ...mapGetters('appointment', ['getFirstUpcomingAppointment']),
        }
    })
    export default class Dashboard extends Vue {
        dossier!: DossierEntity
        license!: License
        consumer!: Consumer
        appointment: Appointment | null = null
        appointments!: Appointment[]
        productActionsGroupedByProduct!: []
        downloadableProductActionsGroupedByProduct!: []
        getFirstUpcomingAppointment!: Appointment | undefined
        banner: Banner | null = null
        hasLocalizedContent = hasLocalizedContent
        localizeContent = localizeContent

        @Inject() getDossierDataCommand!: GetDossierDataCommand
        @Inject() getAllActionsCommand!: GetAllActionsCommand
        @Inject() getAllAppointmentsCommand!: GetAllAppointmentsCommand
        @Inject() getBannerCommand!: GetBannerCommand

        async mounted() {
            await this.getAllActionsCommand.run()
            await this.getDossierDataCommand.run()
            await this.getAllAppointmentsCommand.run()

            this.appointment = this.getFirstUpcomingAppointment ?? null
            this.banner = await this.getBannerCommand.run()
        }

    }
