import { ActionTree, Commit } from 'vuex'
import { RootState } from '@/app/common/types/root-state.type'
import { ImportantDocumentState } from './state'
import { Document } from '@/app/important-documents/entities/document.entity'

export const actions: ActionTree<ImportantDocumentState, RootState> = {
    updateDocuments({ commit }: { commit: Commit }, documents: Document[]) {
        commit('updateDocuments', documents)
    }
}
