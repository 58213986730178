import store from '@/plugins/store'
import { AuthData } from '@/app/auth/entities/auth-data.entity'
import { AxiosClient } from '@/app/common/services/http/axios-client.service'
import { HttpResponse } from '@/app/common/services/http/http-response'

const httpClient = new AxiosClient()
const axiosInstance = httpClient.getClient()
let isRefreshing = false
let failedQueue: any[] = []

const processQueue = (error?: Error, token?: string) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error)
        } else {
            prom.resolve(token)
        }
    })

    failedQueue = []
}

export default (onError?: (err: Error) => void) => {
    return (error: any) => {
        const originalRequest = error.config
        const refreshToken = store.state.auth.refreshToken

        store.dispatch('global/hideNetworkLoader')

        if (error.response.status === 401 && !originalRequest.retry) {
            if (isRefreshing) {
                return new Promise((resolve, reject) => {
                    failedQueue.push({ resolve, reject })
                }).then(token => {
                    originalRequest.headers.Authorization = `Bearer ${token}`
                    return axiosInstance(originalRequest)
                }).catch(err => {
                    return Promise.reject(err)
                })
            }

            originalRequest.retry = true
            isRefreshing = true

            return new Promise((resolve, reject) => {
                httpClient.post('/api/auth/refresh', { refreshToken })
                    .then((response: HttpResponse) => {
                        const authData = response.asEntity(AuthData)

                        originalRequest.headers.Authorization = `Bearer ${authData.accessToken}`

                        // noinspection JSIgnoredPromiseFromCall
                        store.dispatch('auth/login', {
                            accessToken: authData.accessToken,
                            refreshToken: authData.refreshToken
                        })

                        processQueue(undefined, authData.accessToken)

                        resolve(axiosInstance(originalRequest))
                    })
                    .catch(err => {
                        // noinspection JSIgnoredPromiseFromCall
                        store.dispatch('auth/logout')

                        processQueue(err, undefined)

                        if (onError) {
                            onError(err)
                        }

                        reject(err)
                    })
                    .finally(() => {
                        isRefreshing = false
                    })
            })
        }

        return Promise.reject(error)
    }
}

