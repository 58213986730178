
    import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
    import SimpleTable from "@/app/common/components/simple-table.vue"
    import CurrencyField from "@/app/common/components/currency-field.vue"
    import DatePicker from "@/app/common/components/date-picker.vue"
    import { BasedOnOptions } from "@/app/mortgage/support/based-on-options"
    import { MortgageCollateral as CollateralData } from "@/app/mortgage/entities/mortgage-collateral.entity"
    import formalText from "../../common/helpers/formal-text"
    import validation from "@/app/common/helpers/validation"

    @Component({
        methods: { formalText },
        components: { DatePicker, CurrencyField, SimpleTable }
    })
    export default class MortgageCollateral extends Vue {
        @Prop({ type: CollateralData, required: true }) collateral!: CollateralData

        rules = validation

        basedOnOptions = [
            { text: "-", value: null },
            { text: this.$t('page.my_products.mortgage.loan_parts_based_on.woz').toString(), value: BasedOnOptions.Woz },
            { text: this.$t('page.my_products.mortgage.loan_parts_based_on.valuation').toString(), value: BasedOnOptions.Valuation },
            { text: this.$t('page.my_products.mortgage.loan_parts_based_on.remote_valuation').toString(), value: BasedOnOptions.RemoteValuation },
            { text: this.$t('page.my_products.mortgage.loan_parts_based_on.other').toString(), value: BasedOnOptions.Other }
        ]

        collateralData: CollateralData = {
            marketValue: this.collateral.marketValue,
            marketValueDate: this.collateral.marketValueDate,
            basedOn: this.collateral.basedOn,
            basedOnValues: this.collateral.basedOnValues,
            houseNumber: this.collateral.houseNumber,
            streetName: this.collateral.streetName,
            zipCode: this.collateral.zipCode,
            city: this.collateral.streetName
        }

        mounted() {
            this.watchFormData()
        }

        @Watch('collateralData', { deep: true })
        watchFormData() {
            this.$emit('input', this.collateralData)
        }

        getRules(): any[] {
            return this.collateralData.marketValue > 0 ? [this.rules.required()] : []
        }
    }
