import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { RootState } from '@/app/common/types/root-state.type'
import { WoongarantInsuranceState, state } from "@/app/woongarant-insurance/store/state"

export const woongarantInsuranceStore: Module<WoongarantInsuranceState, RootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
