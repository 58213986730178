
    import { Component, Inject, Prop, Vue } from "vue-property-decorator"
    import { VForm } from "@/app/common/types/v-form.type"
    import { NotificationDispatcher } from "@/app/common/services/notification-dispatcher.service"
    import { GetPartnerDataCommand } from "@/app/inventory-form/commands/get-partner-data.command"
    import { Consumer } from "@/app/identity"
    import validation from "@/app/common/helpers/validation"
    import { mapState } from "vuex"

    @Component({
        components: {},
        computed: {
            ...mapState('auth', ['consumer'])
        }
    })
    export default class Income extends Vue {
        formData = {
            customer: [
                { incomeExpectationTrend: '', incomeExpectation: null },
                { incomeExpectationTrend: '', incomeExpectation: null }
            ]
        }

        @Inject() notificationDispatcher!: NotificationDispatcher
        @Inject() getPartnerDataCommand!: GetPartnerDataCommand

        couple: Consumer|null = null
        consumer!: Consumer
        rules = validation
        @Prop({ default: () => ({}) }) readonly externalFormData!: any
        async mounted() {
            this.couple = await this.getPartnerDataCommand.run()
            if (this.externalFormData.customer && this.externalFormData.customer.length >= 2) {
                this.formData = { ...this.formData, ...this.externalFormData }
            } else {
                this.formData.customer = [
                    { incomeExpectationTrend: '', incomeExpectation: null },
                    { incomeExpectationTrend: '', incomeExpectation: null }
                ]
            }
        }
        get form(): VForm {
            return this.$refs.form as VForm
        }
        validate(): boolean {
            return this.form.validate()
        }
        goToPreviousStep() {
            this.$emit('changeStep', 2)
        }
        goToNextStep() {
            if (this.validate()) {
                this.$emit('updateData', this.formData)
                this.$emit('changeStep', 4)
            }
        }
    }
