import { AxiosClient } from '@/app/common/services/http/axios-client.service'
import store from '@/plugins/store'
import { GetAllOtdsCommand } from '@/app/otd/commands/get-all-otds.command'
import { SignOtdCommand } from '@/app/otd/commands/sign-otd.command'
import { DownloadOtdCommand } from '@/app/otd/commands/download-otd.command'

const httpClient = new AxiosClient()

export const getAllOtdsCommand = new GetAllOtdsCommand(httpClient, store)
export const downloadOtdCommand = new DownloadOtdCommand(httpClient, store)
export const signOtdCommand = new SignOtdCommand(httpClient, store)
