import { ActionTree, Commit } from 'vuex'
import { RootState } from '@/app/common/types/root-state.type'
import { MaintenanceState } from '@/app/maintenance/store/state'
import { Maintenance } from '@/app/maintenance/entities/maintenance.entity'

export const actions: ActionTree<MaintenanceState, RootState> = {

    setMaintenance({ commit }: { commit: Commit }, maintenance: Maintenance) {
        commit('setMaintenance', maintenance)
    }

}
