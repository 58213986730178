import Login from './auth/pages/login.vue'
import ForgotPassword from './auth/pages/forgot.vue'
import Logout from './auth/pages/logout.vue'
import ResetPassword from './auth/pages/reset.vue'
import Dashboard from './dashboard/pages/dashboard.vue'
import Settings from './settings/pages/settings.vue'
import MortgageList from '@/app/mortgage/pages/mortgage-list.vue'
import Mortgage from './mortgage/pages/mortgage.vue'
import MortgageUpdateDetails from './mortgage/pages/mortgage-update-details.vue'
import CreditList from '@/app/credit/pages/credit-list.vue'
import Credit from './credit/pages/credit.vue'
import AppointmentList from '@/app/appointment/pages/appointment-list.vue'
import Appointment from '@/app/appointment/pages/appointment.vue'
import BankguaranteeList from '@/app/bankguarantee/pages/bankguarantee-list.vue'
import Bankguarantee from './bankguarantee/pages/bankguarantee.vue'
import BnpBankguaranteeProposal from '@/app/bankguarantee/pages/bnp-bankguarantee-proposal.vue'
import OtdList from './otd/pages/otd-list.vue'
import Otd from './otd/pages/otd.vue'
import OtdSign from './otd/pages/otd-sign.vue'
import ImportantDocuments from '@/app/important-documents/pages/important-documents.vue'
import DownloadQuote from '@/app/product/pages/download-quote.vue'
import LifeInsuranceList from '@/app/life-insurance/pages/life-insurance-list.vue'
import LifeInsurance from '@/app/life-insurance/pages/life-insurance.vue'
import FgvList from '@/app/fgv/pages/fgv-list.vue'
import Fgv from '@/app/fgv/pages/fgv.vue'
import GeneralInsuranceList from '@/app/general-insurance/pages/general-insurance-list.vue'
import GeneralInsurance from '@/app/general-insurance/pages/general-insurance.vue'
import CreateDossier from '@/app/dossier/pages/create-dossier.vue'
import UpdateDateConditionsPrecedent from '@/app/product/pages/update-date-conditions-precedent.vue'
import NotFound from '@/app/common/pages/not-found.vue'
import CardifAoList from '@/app/cardifao/pages/cardifao-list.vue'
import CardifAo from '@/app/cardifao/pages/cardifao.vue'
import CardifAoPremium from '@/app/cardifao/pages/cardifao-premium.vue'
import UploadDocument from '@/app/product/pages/upload-document.vue'
import KnowledgeForm from '@/app/knowledge-form/pages/knowledge-form.vue'
import HypactForm from "@/app/hypact-profile/pages/hypact-form.vue";
import CreateDamageReport from '@/app/general-insurance/pages/create-damage-report.vue'
import InventoryForm from '@/app/inventory-form/pages/inventory-form.vue'
import PurchaseIntentionList from '@/app/purchase-intention/pages/purchase-intention-list.vue'
import PurchaseIntention from '@/app/purchase-intention/pages/purchase-intention.vue'
import ApproveDocument from "@/app/product/pages/approve-document.vue"
import InvoiceList from '@/app/invoice/pages/invoice-list.vue'
import InvoicePayment from '@/app/invoice/pages/invoice-payment.vue'
import ValidateInvoicePayment from '@/app/invoice/pages/validate-invoice-payment.vue'
import BusinessIncomeList from '@/app/business-income/pages/business-income-list.vue'
import BusinessIncome from '@/app/business-income/pages/business-income.vue'
import MortgageMarketValue from './mortgage/pages/mortgage-marketvalue.vue'
import PresentOpportunityToConsumer from "@/app/mortgage/pages/present-opportunity-to-consumer.vue"
import WillWritingAdviceList from "@/app/will_writing_advice/pages/will-writing-advice-list.vue"
import WillWritingAdvice from "@/app/will_writing_advice/pages/will-writing-advice.vue"
import OcktoForm from "@/app/inventory-form/pages/ockto-form.vue"
import IwizeForm from "@/app/inventory-form/pages/iwize-form.vue"
import MortgageCheck from "@/app/mortgage-check/pages/mortgage-check.vue"
import WoongarantInsurance from "@/app/woongarant-insurance/pages/woongarant-insurance.vue"
import ProductActionLogin from "@/app/auth/pages/product-action-login.vue"
import MortgageCheckNoLogin from "@/app/mortgage-check/pages/mortgage-check-no-login.vue"
import SignProposal from '@/app/woongarant-insurance/pages/sign-proposal.vue'

export default [
    {
        path: '*',
        name: 'not-found',
        component: NotFound
    },
    {
        path: '/',
        name: 'login',
        component: Login,
        meta: {
            guest: true
        }
    },
    {
        path: '/mortgage-check-login/:action',
        name: 'mortgage-check-login',
        component: ProductActionLogin,
        meta: {
            guest: true
        }
    },
    {
        path: '/forgot/password',
        name: 'forgot-password',
        component: ForgotPassword,
        meta: {
            guest: true
        }
    },
    {
        path: '/reset/password/:token',
        name: 'reset-password',
        component: ResetPassword,
        meta: {
            guest: true
        }
    },
    {
        path: '/logout',
        name: 'logout',
        component: Logout
    },
    {
        path: '/dossiers/create',
        name: 'create-dossier',
        component: CreateDossier,
        meta: {
            guest: true
        }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard
    },
    {
        path: '/mortgages',
        name: 'mortgage-list',
        component: MortgageList
    },
    {
        path: '/mortgages/:id',
        name: 'mortgage',
        component: Mortgage
    },
    {
        path: '/mortgages/:id/loan-parts/:action',
        name: 'mortgage-loanparts',
        component: MortgageUpdateDetails
    }, {
        path: '/mortgages/:id/marketvalue/:action',
        name: 'mortgage-marketvalue',
        component: MortgageMarketValue
    },
    {
        path: '/mortgages/:id/present-opportunity-to-consumer/:action',
        name: 'present-opportunity-to-consumer',
        component: PresentOpportunityToConsumer
    },
    {
        path: '/appointments',
        name: 'appointment-list',
        component: AppointmentList
    },
    {
        path: '/appointments/:id',
        name: 'appointment',
        component: Appointment
    },
    {
        path: '/bankguarantees',
        name: 'bankguarantee-list',
        component: BankguaranteeList
    },
    {
        path: '/bankguarantees/:id',
        name: 'bankguarantee',
        component: Bankguarantee
    },
    {
        path: '/bankguarantees/:id/bnp-proposal/:action',
        name: 'bankguarantee-bnp-proposal',
        component: BnpBankguaranteeProposal
    },
    {
        path: '/credits',
        name: 'credit-list',
        component: CreditList
    },
    {
        path: '/credits/:id',
        name: 'credit',
        component: Credit
    },
    {
        path: '/otds',
        name: 'otd-list',
        component: OtdList
    },
    {
        path: '/otds/:id',
        name: 'otd',
        component: Otd
    },
    {
        path: '/otds/:id/sign/:action',
        name: 'otd-sign',
        component: OtdSign
    },
    {
        path: '/invoices',
        name: 'invoice-list',
        component: InvoiceList
    },
    {
        path: '/settings',
        name: 'settings',
        component: Settings
    },
    {
        path: '/important-documents',
        name: 'important-documents',
        component: ImportantDocuments
    },
    {
        path: '/life-insurances',
        name: 'life-insurance-list',
        component: LifeInsuranceList
    },
    {
        path: '/life-insurances/:id',
        name: 'life-insurance',
        component: LifeInsurance
    },
    {
        path: '/general-insurances',
        name: 'general-insurance-list',
        component: GeneralInsuranceList
    },
    {
        path: '/general-insurances/:id',
        name: 'general-insurance',
        component: GeneralInsurance
    },
    {
        path: '/general-insurances/:id/damage-reports/create',
        name: 'create-damage-report',
        component: CreateDamageReport
    },
    {
        path: '/fgvs',
        name: 'fgv-list',
        component: FgvList
    },
    {
        path: '/fgvs/:id',
        name: 'fgv',
        component: Fgv
    },
    {
        path: '/cardifao',
        name: 'cardifao-list',
        component: CardifAoList
    },
    {
        path: '/cardifao/:id',
        name: 'cardifao',
        component: CardifAo
    },
    {
        path: '/cardifao/:id/premium/:action',
        name: 'cardifao-premium',
        component: CardifAoPremium
    },
    {
        path: '/purchase-intentions',
        name: 'purchase-intention-list',
        component: PurchaseIntentionList
    },
    {
        path: '/purchase-intentions/:id',
        name: 'purchase-intention',
        component: PurchaseIntention
    },
    {
        path: '/actions/:action/knowledge-test',
        name: 'knowledge-form',
        component: KnowledgeForm
    },
    {
        path: '/actions/:action/hypact-form',
        name: 'hypact-form',
        component: HypactForm
    },
    {
        path: '/actions/:action/inventory',
        name: 'inventory-form',
        component: InventoryForm
    },
    {
        path: '/actions/:action/update-date-conditions-precedent',
        name: 'update-date-conditions-precedent',
        component: UpdateDateConditionsPrecedent
    },
    {
        path: '/actions/:action/upload-document',
        name: 'upload-document',
        component: UploadDocument
    },
    {
        path: '/actions/:action/download-qoute',
        name: 'download-quote',
        component: DownloadQuote
    },
    {
        path: '/actions/:action/approve-document',
        name: 'approve-document',
        component: ApproveDocument
    },
    {
        path: '/actions/:action/invoice-payment',
        name: 'invoice-payment',
        component: InvoicePayment
    },
    {
        path: '/actions/:action/validate-ideal-payment',
        name: 'validate-invoice-payment',
        component: ValidateInvoicePayment
    },
    {
        path: '/business-income',
        name: 'business-income-list',
        component: BusinessIncomeList
    },
    {
        path: '/business-income/:id',
        name: 'business-income',
        component: BusinessIncome
    },
    {
        path: '/will-writing-advices',
        name: 'will-writing-advice-list',
        component: WillWritingAdviceList
    },
    {
        path: '/will-writing-advices/:id',
        name: 'will-writing-advice',
        component: WillWritingAdvice
    },
    {
        path: '/action/:action/ockto-form',
        name: 'ockto-form',
        component: OcktoForm
    },
    {
        path: '/action/:action/iwize-form',
        name: 'iwize-form',
        component: IwizeForm
    },
    {
        path: '/mortgage-check/:id/:action',
        name: 'mortgage-check',
        component: MortgageCheck,
    },
    {
        path: '/woongarant-insurances/:id',
        name: 'woongarant-insurance',
        component: WoongarantInsurance
    },
    {
        path: '/mortgage-check/:action',
        name: 'mortgage-check-no-login',
        component: MortgageCheckNoLogin,
        meta: {
            guest: true
        }
    },
    {
        path: '/woongarant-insurance/:id/sign/:action',
        name: 'sign-proposal',
        component: SignProposal
    }
]
