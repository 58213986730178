import { Expose, Type } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'
import { ProductActionCounter } from '@/app/product/entities/product-action-counter.entity'

export class Credit extends Entity<Credit> {
    @Expose()
    id!: string

    @Expose()
    label!: string

    @Expose({ name: 'lender' })
    lender!: string

    @Expose({ name: 'date_entry' })
    entryDate!: string

    @Expose({ name: 'spending_target' })
    spendingTarget!: string

    @Expose({ name: 'loan_amount' })
    amount!: number

    @Expose({ name: 'repayment_amount' })
    repaymentAmount!: number

    @Expose({ name: 'repayment_period' })
    repaymentPeriod!: string

    @Expose({ name: 'repayment_percentage' })
    repaymentPercentage!: string

    @Expose({ name: 'action_counter' })
    @Type(() => ProductActionCounter)
    actionCounter!: ProductActionCounter
}
