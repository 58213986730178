import { ActionTree, Commit } from 'vuex'
import { WillWritingAdviceState } from '@/app/will_writing_advice/store/state'
import { RootState } from '@/app/common/types/root-state.type'
import { WillWritingAdvice } from '@/app/will_writing_advice/entities/will-writing-advice.entity'

export const actions: ActionTree<WillWritingAdviceState, RootState> = {

    setWillWritingAdvices({ commit }: { commit: Commit }, willWritingAdvices: WillWritingAdvice[]) {
        commit('setWillWritingAdvices', willWritingAdvices)
    }

}
