import { AxiosClient } from '@/app/common/services/http/axios-client.service'
import store from '@/plugins/store'
import { GetAllMortgagesCommand } from '@/app/mortgage/commands/get-all-mortgages.command'
import { UpdateMortgageDetailsCommand } from '@/app/mortgage/commands/update-mortgage-details.command'
import { EditMarketValueCommand } from "@/app/product/commands/edit-market-value";
import {
    UpdatePresentOpportunityToConsumerCommand
} from '@/app/mortgage/commands/update-present-opportunity-to-consumer-command'

const httpClient = new AxiosClient()

export const getAllMortgagesCommand = new GetAllMortgagesCommand(httpClient, store)
export const updateMortgageDetailsCommand = new UpdateMortgageDetailsCommand(httpClient, store)
export const editMarketValueCommand = new EditMarketValueCommand(httpClient, store)
export const updatePresentOpportunityToConsumerCommand = new UpdatePresentOpportunityToConsumerCommand(httpClient, store)
