import { ActionTree, Commit } from 'vuex'
import { RootState } from '@/app/common/types/root-state.type'
import { BankguaranteeState } from './state'
import { Bankguarantee } from '@/app/bankguarantee/entities/bankguarantee.entity'

export const actions: ActionTree<BankguaranteeState, RootState> = {

    setBankguarantees({ commit }: { commit: Commit }, bankguarantees: Bankguarantee[]) {
        commit('setBankguarantees', bankguarantees)
    }

}
