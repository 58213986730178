import { Expose } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'
import { Document } from '@/app/important-documents/entities/document.entity'

export class ImportantDocumentCategory extends Entity<Document> {
    @Expose()
    id!: string

    @Expose()
    name!: string

    @Expose()
    documents!: Document[]
}
