import { Expose } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'

export class DamageReport extends Entity<DamageReport> {
    @Expose()
    cause!: string

    @Expose()
    notes!: string

    @Expose()
    consumer!: string

    @Expose()
    state!: string

    @Expose({ name: 'date_reported' })
    dateReported!: string

    @Expose({ name: 'is_closed' })
    isClosed!: boolean
}
