
    import { Vue, Prop, Component, Watch } from 'vue-property-decorator'

    @Component
    export default class DeleteConfirmation extends Vue {
        @Prop() value!: boolean
        @Prop({ type: String, required: true }) message!: string
        @Prop({ type: Boolean, default: false }) loading!: boolean
        @Prop({ type: String }) confirmText!: string
        @Prop({ type: String }) confirmIcon!: string

        dialogModel = false

        @Watch('value')
        updateModel(value: boolean) {
            this.dialogModel = value
        }

        cancel() {
            this.$emit('cancel', true)
        }

        deleteItem() {
            this.$emit('confirm-delete', true)
        }
    }
