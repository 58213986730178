
    import { mapState } from 'vuex'
    import { Component, Inject, Vue } from 'vue-property-decorator'
    import Layout from '../../common/components/layout/layout.vue'
    import { Invoice } from '@/app/invoice/entities/invoice.entity'
    import { GetAllInvoicesCommand } from '@/app/invoice/commands/get-all-invoices.command'
    import { DownloadInvoiceCommand } from '@/app/invoice/commands/download-invoice.command'
    import { NotificationDispatcher } from '@/app/common/services/notification-dispatcher.service'

    @Component({
        components: { Layout },
        metaInfo() {
            return {
                title: 'Invoices'
            }
        },
        computed: {
            ...mapState('invoice', ['invoices'])
        }
    })
    export default class InvoiceList extends Vue {
        invoices!: Invoice[]
        loading: string[] = []

        @Inject() getAllInvoicesCommand!: GetAllInvoicesCommand
        @Inject() downloadInvoiceCommand!: DownloadInvoiceCommand
        @Inject() notificationDispatcher!: NotificationDispatcher

        mounted() {
            this.getAllInvoicesCommand.run()
        }

        goToInvoicePayment(productActionApiId: string) {
            this.$router.push({ name: 'invoice-payment', params: { action: productActionApiId } })
        }

        async downloadInvoice(id: string) {
            try {
                this.loading.push(id)
                await this.downloadInvoiceCommand.run(id)
            } catch (err) {
                await this.notificationDispatcher.error(err.message)
            } finally {
                this.loading = this.loading.filter(item => item !== id)
            }
        }
    }
