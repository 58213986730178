import { Command } from '@/app/common/base/command'

export class DownloadBankguaranteeDocumentCommand extends Command {
    public async run(id: string, filename: string, type: string) {
        const response = await this.client.download(`/api/documents/bankguarantees/${id}/download`, {
            responseType: 'blob',
            timeout: 30000,
            params: { type }
        })

        const fileURL = window.URL.createObjectURL(response.data)
        const fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute('download', filename)
        document.body.appendChild(fileLink)
        fileLink.click()
    }
}
