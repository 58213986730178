
    import { mapGetters, mapState } from 'vuex'
    import { Component, Inject, Vue } from 'vue-property-decorator'
    import Layout from '../../common/components/layout/layout.vue'
    import OtdTerms from '../components/otd-terms.vue'
    import { Otd } from '@/app/otd/entities/otd.entity'
    import { GetAllActionsCommand } from '@/app/product/commands/get-all-actions.command'
    import { NotificationDispatcher } from '@/app/common/services/notification-dispatcher.service'
    import { GetAllOtdsCommand } from '@/app/otd/commands/get-all-otds.command'
    import { DownloadOtdCommand } from '@/app/otd/commands/download-otd.command'
    import { SignOtdCommand } from '@/app/otd/commands/sign-otd.command'
    import validation from '@/app/common/helpers/validation'
    import { ProductAction } from '@/app/product/entities/product-action.entity'
    import SimpleTable from '@/app/common/components/simple-table.vue'
    import { Consumer } from "@/app/identity"
    import { VForm } from '@/app/common/types/v-form.type'

    @Component({
        components: { Layout, OtdTerms, SimpleTable },
        metaInfo() {
            return {
                title: 'Sign OTD'
            }
        },
        computed: {
            ...mapGetters('otd', ['getByOtdId']),
            ...mapState('otd', ['otds']),
            ...mapState('product', ['productActions']),
            ...mapGetters('product', ['getByProductActionId']),
            ...mapGetters('submitEnabled', ['submitEnabled']),
            ...mapState('auth', ['consumer'])
        }
    })
    export default class OtdSign extends Vue {
        showTermsDialog = false
        otd: Otd | null = null
        getByOtdId!: (id: string) => Otd | undefined
        otds!: Otd[]
        productActions!: ProductAction[]
        getByProductActionId!: (id: string) => ProductAction | undefined
        productAction: ProductAction | null = null
        loading = false
        agreeToTerms = false
        agreeWithOtd = false
        data = {
            sepaAccountholderIbanAccount: this.otd?.sepaAccountholderIbanAccount,
            sepaAccountholderIbanName: this.otd?.sepaAccountholderIbanName

        }
        rules = validation
        consumer!: Consumer

        @Inject() getAllOtdsCommand!: GetAllOtdsCommand
        @Inject() getAllActionsCommand!: GetAllActionsCommand
        @Inject() downloadOtdCommand!: DownloadOtdCommand
        @Inject() signOtdCommand!: SignOtdCommand
        @Inject() notificationDispatcher!: NotificationDispatcher

        async mounted() {
            await this.getAllOtds()
            await this.getAllProductActions()

            const product = this.$route.params.id
            const productActionId = this.$route.params.action
            this.otd = this.getByOtdId(product) ?? null
            this.productAction = this.getByProductActionId(productActionId) ?? null
        }

        async getAllOtds() {
            // Fetch the otds if needed. This won't happen
            // if they navigate from the otd list page, but will
            // happen if they visit this page directly.
            if (!this.otds.length) {
                await this.getAllOtdsCommand.run()
            }
        }

        async getAllProductActions() {
            if (!this.productActions.length) {
                await this.getAllActionsCommand.run()
            }
        }

        get form(): VForm {
            return this.$refs.form as VForm
        }

        validate(): boolean {
            if (!this.agreeToTerms && !this.agreeWithOtd) {
                this.notificationDispatcher.error(this.$t('error.terms_not_read').toString())
                this.scrollToTermsButton()
                return false
            }
            const validated = this.form.validate()
            if (!validated) {
                this.notificationDispatcher.error(this.$t('validation.error.all_required_fields_must_be_filled').toString())
            }

            return validated && this.agreeWithOtd
        }

        scrollToTermsButton() {
            const termsButton = (this.$refs.termsButton as any).$el
            if (termsButton && typeof termsButton.scrollIntoView === 'function') {
                termsButton.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'nearest',
                })
            }
        }
        updateAgreeToTerms(value: boolean) {
            this.agreeToTerms = value
        }
        updateAgreeWithOtd(value: boolean) {
            this.agreeWithOtd = value
        }
        async signOtd() {
            try {
                if (this.agreeToTerms) {
                    this.loading = true
                    await this.signOtdCommand.run(this.productAction!.id, this.data)
                    await this.notificationDispatcher.success(this.$t('pages.do_action.signotd.sign_success').toString())
                    await this.$router.push({ name: 'dashboard' })
                }
            } catch (err) {
                await this.notificationDispatcher.error(err.message)
            } finally {
                this.loading = false
            }
        }

        async download(id: string, filename: string) {
            try {
                this.loading = true
                await this.downloadOtdCommand.run(this.productAction!.id, filename)
            } catch (err) {
                await this.notificationDispatcher.error(err.message)
            } finally {
                this.loading = false
            }
        }
    }
