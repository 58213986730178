import { Expose } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'
import localizeContent from '@/app/common/helpers/localize-content'

export class UserPanel extends Entity<UserPanel> {
    @Expose()
    name!: string

    @Expose()
    label!: string

    @Expose()
    phone!: string

    @Expose()
    dontShowPrefix!: string

    @Expose({ name: 'emailaddress' })
    email!: string

    @Expose({ name: 'systemname' })
    systemName!: string

    @Expose({ name: 'image' })
    profilePicture?: string

    get initials(): string {
        return this.name.split(' ')
            .map((part: string) => part.slice(0, 1).toUpperCase())
            .join('')
    }

    hasPrefix(): boolean {
        const hasPrefix = parseInt(localizeContent(this.dontShowPrefix), 10)
        return hasPrefix !== 1
    }
}
