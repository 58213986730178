
    import { mapState } from 'vuex'
    import { Component, Inject, Vue } from 'vue-property-decorator'
    import Layout from '../../common/components/layout/layout.vue'
    import AppointmentListItem from '@/app/appointment/components/appointment-list-item.vue'
    import { GetAllAppointmentsCommand } from '@/app/appointment/commands/get-all-appointments.command'
    import { Appointment } from '@/app/appointment/entities/appointment.entity'

@Component({
        components: { Layout, AppointmentListItem },
        metaInfo() {
            return {
                title: 'Appointments'
            }
        },
        computed: {
            ...mapState('appointment', ['appointments'])
        }
    })
    export default class AppointmentList extends Vue {
        appointments!: Appointment[]

        @Inject() getAllAppointmentsCommand!: GetAllAppointmentsCommand

        mounted() {
            this.getAllAppointmentsCommand.run()
        }
    }
