import { ActionTree, Commit } from 'vuex'
import { RootState } from '@/app/common/types/root-state.type'
import { OtdState } from './state'
import { Otd } from '@/app/otd/entities/otd.entity'

export const actions: ActionTree<OtdState, RootState> = {

    setOtds({ commit }: { commit: Commit }, otds: Otd[]) {
        commit('setOtds', otds)
    }

}
