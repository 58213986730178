import { Command } from '@/app/common/base/command'
import { QrCode } from '@/app/settings/entities/qr-code.entity'

export class LoadQrCommand extends Command {

    public async run(): Promise<string> {
        const response = await this.client.get('/api/auth/two-factor-qr')
        const qrCode = response.asEntity(QrCode)

        return qrCode.image
    }

}
