import { ActionTree, Commit } from 'vuex'
import { RootState } from '@/app/common/types/root-state.type'
import { Consumer } from '@/app/identity'
import { AuthState } from './state'

export const actions: ActionTree<AuthState, RootState> = {
    login({ commit }: { commit: Commit }, { accessToken, refreshToken }: { accessToken: string, refreshToken: string }) {
        commit('updateTokens', { accessToken, refreshToken })
    },

    updateProductActionToken(
        { commit }: { commit: Commit },
        { id, token }: { id: string, token: string }
    ) {
        commit('updateProductActionToken', { id, token })
    },

    removeProductActionToken({ commit }: { commit: Commit }) {
        commit('removeProductActionToken')
    },

    pendingLogin(
        { commit }: { commit: Commit },
        { accessToken, refreshToken }: { accessToken: string, refreshToken: string }
    ) {
        commit('updatePendingLogin', { accessToken, refreshToken })
    },

    updateTokens({ commit }: { commit: Commit }, { accessToken, refreshToken }: { accessToken: string, refreshToken: string }) {
        commit('updateTokens', { accessToken, refreshToken })
        commit('clearPendingLogin')
    },

    logout({ commit }: { commit: Commit }) {
        commit('logout')
    },

    updateConsumer({ commit }: { commit: Commit }, consumer: Consumer) {
        commit('updateConsumer', consumer)
    },

    setTwoFactorStatus({ commit }: { commit: Commit }, enabled: boolean) {
        commit('setTwoFactorStatus', enabled)
    }
}
