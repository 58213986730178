import { Expose, Type } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'
import { InsuredConsumerData } from '@/app/life-insurance/entities/insured-consumer-data.entity'

export class InsuredConsumer extends Entity<InsuredConsumer> {
    @Expose()
    name!: string

    @Expose()
    @Type(() => InsuredConsumerData)
    data!: InsuredConsumerData[]
}
