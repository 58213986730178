import { Command } from '@/app/common/base/command'
import { PasswordStrength } from "@/app/auth/entities/password-strength.entity"

export class ConfirmResetTokenCommand extends Command {

    public async run(token: string): Promise<{score: number}> {
        const response = await this.client.post('/api/auth/start-password-reset', { token })
        const passwordStrength = response.asEntity(PasswordStrength)

        return { score: passwordStrength.score }
    }

}
