import { Command } from '@/app/common/base/command'
import { AuthData } from "@/app/auth/entities/auth-data.entity";

export class LoginWithTwoFactorCommand extends Command {

    public async run(consumerId: string, otp: string) {
        const response = await this.client.post('/api/auth/two-factor', {
            consumerId,
            code: otp,
            enabled: true,
            accessToken: this.store.state.auth.pendingCredentials.accessToken
        })
        const authData = response.asEntity(AuthData)

        await this.store.dispatch('auth/updateTokens', { accessToken: authData.accessToken, refreshToken: authData.refreshToken })
    }

}
