
    import KnowledgeExperience from './knowledge-experience.vue'
    import Pep from './pep.vue'
    import Income from './income.vue'
    import Retirement from "@/app/hypact-profile/pages/retirement.vue"
    import Savings from "@/app/hypact-profile/pages/savings.vue"
    import Goals from "@/app/hypact-profile/pages/goals.vue"
    import Layout from '../../common/components/layout/layout.vue'
    import RiskTolerance from "@/app/hypact-profile/pages/risk-tolerance.vue"
    import { Component, Inject, Vue } from "vue-property-decorator"
    import { mapState } from "vuex"
    import PassingAway from "@/app/hypact-profile/pages/passing-away.vue"
    import Sustainability from "@/app/hypact-profile/pages/sustainability.vue"
    import { NotificationDispatcher } from "@/app/common/services/notification-dispatcher.service"
    import { SubmitHypactProfileCommand } from "@/app/hypact-profile/commands/submit-hypact-profile.command"

    @Component({
        components: { Layout,KnowledgeExperience,Pep,Income,Retirement,Savings,Goals,RiskTolerance,PassingAway,Sustainability },
        computed: {
            ...mapState('auth', ['consumer'])
        },
        metaInfo() {
            return {
                title: 'Hypact'
            }
        }
    })
    export default class HypactForm extends Vue {
        currentStep = 1
        formData = {
            knowledgeExperience: {},
            pep: {},
            consumer: {},
            savings:{},
            goals:{},
            riskTolerance:{},
            sustainability:{}
        }
        @Inject() notificationDispatcher!: NotificationDispatcher
        @Inject() submitHypactProfileCommand!: SubmitHypactProfileCommand
        mergeFormData() {
            let mergedData = {}
            Object.keys(this.formData).forEach(key => {
                mergedData = { ...mergedData, ...(this.formData as any)[key] }
            })
            return mergedData
        }
        updateFormData(step: string, data: {}) {
            (this.formData as any)[step] = data
        }
        changeStep(step: number) {
            this.currentStep = step
        }
        async submitForm(ready: boolean){
            if (ready) {
                try {
                    const productActionId = this.$route.params.action
                    await this.submitHypactProfileCommand.run(this.mergeFormData(), productActionId)
                    await this.$router.push({ name: 'dashboard' })
                    await this.notificationDispatcher.success(this.$t('advice.submitSuccess').toString())
                } catch (err) {
                    await this.notificationDispatcher.error(err.message)
                }
            }
        }
    }
