
    import { Component, Inject, Vue } from 'vue-property-decorator'
    import Layout from '../../common/components/layout/layout.vue'
    import { GetAllCreditsCommand } from '@/app/credit/commands/get-all-credits.command'
    import { mapState } from 'vuex'
    import { Credit } from '@/app/credit/entities/credit.entity'

    @Component({
        components: { Layout },
        metaInfo() {
            return {
                title: 'Credits'
            }
        },
        computed: {
            ...mapState('credit', ['credits'])
        }
    })
    export default class CreditList extends Vue {
        credits!: Credit[]

        @Inject() getAllCreditsCommand!: GetAllCreditsCommand

        mounted() {
            this.getAllCreditsCommand.run()
        }
    }
