import axios, { AxiosInstance } from 'axios'
import Env from '@/env'
import { HttpClient } from './http-client.interface'
import { HttpParams } from './http-params.interface'
import { HttpResponse } from '@/app/common/services/http/http-response'
import { BlobResponse } from '@/app/common/services/http/blob-response.entity'
import { HttpRequestBody } from '@/app/common/services/http/http-request-body'
import ErrorTranslator from '@/app/common/services/error-translator.service'

export class AxiosClient implements HttpClient {
    protected static instance: AxiosInstance

    constructor() {
        if (!AxiosClient.instance) {
            AxiosClient.instance = axios.create({
                baseURL: Env.apiUrl,
            })
        }
    }

    /**
     * This should be an internal implementation and not exposed
     * publically or via the interface. It was done for interceptors,
     * so it should be reverted to "protected" once a better solution is found.
     */
    public getClient(): AxiosInstance {
        return AxiosClient.instance
    }

    public async get(url: string, params?: HttpParams): Promise<HttpResponse> {
        try {
            const { data } = await this.getClient().get(url, params)

            return new HttpResponse(data)
        } catch (err) {
            throw new Error(new ErrorTranslator(err.response?.data?.error).translate())
        }
    }

    public async put(url: string, body?: HttpParams): Promise<HttpResponse> {
        try {
            const { data } = await this.getClient().put(url, new HttpRequestBody(body).transform())

            return new HttpResponse(data)
        } catch (err) {
            const code = err.response?.data?.error?.code ?? err.response?.data?.error
            throw new Error(new ErrorTranslator(code).translate())
        }
    }

    public async post(url: string, body?: HttpParams, config?: HttpParams): Promise<HttpResponse> {
        try {
            const { data } = await this.getClient().post(url, new HttpRequestBody(body).transform(), config)

            return new HttpResponse(data)
        } catch (err) {
            const code = err.response?.data?.error?.code ?? err.response?.data?.error
            throw new Error(new ErrorTranslator(code).translate())
        }
    }

    public async multiPart(url: string, body?: FormData): Promise<HttpResponse> {
        try {
            const { data } = await this.getClient().post(url, body, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })

            return new HttpResponse(data)
        } catch (err) {
            const code = err.response?.data?.error?.code ?? err.response?.data?.error
            throw new Error(new ErrorTranslator(code).translate())
        }
    }

    public async download(url: string, params?: HttpParams): Promise<BlobResponse> {
        try {
            const response = await this.getClient().get(url, params)

            return {
                data: response.data,
                headers: response.headers
            }
        } catch (err) {
            const code = err.response?.data?.error?.code ?? err.response?.data?.error
            throw new Error(new ErrorTranslator(code).translate())
        }
    }
}
