import { ActionTree, Commit } from 'vuex'
import { RootState } from '@/app/common/types/root-state.type'
import { CreditState } from './state'
import { Credit } from '@/app/credit/entities/credit.entity'

export const actions: ActionTree<CreditState, RootState> = {

    setCredits({ commit }: { commit: Commit }, credits: Credit[]) {
        commit('setCredits', credits)
    }

}
