import { Command } from '@/app/common/base/command'
import { BusinessIncome } from '@/app/business-income/entities/business-income.entity'

export class GetAllBusinessIncomesCommand extends Command {
    public async run() {
        const response = await this.client.get('/api/products/business-incomes')
        const businessIncome = response.asEntities(BusinessIncome)

        await this.store.dispatch('businessIncome/setBusinessIncomes', businessIncome)
    }
}
