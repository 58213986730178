import { ActionTree, Commit } from 'vuex'
import { RootState } from '@/app/common/types/root-state.type'
import { WoongarantInsurance } from "@/app/woongarant-insurance/entities/woongarant-insurance.entity"
import { WoongarantInsuranceState } from "@/app/woongarant-insurance/store/state"

export const actions: ActionTree<WoongarantInsuranceState, RootState> = {

    setWoongarantInsurances({ commit }: { commit: Commit }, woongarantInsurances: WoongarantInsurance[]) {
        commit('setWoongarantInsurances', woongarantInsurances)
    }

}
