import { AxiosClient } from '@/app/common/services/http/axios-client.service'
import store from '@/plugins/store'
import { SubmitInventoryFormCommand } from '@/app/inventory-form/commands/submit-inventory-form.command'
import { GetPartnerDataCommand } from '@/app/inventory-form/commands/get-partner-data.command'
import { GetInventoryFormRequirementsCommand } from '@/app/inventory-form/commands/get-inventory-form-requirements.command'
import { SubmitIwizeInventoryFormCommand } from '@/app/inventory-form/commands/submit-iwize-inventory-form-.command'
import { GetOcktoFormStateCommand } from '@/app/inventory-form/commands/get-ockto-form-state.command'
import { SubmitOcktoTestDataCommand } from '@/app/inventory-form/commands/submit-ockto-test-data.command'
import { RenewQrCodeCommand } from '@/app/inventory-form/commands/renew-qr-code.command'

const httpClient = new AxiosClient()

export const submitInventoryFormCommand = new SubmitInventoryFormCommand(httpClient, store)
export const getPartnerDataCommand = new GetPartnerDataCommand(httpClient, store)
export const getInventoryFormRequirementsCommand = new GetInventoryFormRequirementsCommand(httpClient, store)
export const submitIwizeInventoryFormCommand = new SubmitIwizeInventoryFormCommand(httpClient, store)
export const getOcktoFormStateCommand = new GetOcktoFormStateCommand(httpClient, store)
export const submitOcktoTestDataCommand = new SubmitOcktoTestDataCommand(httpClient, store)
export const renewQrCodeCommand = new RenewQrCodeCommand(httpClient, store)
