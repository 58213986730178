
    import { Component, Inject, Vue } from 'vue-property-decorator'
    import { mapGetters } from 'vuex'
    import Layout from '../../common/components/layout/layout.vue'
    import { ProductAction } from '@/app/product/entities/product-action.entity'
    import { GetAllActionsCommand } from '@/app/product/commands/get-all-actions.command'
    import { DownloadQuoteAttachmentCommand } from '@/app/product/commands/download-quote-attachment.command'
    import SimpleTable from '@/app/common/components/simple-table.vue'
    import { NotificationDispatcher } from '@/app/common/services/notification-dispatcher.service'

    @Component({
        components: { Layout, SimpleTable },
        metaInfo() {
            return {
                title: 'Document'
            }
        },
        computed: {
            ...mapGetters('product', ['getByProductActionId'])
        }
    })
    export default class DownloadQuote extends Vue {
        productAction?: ProductAction | null = null
        getByProductActionId!: (id: string) => ProductAction | undefined

        @Inject() getAllActionsCommand!: GetAllActionsCommand
        @Inject() downloadQuoteAttachmentCommand!: DownloadQuoteAttachmentCommand
        @Inject() notificationDispatcher!: NotificationDispatcher

        async mounted() {
            await this.getAllActionsCommand.run()
            const id = this.$route.params.action
            this.productAction = this.getByProductActionId(id) ?? null
        }

        async download(attachmentId: string) {
            if (this.productAction) {
                try {
                    const action: ProductAction = this.productAction as ProductAction
                    await this.downloadQuoteAttachmentCommand.run(attachmentId, action.id)
                } catch (err) {
                    await this.notificationDispatcher.error(err.message)
                }
            }
        }
    }
