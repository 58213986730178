import { Expose, Type } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'
import { UserPanel } from '@/app/dossier/entities/user-panel.entity'

export class Dossier extends Entity<Dossier> {
    @Expose()
    id!: string

    @Expose()
    label!: string

    @Expose({ name: 'percentage_complete' })
    progress!: string

    @Expose({ name: 'user_panels' })
    @Type(() => UserPanel)
    userPanels!: UserPanel[]
}
