import { Module, ActionTree, MutationTree } from 'vuex'
import { RootState } from '@/app/common/types/root-state.type'

export interface GlobalState {
    version: number
    locale?: string
    networkLoading: boolean
}

const globalState: GlobalState = {
    version: 1,
    locale: undefined,
    networkLoading: false
}

const actions: ActionTree<GlobalState, RootState> = {
    setLocale({ commit }, locale: string) {
        commit('updateLocale', locale)
    },

    showNetworkLoader({ commit }) {
        commit('updateNetworkLoader', true)
    },

    hideNetworkLoader({ commit }) {
        commit('updateNetworkLoader', false)
    }
}

const mutations: MutationTree<GlobalState> = {
    updateLocale(state: GlobalState, locale: string) {
        state.locale = locale
    },

    updateNetworkLoader(state: GlobalState, status: boolean) {
        state.networkLoading = status
    }
}

export const globalStore: Module<GlobalState, RootState> = {
    namespaced: true,
    state: globalState,
    actions,
    mutations
}
