
    import { Component, Vue, Inject, Prop } from 'vue-property-decorator'
    import { VForm } from '@/app/common/types/v-form.type'
    import validation from '@/app/common/helpers/validation'
    import { NotificationDispatcher } from '@/app/common/services/notification-dispatcher.service'

    @Component
    export default class Sustainability extends Vue {
        formData = {
            sustainabilityInterest: null,
            sustainabilityReasonCosts: false,
            sustainabilityReasonComfort: false,
            sustainabilityReasonSale: false,
            sustainabilityReasonEnvironment: false,
            sustainabilityKnowGeneral: null,
            sustainabilityFinanceLoan: false,
            sustainabilityFinanceSavings: false,
            sustainabilityKnowEnergy: null,
            sustainabilityKnowSavings: null,
            sustainabilityKnowFinancing: null,
            sustainabilityComment: ''
        }
        rules = validation
        @Inject() notificationDispatcher!: NotificationDispatcher
        @Prop({ default: () => ({}) }) readonly externalFormData!: any
        mounted() {
            this.formData = { ...this.formData, ...this.externalFormData }
        }
        get form(): VForm {
            return this.$refs.form as VForm
        }
        validate(): boolean {
            return this.form.validate()
        }
        goToPreviousStep() {
            this.$emit('changeStep', 8)
        }
        goToNextStep() {
            if (this.validate()) {
                this.$emit('updateData', this.formData)
                this.$emit('submitForm', true)
            }
        }
    }
