import i18n from '@/plugins/i18n'

const getQuestions = () => ({
    [i18n.t('page.knowledge_form.questions.general.title').toString()]: {
        'financiele_zaken_in_het_algemeen': i18n.t('page.knowledge_form.questions.general.0').toString(),
        'financiele_producten': i18n.t('page.knowledge_form.questions.general.1').toString(),
        'fiscale_wetgeving_belastingen': i18n.t('page.knowledge_form.questions.general.2').toString(),
        'sociale_wetgeving': i18n.t('page.knowledge_form.questions.general.3').toString(),
        'financieel_advies': i18n.t('page.knowledge_form.questions.general.4').toString()
    },
    [i18n.t('page.knowledge_form.questions.mortgage.title').toString()]: {
        'hypotheken_in_het_algemeen': i18n.t('page.knowledge_form.questions.mortgage.0').toString(),
        'de_verschillende_aflossingsvormen': i18n.t('page.knowledge_form.questions.mortgage.1').toString(),
        'het_risico_van_een_restschuld_bij_verkoop_van_de_woning': i18n.t('page.knowledge_form.questions.mortgage.2').toString(),
        'keuzes_en_risicos_van_mogelijke_rentevastperiodes': i18n.t('page.knowledge_form.questions.mortgage.3').toString(),
        'fiscale_aftrek_hypotheekrente': i18n.t('page.knowledge_form.questions.mortgage.4').toString(),
        'de_bijleenregeling_verkoopwinst': i18n.t('page.knowledge_form.questions.mortgage.5').toString(),
        'risico_van_dubbele_woonlasten': i18n.t('page.knowledge_form.questions.mortgage.6').toString()
    },
    [i18n.t('page.knowledge_form.questions.post_death_income.title').toString()]: {
        'wijzigingen_van_het_inkomen_bij_overlijden': i18n.t('page.knowledge_form.questions.post_death_income.0').toString(),
        'nabestaandevoorziening_anw_overheid': i18n.t('page.knowledge_form.questions.post_death_income.1').toString(),
        'nabestaandepensioen_werkgever': i18n.t('page.knowledge_form.questions.post_death_income.2').toString(),
        'nabestaandevoorziening_uit_een_eigen_verzekering': i18n.t('page.knowledge_form.questions.post_death_income.3').toString(),
        'overlijdensrisicoverzekeringen': i18n.t('page.knowledge_form.questions.post_death_income.4').toString()
    },
    [i18n.t('page.knowledge_form.questions.income_incapacity.title').toString()]: {
        'wijziging_van_het_inkomen_bij_arbeidsongeschiktheid': i18n.t('page.knowledge_form.questions.income_incapacity.0').toString(),
        'aanuvlling_vanuit_de_werkgever_bij_arbeidsongeschiktheid': i18n.t('page.knowledge_form.questions.income_incapacity.1').toString(),
        'verzekering_die_een_aanvulling_geven_bij_arbeidsongeschiktheid': i18n.t('page.knowledge_form.questions.income_incapacity.2').toString()
    },
    [i18n.t('page.knowledge_form.questions.unemployment_income.title').toString()]: {
        'wijziging_van_het_inkomen_bij_werkloosheid': i18n.t('page.knowledge_form.questions.unemployment_income.0').toString(),
        'verzekeringen_die_een_aanvulling_geven_bij_werkloosheid': i18n.t('page.knowledge_form.questions.unemployment_income.1').toString()
    },
    [i18n.t('page.knowledge_form.questions.retirement_income.title').toString()]: {
        'wijziging_van_het_eigen_inkomen_bij_pensioen': i18n.t('page.knowledge_form.questions.retirement_income.0').toString(),
        'aow_via_de_overheid': i18n.t('page.knowledge_form.questions.retirement_income.1').toString(),
        'pensioen_via_de_werkgever': i18n.t('page.knowledge_form.questions.retirement_income.2').toString(),
        'eigen_inkomensaanvulling_bij_pensioen': i18n.t('page.knowledge_form.questions.retirement_income.3').toString()
    },
    [i18n.t('page.knowledge_form.questions.savings.title').toString()]: {
        'beleggen_in_het_algemeen': i18n.t('page.knowledge_form.questions.savings.0').toString(),
        'ontwikkelingen_op_de_financiele_markten': i18n.t('page.knowledge_form.questions.savings.1').toString(),
        'zelf_handelen_in_aandelen': i18n.t('page.knowledge_form.questions.savings.2').toString(),
        'zelf_beleggen_via_beleggingsfondsen': i18n.t('page.knowledge_form.questions.savings.3').toString(),
        'vermogensbeheer': i18n.t('page.knowledge_form.questions.savings.4').toString(),
        'beleggen_via_een_beleggingsverzekering': i18n.t('page.knowledge_form.questions.savings.5').toString(),
        'sparen_in_een_verzekering': i18n.t('page.knowledge_form.questions.savings.6').toString(),
        'sparen_op_een_spaarrekening': i18n.t('page.knowledge_form.questions.savings.7').toString()
    }
})

export default getQuestions
