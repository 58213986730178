import { AxiosClient } from '@/app/common/services/http/axios-client.service'
import store from '@/plugins/store'
import { GetAllInvoicesCommand } from '@/app/invoice/commands/get-all-invoices.command'
import { DownloadInvoiceCommand } from '@/app/invoice/commands/download-invoice.command'
import { GetInvoicePaymentCommand } from '@/app/invoice/commands/get-invoice-payment.command'
import { ValidateInvoicePaymentCommand } from '@/app/invoice/commands/validate-invoice-payment.command'

const httpClient = new AxiosClient()

export const getAllInvoicesCommand = new GetAllInvoicesCommand(httpClient, store)
export const downloadInvoiceCommand = new DownloadInvoiceCommand(httpClient, store)
export const getInvoicePaymentCommand = new GetInvoicePaymentCommand(httpClient, store)
export const validateInvoicePaymentCommand = new ValidateInvoicePaymentCommand(httpClient, store)
