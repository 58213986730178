import { Command } from '@/app/common/base/command'
import { License } from '@/app/license/entities/license.entity'

export class GetCurrentLicenseCommand extends Command {
    public async run() {
        const response = await this.client.get('/api/licenses/current')
        const license = response.asEntity(License)

        await this.store.dispatch('license/setLicense', license)
    }
}
