
    import { mapGetters, mapState } from "vuex"
    import { Vue, Component, Inject } from 'vue-property-decorator'
    import Layout from "@/app/common/components/layout/layout.vue"
    import { WoongarantInsurance as WoongarantInsuranceEntity } from "@/app/woongarant-insurance/entities/woongarant-insurance.entity"
    import { GetAllWoongarantInsurancesCommand } from "@/app/woongarant-insurance/commands/get-all-woongarant-insurances.command"
    import SimpleTable from "@/app/common/components/simple-table.vue"
    import ProductLayout from "@/app/product/components/product-layout.vue"

    @Component({
        components: { SimpleTable, ProductLayout, Layout },
        metaInfo() {
            return {
                title: this.$t('page.woongarant_insurance.title').toString()
            }
        },
        computed: {
            ...mapGetters('woongarantInsurance', ['getByWoongarantInsuranceId']),
            ...mapState('woongarantInsurance', ['woongarantInsurances']),
        }
    })
    export default class WoongarantInsurance extends Vue
    {
        woongarantInsurances!: WoongarantInsurance[]
        woongarantInsurance: WoongarantInsuranceEntity | null = null
        getByWoongarantInsuranceId!: (id: string) => WoongarantInsuranceEntity | undefined

        @Inject() getAllWoongarantInsurancesCommand!: GetAllWoongarantInsurancesCommand

        get productId(): string {
            return this.woongarantInsurance?.productId ?? ''
        }

        get woongarantInsuranceId(): string {
            return this.$route.params.id
        }

        async mounted() {
            if (!this.woongarantInsurances.length) {
                await this.getAllWoongarantInsurancesCommand.run()
            }
            this.woongarantInsurance = this.getByWoongarantInsuranceId(this.woongarantInsuranceId) ?? null
        }
    }
