
    import { mapGetters, mapState } from 'vuex'
    import { Component, Vue, Inject } from 'vue-property-decorator'
    import Layout from '../../common/components/layout/layout.vue'
    import LifeInsuranceOverview from '@/app/life-insurance/components/life-insurance-overview.vue'
    import { LifeInsurance as LifeInsuranceEntity } from '@/app/life-insurance/entities/life-insurance.entity'
    import { GetAllLifeInsurancesCommand } from '@/app/life-insurance/commands/get-all-life-insurances.command'
    import ProductLayout from '@/app/product/components/product-layout.vue'

    @Component({
        components: { LifeInsuranceOverview, Layout, ProductLayout },
        metaInfo() {
            return {
                title: 'Life Insurance'
            }
        },
        computed: {
            ...mapGetters('lifeInsurance', ['getByLifeInsuranceId']),
            ...mapState('lifeInsurance', ['lifeInsurances'])
        }
    })
    export default class LifeInsurance extends Vue {
        lifeInsurance: LifeInsuranceEntity | null = null
        lifeInsurances!: LifeInsuranceEntity[]
        getByLifeInsuranceId!: (id: string) => LifeInsuranceEntity | undefined

        @Inject() getAllLifeInsurancesCommand!: GetAllLifeInsurancesCommand

        async mounted() {
            await this.getAllLifeInsurances()

            const id = this.$route.params.id
            this.lifeInsurance = this.getByLifeInsuranceId(id) ?? null
        }

        async getAllLifeInsurances() {
            // Fetch the life insurances if needed. This won't happen
            // if they navigate from the life insurance list page, but will
            // happen if they visit this page directly.
            if (!this.lifeInsurances.length) {
                await this.getAllLifeInsurancesCommand.run()
            }
        }
    }
