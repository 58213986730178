
    import { Component, Vue, Prop, Inject } from 'vue-property-decorator'
    import { mapGetters, mapState } from 'vuex'
    import { ProductAction } from '@/app/product/entities/product-action.entity'
    import { GetAllActionsCommand } from '@/app/product/commands/get-all-actions.command'
    import ProductActionList from '@/app/product/components/product-action-list.vue'
    import { Dossier } from '@/app/dossier/entities/dossier.entity'
    import { GetDossierDataCommand } from '@/app/dossier/commands/get-dossier-data.command'
    import { UserPanel } from '@/app/dossier/entities/user-panel.entity'
    import AdvisorCard from '@/app/identity/components/advisor-card.vue'

    @Component({
        components: { ProductActionList, AdvisorCard },
        computed: {
            ...mapState('product', ['productActions']),
            ...mapState('dossier', ['dossier']),
            ...mapGetters('product', ['productActionsByProductId', 'downloadableProductActionsByProductId'])
        }
    })
    export default class ProductLayout extends Vue {
        @Prop({ required: true }) productId!: string
        @Prop({ default: false }) withAdvisor!: boolean

        productActions!: ProductAction[]
        currentProductActions: ProductAction[] = []
        downloadableProductActions: ProductAction[] = []
        dossier!: Dossier

        productActionsByProductId!: (id: string) => ProductAction[]
        downloadableProductActionsByProductId!: (id: string) => ProductAction[]

        @Inject() getAllActionsCommand!: GetAllActionsCommand
        @Inject() getDossierDataCommand!: GetDossierDataCommand

        get advisor(): UserPanel|undefined {
            return this.dossier?.userPanels.filter((panel: UserPanel) => panel.systemName === 'ADVISOR')?.[0]
        }

        async mounted() {
            await this.getAllProductActions()
            await this.getDossierData()

            this.currentProductActions = this.productActionsByProductId(this.productId)
            this.downloadableProductActions = this.downloadableProductActionsByProductId(this.productId)
        }

        async getAllProductActions() {
            if (!this.productActions.length) {
                await this.getAllActionsCommand.run()
            }
        }

        async getDossierData() {
            if (!this.dossier) {
                await this.getDossierDataCommand.run()
            }
        }
    }
