import { Module, ActionTree, MutationTree } from 'vuex'
import { RootState } from '../../types/root-state.type'
import { Notification } from './notification.model'

export interface NotificationState {
    notifications: Notification[]
}

const notificationState: NotificationState = {
    notifications: []
}

const actions: ActionTree<NotificationState, RootState> = {
    show({ commit }, { type, message, delay = 3000 }: { type: string, message: string, delay: number }) {
        commit('addNotification', { type, message, delay })
    },

    remove({ commit }, id: number) {
        commit('removeNotification', id)
    }
}

const mutations: MutationTree<NotificationState> = {
    addNotification(state: NotificationState, { type, message, delay }: { type: string, message: string, delay: number }) {
        state.notifications.push({ type, message, delay, id: Math.random() })
    },

    removeNotification(state: NotificationState, id: number) {
        const found = state.notifications.find((n: any) => n.id === id)

        if (found) {
            state.notifications.splice(state.notifications.indexOf(found), 1)
        }
    }
}

export const notificationStore: Module<NotificationState, RootState> = {
    namespaced: true,
    state: notificationState,
    actions,
    mutations
}
