
    import { Component, Vue, Prop } from 'vue-property-decorator'
    import { PurchaseIntentionTimeline } from '@/app/purchase-intention/entities/purchase-intention-timeline.entity'
    import localizeContent from '@/app/common/helpers/localize-content'

    @Component
    export default class PurchaseIntentionStatus extends Vue {
        @Prop({ type: Array, required: true }) timeline!: PurchaseIntentionTimeline[]

        localizeContent = localizeContent
    }
