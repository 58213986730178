import { Command } from '@/app/common/base/command'
import { AuthData } from "@/app/auth/entities/auth-data.entity";

export class DisableTwoFactorCommand extends Command {

    public async run(code: string) {
        const response = await this.client.post('/api/auth/two-factor', { code, enabled: false })
        const authData = response.asEntity(AuthData)

        await this.store.dispatch('auth/updateTokens', { accessToken: authData.accessToken, refreshToken: authData.refreshToken })
        await this.store.dispatch('auth/setTwoFactorStatus', false)
    }

}
