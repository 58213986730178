
    import { mapGetters, mapState } from 'vuex'
    import { Component, Vue, Inject } from 'vue-property-decorator'
    import Layout from '../../common/components/layout/layout.vue'
    import { Mortgage as MortgageEntity  } from '@/app/mortgage/entities/mortgage.entity'
    import { GetAllMortgagesCommand } from '@/app/mortgage/commands/get-all-mortgages.command'
    import DatePicker from '@/app/common/components/date-picker.vue'
    import CurrencyField from '@/app/common/components/currency-field.vue'
    import { NotificationDispatcher } from '@/app/common/services/notification-dispatcher.service'
    import SimpleTable from '@/app/common/components/simple-table.vue'
    import { GetAllActionsCommand } from '@/app/product/commands/get-all-actions.command'
    import validation from '@/app/common/helpers/validation'
    import { VForm } from '@/app/common/types/v-form.type'
    import { EditMarketValueCommand } from '@/app/product/commands/edit-market-value'

@Component({
    components: { Layout, DatePicker, CurrencyField, SimpleTable },
    metaInfo() {
        return {
            title: 'Mortgage Market Value'
        }
    },
    computed: {
        ...mapGetters('mortgage', ['getByMortgageId']),
        ...mapState('mortgage', ['mortgages'])
    }
})
    export default class MortgageMarketvalue extends Vue {
    mortgage: MortgageEntity | null = null
    getByMortgageId!: (id: string) => MortgageEntity | undefined
    mortgages!: MortgageEntity[]
    saving = false
    marketValue: number | null = null
    currentMarketValue: number | null = null
    houseNumber: number | null = null
    zipCode: string | null = null
    streetName: string | null = null
    marketValueDate = ''
    city: string | null = null
    rules = validation
    selectedOption = ''

    @Inject() getAllMortgagesCommand!: GetAllMortgagesCommand
    @Inject() editMarketValueCommand!: EditMarketValueCommand
    @Inject() notificationDispatcher!: NotificationDispatcher
    @Inject() getAllActionsCommand!: GetAllActionsCommand

    async mounted() {
        await this.getAllMortgages()
        const id = this.$route.params.id
        this.mortgage = this.getByMortgageId(id) ?? null
        this.currentMarketValue = this.mortgage?.collateral.marketValue ?? null
        this.marketValue = null
        this.streetName = this.mortgage?.collateral.streetName ?? null
        this.zipCode = this.mortgage?.collateral.zipCode ?? null
        this.houseNumber = this.mortgage?.collateral.houseNumber ?? null
        this.city = this.mortgage?.collateral.city ?? null
        this.marketValueDate = ''
        this.selectedOption = 'other'
    }

    async getAllMortgages() {
        if (!this.mortgages.length) {
            await this.getAllMortgagesCommand.run()
        }
    }

    get form(): VForm {
        return this.$refs.form as VForm
    }

    validate(): boolean {
        const validated = this.form.validate()
        if (!validated) {
            this.notificationDispatcher.error(this.$t('apiError.marketValueFieldsError').toString())
        }

        return validated
    }

    async save() {
        try {
            this.saving = true

            await this.editMarketValueCommand.run(
                this.mortgage!.id,
                this.$route.params.action,
                this.marketValue ?? 0,
                this.marketValueDate || '',
                this.selectedOption
            )

            await this.notificationDispatcher.success(this.$t('mortgage.marketValueUpdateSuccess').toString())
            await this.getAllActionsCommand.run()
            await this.$router.back()
        } catch (err) {
            await this.notificationDispatcher.error(err.message)
        } finally {
            this.saving = false
        }
    }

    }
