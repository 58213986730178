import { Module } from 'vuex'
import { RootState } from '@/app/common/types/root-state.type'
import { BusinessIncomeState, state } from '@/app/business-income/store/state'
import { actions } from '@/app/business-income/store/actions'
import { mutations } from '@/app/business-income/store/mutations'
import { getters } from '@/app/business-income/store/getters'

export const businessIncomeStore: Module<BusinessIncomeState, RootState> = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
