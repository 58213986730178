import i18n from '@/plugins/i18n'
import store from "@/plugins/store"

/*
    Return the formal or informal style based on the authenticated consumer preference.
    Defaults to formal style.
 */
export default function formalText(slug: string): string {
    const style = store.state.auth.consumer?.addressStyle ?? 'formal'
    return i18n.t(slug.concat((style === 'informal') ? '.informal' : '.formal')).toString()
}
