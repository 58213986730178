import { Module } from 'vuex'
import { MaintenanceState, state } from './state'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { RootState } from '@/app/common/types/root-state.type'

export const maintenanceStore: Module<MaintenanceState, RootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
