import { Command } from "@/app/common/base/command"
import { ProductActionAuthEntity } from "@/app/auth/entities/product-action-auth.entity"

export class SubmitMortgageCheckNoLoginCommand extends Command {

    public async run(auth: ProductActionAuthEntity, productActionId: string, formData: object): Promise<void> {
        await this.client.post('/api/mortgage-check/submit-form-no-login', { consumerId: auth.id , productActionId, formData }, {
            headers: {
                'Token': auth.token
            }
        })

        await this.store.dispatch('auth/removeProductActionToken')
    }

}
