import { Command } from '@/app/common/base/command'
import { Dossier } from '@/app/dossier/entities/dossier.entity'

export class GetDossierDataCommand extends Command {
    public async run() {
        const response = await this.client.get(`/api/dossiers`)
        const dossier = response.asEntity(Dossier)

        await this.store.dispatch('dossier/setDossier', dossier)
    }
}
