import { GetterTree } from 'vuex'
import { RootState } from '@/app/common/types/root-state.type'
import { OtdState } from './state'
import { Otd } from '@/app/otd/entities/otd.entity'

export const getters: GetterTree<OtdState, RootState> = {

    getByOtdId(state: OtdState): (id: string) => Otd | undefined {
        return (id: string) => {
            const otds = state.otds.filter(b => b.id === id)
            return otds?.[0]
        }
    }

}
