
    import { Component, Inject, Prop, Vue } from 'vue-property-decorator'
    import { Dossier } from '@/app/dossier/entities/dossier.entity'
    import { Consumer } from '@/app/identity/entities/consumer.entity'
    import SimpleTable from '@/app/common/components/simple-table.vue'
    import { mapState } from 'vuex'
    import { License } from '@/app/license/entities/license.entity'
    import { GetPartnerDataCommand } from '@/app/inventory-form/commands/get-partner-data.command'

    @Component({
        components: { SimpleTable },
        computed: {
            ...mapState('license', ['license']),
            ...mapState('auth', ['consumer'])
        }
    })
    export default class DossierProgress extends Vue {
        @Prop({ type: Dossier, required: true }) dossier!: Dossier

        license!: License
        consumer!: Consumer
        partner: Consumer|null = null

        @Inject() getPartnerDataCommand!: GetPartnerDataCommand

        async mounted() {
            this.partner = await this.getPartnerDataCommand.run()
        }
    }
