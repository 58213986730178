import { GetterTree } from 'vuex'
import { RootState } from '@/app/common/types/root-state.type'
import { LifeInsuranceState } from '@/app/life-insurance/store/state'
import { LifeInsurance } from '@/app/life-insurance/entities/life-insurance.entity'

export const getters: GetterTree<LifeInsuranceState, RootState> = {

    getByLifeInsuranceId(state: LifeInsuranceState): (id: string) => LifeInsurance | undefined {
        return (id: string) => {
            const lifeInsurances = state.lifeInsurances.filter(m => m.id === id)
            return lifeInsurances?.[0]
        }
    }

}
