import { Entity } from "@/app/common/base/entity"
import { Expose, Type } from "class-transformer"
import { WoongarantInsuranceDocument } from "@/app/woongarant-insurance/entities/woongarant-insurance-document.entity"
import { ProductActionCounter } from "@/app/product/entities/product-action-counter.entity"

export class WoongarantInsurance extends Entity<WoongarantInsurance> {
    @Expose()
    id!: string

    @Expose({ name: 'product_id' })
    productId!: string

    @Expose({ name: 'product_type' })
    type!: string

    @Expose({ name: 'insurance_id' })
    insuranceId!: string

    @Expose()
    status!: string

    @Expose({ name: 'date_start' })
    dateStart!: string

    @Expose()
    @Type(() => WoongarantInsuranceDocument)
    documents!: WoongarantInsuranceDocument[]

    @Expose({ name: 'action_counter' })
    @Type(() => ProductActionCounter)
    actionCounter!: ProductActionCounter
}
