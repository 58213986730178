
    import { mapGetters, mapState } from 'vuex'
    import { Component, Vue, Inject } from 'vue-property-decorator'
    import Layout from '../../common/components/layout/layout.vue'
    import { Mortgage as MortgageEntity } from '@/app/mortgage/entities/mortgage.entity'
    import { GetAllMortgagesCommand } from '@/app/mortgage/commands/get-all-mortgages.command'
    import DatePicker from '@/app/common/components/date-picker.vue'
    import CurrencyField from '@/app/common/components/currency-field.vue'
    import moment from 'moment'
    import {
        UpdateMortgageDetailsCommand,
        UpdateMortgageDetailsData
    } from '@/app/mortgage/commands/update-mortgage-details.command'
    import { NotificationDispatcher } from '@/app/common/services/notification-dispatcher.service'
    import SimpleTable from '@/app/common/components/simple-table.vue'
    import { GetAllActionsCommand } from '@/app/product/commands/get-all-actions.command'
    import { LoanPartFormItem } from '@/app/mortgage/support/loan-part-form-item'
    import { SmartcareSavingPeriod } from '@/app/product/support/smartcare-saving-period'
    import validation from '@/app/common/helpers/validation'
    import { VForm } from '@/app/common/types/v-form.type'
    import MortgageLoanParts from "@/app/mortgage/components/mortgage-loan-parts.vue"
    import MortgageCollateral from "@/app/mortgage/components/mortgage-collateral.vue"
    import { MortgageCollateral as CollateralData } from "@/app/mortgage/entities/mortgage-collateral.entity"
    import formalText from "../../common/helpers/formal-text"

    @Component({
        methods: { formalText },
        components: { MortgageCollateral, MortgageLoanParts, Layout, DatePicker, CurrencyField, SimpleTable },
        metaInfo() {
            return {
                title: 'Mortgage Loan Parts'
            }
        },
        computed: {
            ...mapGetters('mortgage', ['getByMortgageId']),
            ...mapState('mortgage', ['mortgages'])
        }
    })
    export default class MortgageUpdateDetails extends Vue {
        mortgage: MortgageEntity | null = null
        getByMortgageId!: (id: string) => MortgageEntity | undefined
        mortgages!: MortgageEntity[]
        saving = false
        collateralData: null|CollateralData = null
        loanPartsData: LoanPartFormItem[] = []
        smartcareMinimumSavingsAmount: number|null = null
        smartcareMinimumSavingsPeriod: string|null = null
        smartcareAbstainFromCalculationTill: string|null = null
        smartcareSavingsPeriods = [
            { text: this.$t('page.my_products.mortgage.labels.premium_per_month').toString(), value: SmartcareSavingPeriod.monthly },
            { text: this.$t('page.my_products.mortgage.labels.premium_per_year').toString(), value: SmartcareSavingPeriod.annually },
            { text: this.$t('page.my_products.mortgage.labels.per_remaining_duration').toString(), value: SmartcareSavingPeriod.remainingDuration }
        ]

        loanPartTypes: { text: string, value: string }[] = []
        rules = validation

        @Inject() getAllMortgagesCommand!: GetAllMortgagesCommand
        @Inject() updateMortgageDetailsCommand!: UpdateMortgageDetailsCommand
        @Inject() notificationDispatcher!: NotificationDispatcher
        @Inject() getAllActionsCommand!: GetAllActionsCommand

        async mounted() {
            await this.getAllMortgages()

            const id = this.$route.params.id
            this.mortgage = this.getByMortgageId(id) ?? null

            this.smartcareMinimumSavingsAmount = this.mortgage?.smartcare.minimumSavingsAmount ?? null
            this.smartcareMinimumSavingsPeriod = this.mortgage?.smartcare.minimumSavingsPeriod ?? SmartcareSavingPeriod.monthly
            this.smartcareAbstainFromCalculationTill = this.mortgage?.smartcare.abstainFromCalculationTill ?? null
        }

        async getAllMortgages() {
            // Fetch the mortgages if needed. This won't happen
            // if they navigate from the mortgages list page, but will
            // happen if they visit this page directly.
            if (!this.mortgages.length) {
                await this.getAllMortgagesCommand.run()
            }
        }

        get form(): VForm {
            return this.$refs.form as VForm
        }

        validate(): boolean {
            const validated = this.form.validate()

            if (!validated) {
                this.notificationDispatcher.error(this.$t('page.my_products.mortgage.error.mortage_fields_error').toString())
            }

            return validated
        }

        async save() {
            try {
                this.saving = true

                const loanParts = this.loanPartsData.map(loan => ({
                    ...loan,
                    endOfFixedRatePeriod: loan.endOfFixedRatePeriod ? moment(loan.endOfFixedRatePeriod).format('DD-MM-YYYY') : loan.endOfFixedRatePeriod,
                    startOfFixedRatePeriod: loan.startOfFixedRatePeriod ? moment(loan.startOfFixedRatePeriod).format('DD-MM-YYYY') : loan.startOfFixedRatePeriod
                }))

                const data: UpdateMortgageDetailsData = {
                    marketValue: this.collateralData?.marketValue,
                    basedOn: this.collateralData?.basedOn,
                    marketValueDate: this.collateralData?.marketValueDate,
                    smartcareMinimumSavingsAmount: this.smartcareMinimumSavingsAmount ?? undefined,
                    smartcareMinimumSavingsPeriod: this.smartcareMinimumSavingsPeriod ?? undefined,
                    smartcareAbstainFromCalculationTill: this.smartcareAbstainFromCalculationTill ?? undefined,
                    loanParts: loanParts.filter(item => !item.isNew),
                    newLoanParts: loanParts.filter(item => item.isNew)
                }

                await this.updateMortgageDetailsCommand.run(this.mortgage!.id, this.$route.params.action, data)
                await this.notificationDispatcher.success(this.$t('page.my_products.mortgage.loan_parts_update_success').toString())
                await this.getAllActionsCommand.run()
                await this.$router.back()
            } catch (err) {
                await this.notificationDispatcher.error(err.message)
            } finally {
                this.saving = false
            }
        }
    }
