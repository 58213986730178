
    import { mapGetters, mapState } from 'vuex'
    import { Component, Vue, Inject } from 'vue-property-decorator'
    import Layout from '../../common/components/layout/layout.vue'
    import FgvOverview from '../components/fgv-overview.vue'
    import { Fgv as FgvEntity } from '@/app/fgv/entities/fgv.entity'
    import { GetAllFgvsCommand } from '@/app/fgv/commands/get-all-fgvs.command'
    import ProductLayout from '@/app/product/components/product-layout.vue'

    @Component({
        components: { Layout, FgvOverview, ProductLayout },
        metaInfo() {
            return {
                title: 'FGV'
            }
        },
        computed: {
            ...mapGetters('fgv', ['getByFgvId']),
            ...mapState('fgv', ['fgvs'])
        }
    })
    export default class Fgv extends Vue {
        fgv: FgvEntity | null = null
        fgvs!: FgvEntity[]
        getByFgvId!: (id: string) => FgvEntity | undefined

        @Inject() getAllFgvsCommand!: GetAllFgvsCommand

        async mounted() {
            await this.getAllFgvs()

            const id = this.$route.params.id
            this.fgv = this.getByFgvId(id) ?? null
        }

        async getAllFgvs() {
            if (!this.fgvs.length) {
                await this.getAllFgvsCommand.run()
            }
        }
    }
