import { ProductAction } from '@/app/product/entities/product-action.entity'
import { License } from '@/app/license/entities/license.entity'
import localizeContent from '@/app/common/helpers/localize-content'
import i18n from '@/plugins/i18n'

export interface ProductActionMeta {
    title?: string
    formal?: string
    informal?: string
    comment?: string
    button?: string
    to?: string
    download?: boolean
    showButtonWhenClosed?: boolean
    closedButton?: string
    htmlDescription?: boolean
}

export const productActionMeta = (productAction: ProductAction, license: License) => {
    const texts: { [key: string]: ProductActionMeta } = {
        UPLOAD_DOCUMENTMANAGEMENT_FILE: {
            title: `${i18n.t('productaction.UPLOAD_DOCUMENTMANAGEMENT_FILE.title')  } ${  localizeContent(productAction?.document?.label) ?? '' } ${productAction?.document?.consumerName ? `(${  productAction?.document?.consumerName  })` : ''}`,
            formal: `${i18n.t('productaction.UPLOAD_DOCUMENTMANAGEMENT_FILE.description.formal', { document: localizeContent(productAction.document?.label) })} ${productAction.document?.isConsumerSpecific ? i18n.t('product.UPLOAD_DOCUMENTMANAGEMENT_FILE.consumer_specific', { consumer: productAction.document.consumerName }) : ''}`,
            informal: `${i18n.t('productaction.UPLOAD_DOCUMENTMANAGEMENT_FILE.description.informal', { document: localizeContent(productAction.document?.label) })} ${productAction.document?.isConsumerSpecific ? i18n.t('product.UPLOAD_DOCUMENTMANAGEMENT_FILE.consumer_specific', { consumer: productAction.document.consumerName }) : ''}`,
            button: i18n.t('productaction.deliver').toString(),
            to: 'upload-document'
        },
        REUPLOAD_DOCUMENTMANAGEMENT_FILE: {
            title: `${i18n.t('productaction.REUPLOAD_DOCUMENTMANAGEMENT_FILE.title_1')  } ${  localizeContent(productAction?.document?.label) ?? '' } ${productAction?.document?.consumerName ? `(${  productAction?.document?.consumerName  })` : ''}`,
            formal: `${i18n.t('productaction.REUPLOAD_DOCUMENTMANAGEMENT_FILE.description.formal', { document: localizeContent(productAction.document?.label) })} ${productAction.document?.isConsumerSpecific ? i18n.t('product.REUPLOAD_DOCUMENTMANAGEMENT_FILE.consumer_specific', { consumer: productAction.document.consumerName }) : ''}`,
            informal: `${i18n.t('productaction.REUPLOAD_DOCUMENTMANAGEMENT_FILE.description.informal', { document: localizeContent(productAction.document?.label) })} ${productAction.document?.isConsumerSpecific ? i18n.t('product.REUPLOAD_DOCUMENTMANAGEMENT_FILE.consumer_specific', { consumer: productAction.document.consumerName }) : ''}`,
            button: i18n.t('productaction.redeliver').toString(),
            to: 'upload-document'
        },
        FILL_INVENTORY_FORM: {
            title: i18n.t('productaction.FILL_INVENTORY_FORM.title').toString(),
            formal: i18n.t('productaction.FILL_INVENTORY_FORM.description.formal').toString(),
            informal: i18n.t('productaction.FILL_INVENTORY_FORM.description.informal').toString(),
            comment: productAction.comment,
            button: i18n.t('productaction.fill_out').toString(),
            to: 'inventory-form'
        },
        FILL_INVENTORY_IWIZE: {
            title: i18n.t('productaction.FILL_INVENTORY_IWIZE.title', { consumerName: productAction?.consumerName }).toString(),
            formal: i18n.t('productaction.FILL_INVENTORY_IWIZE.description.formal').toString(),
            informal: i18n.t('productaction.FILL_INVENTORY_IWIZE.description.informal').toString(),
            comment: productAction.comment,
            button: i18n.t('productaction.deliver').toString(),
            to: 'iwize-form'
        },
        FILL_INVENTORY_OCKTO: {
            title: i18n.t('productaction.FILL_INVENTORY_OCKTO.title', { consumerName: productAction?.consumerName }).toString(),
            formal: i18n.t('productaction.FILL_INVENTORY_OCKTO.description.formal').toString(),
            informal: i18n.t('productaction.FILL_INVENTORY_OCKTO.description.informal').toString(),
            comment: productAction.comment,
            button: i18n.t('productaction.deliver').toString(),
            to: 'ockto-form'
        },
        REQUEST_DATE_CONDITIONS_PRECEDENT: {
            title: i18n.t('productaction.REQUEST_DATE_CONDITIONS_PRECEDENT.title').toString(),
            formal: i18n.t('productaction.REQUEST_DATE_CONDITIONS_PRECEDENT.description.formal').toString(),
            informal: i18n.t('productaction.REQUEST_DATE_CONDITIONS_PRECEDENT.description.informal').toString(),
            comment: productAction.comment,
            button: i18n.t('productaction.fill_out').toString(),
            to: 'update-date-conditions-precedent'
        },
        SIGN_OTD: {
            title: i18n.t('productaction.SIGN_OTD.title').toString(),
            formal: i18n.t('productaction.SIGN_OTD.description.formal').toString(),
            informal: i18n.t('productaction.SIGN_OTD.description.informal').toString(),
            button: i18n.t('productaction.to_OTD').toString(),
            to: 'otd-sign',
            showButtonWhenClosed: true,
            closedButton: i18n.t('productaction.download').toString()
        },
        DOWNLOAD_QUOTE_AFTER_PAYMENT: {
            formal: i18n.t('productaction.DOWNLOAD_QUOTE_AFTER_PAYMENT.description.formal').toString(),
            informal: i18n.t('productaction.DOWNLOAD_QUOTE_AFTER_PAYMENT.description.informal').toString(),
            button: i18n.t('productaction.view_now').toString(),
            download: true
        },
        DOWNLOAD_OTD: {
            title: i18n.t('productaction.DOWNLOAD_OTD.title').toString(),
            formal: i18n.t('productaction.DOWNLOAD_OTD.description.formal').toString(),
            informal: i18n.t('productaction.DOWNLOAD_OTD.description.informal').toString(),
            button: i18n.t('productaction.download').toString(),
            download: true,
            showButtonWhenClosed: true
        },
        DOWNLOAD_SIGNED_OTD: {
            title: i18n.t('productaction.DOWNLOAD_SIGNED_OTD.title').toString(),
            formal: i18n.t('productaction.DOWNLOAD_SIGNED_OTD.description.formal').toString(),
            informal: i18n.t('productaction.DOWNLOAD_SIGNED_OTD.description.informal').toString(),
            button: i18n.t('productaction.download').toString(),
            download: true,
            showButtonWhenClosed: true
        },
        DOWNLOAD_UNSIGNED_OTD: {
            title: i18n.t('productaction.DOWNLOAD_UNSIGNED_OTD.title').toString(),
            formal: i18n.t('productaction.DOWNLOAD_UNSIGNED_OTD.description.formal').toString(),
            informal: i18n.t('productaction.DOWNLOAD_UNSIGNED_OTD.description.informal').toString(),
            button: i18n.t('productaction.download').toString(),
            download: true,
            showButtonWhenClosed: true
        },
        CARDIFAO_START: {
            title: i18n.t('productaction.CARDIFAO_START.title', { consumerName: productAction?.consumerName }).toString(),
            formal: localizeContent(license.content.cardifAoActionBody),
            informal: localizeContent(license.content.cardifAoActionBody),
            htmlDescription: true,
            button: i18n.t('productaction.calculate_premium').toString(),
            to: 'cardifao-premium'
        },
        APPROVE_DOCUMENT: {
            title: `${i18n.t('productaction.APPROVE_DOCUMENT.title')  } ${  localizeContent(productAction?.document?.label) ?? '' } ${productAction?.document?.consumerName ? `(${  productAction?.document?.consumerName  })` : ''}`,
            formal: !productAction.isDone ? i18n.t('productaction.APPROVE_DOCUMENT.description.formal').toString() : '',
            informal: !productAction.isDone ? i18n.t('productaction.APPROVE_DOCUMENT.description.informal').toString() : '',
            comment: productAction.comment,
            button: i18n.t('productaction.view_now').toString(),
            to: 'approve-document',
            showButtonWhenClosed: true,
            closedButton: i18n.t('productaction.download').toString()
        },
        BNP_BANKGUARANTEE: {
            title: i18n.t('productaction.BNP_BANKGUARANTEE.title').toString(),
            comment: productAction.comment,
            button: i18n.t('productaction.to_guarantee').toString(),
            to: 'bankguarantee-bnp-proposal',
        },
        DOWNLOAD_QUOTE_INDICATIVE_PROPOSAL: {
            title: i18n.t('productaction.DOWNLOAD_QUOTE_INDICATIVE_PROPOSAL.title').toString(),
            formal: i18n.t('productaction.DOWNLOAD_QUOTE_INDICATIVE_PROPOSAL.description_formal').toString(),
            informal: i18n.t('productaction.DOWNLOAD_QUOTE_INDICATIVE_PROPOSAL.description_informal').toString(),
            comment: productAction.comment,
            button: i18n.t('productaction.view_now').toString(),
            download: true,
            showButtonWhenClosed: true
        },
        DOWNLOAD_QUOTE_FINAL_OFFER: {
            title: i18n.t('productaction.DOWNLOAD_QUOTE_FINAL_OFFER.title').toString(),
            formal: i18n.t('productaction.DOWNLOAD_QUOTE_FINAL_OFFER.description_formal').toString(),
            informal: i18n.t('productaction.DOWNLOAD_QUOTE_FINAL_OFFER.description_formal').toString(),
            comment: productAction.comment,
            button: i18n.t('productaction.view_now').toString(),
            download: true,
            showButtonWhenClosed: true
        },
        DOWNLOAD_DOCUMENTMANAGEMENT_FILE: {
            title: `${i18n.t('productaction.DOWNLOAD_DOCUMENTMANAGEMENT_FILE.title')  } ${  localizeContent(productAction?.document?.label) ?? '' } ${productAction?.document?.consumerName ? `(${  productAction?.document?.consumerName  })` : ''}`,
            formal: i18n.t('productaction.DOWNLOAD_DOCUMENTMANAGEMENT_FILE.description.formal', { document: localizeContent(productAction.document?.label) }).toString(),
            informal: i18n.t('productaction.DOWNLOAD_DOCUMENTMANAGEMENT_FILE.description.informal', { document: localizeContent(productAction.document?.label) }).toString(),
            button: i18n.t('productaction.download').toString(),
            download: true,
            showButtonWhenClosed: true
        },
        DOWNLOAD_ADDED_DOCUMENT: {
            button: i18n.t('productaction.download').toString(),
            download: true,
            showButtonWhenClosed: true
        },
        DOWNLOAD_QUOTE: {
            title: i18n.t('productaction.DOWNLOAD_QUOTE.title').toString(),
            formal: i18n.t('productaction.DOWNLOAD_QUOTE.description_formal').toString(),
            informal: i18n.t('productaction.DOWNLOAD_QUOTE.description_informal').toString(),
            comment: productAction.comment,
            button: i18n.t('productaction.view_now').toString(),
            download: true
        },
        DOWNLOAD_QUOTE_DOCUMENT: {
            title: i18n.t('productaction.DOWNLOAD_QUOTE_DOCUMENT.title').toString(),
            formal: i18n.t('productaction.DOWNLOAD_QUOTE_DOCUMENT.description_formal').toString(),
            informal: i18n.t('productaction.DOWNLOAD_QUOTE_DOCUMENT.description_informal').toString(),
            comment: productAction.comment,
            button: i18n.t('productaction.view_now').toString(),
            download: true,
            showButtonWhenClosed: true
        },
        FILL_INVENTORY_KNOWLEDGE_EXPERIENCE: {
            title: i18n.t('productaction.FILL_INVENTORY_KNOWLEDGE_EXPERIENCE.title').toString(),
            formal: i18n.t('productaction.FILL_INVENTORY_KNOWLEDGE_EXPERIENCE.description.formal').toString(),
            informal: i18n.t('productaction.FILL_INVENTORY_KNOWLEDGE_EXPERIENCE.description.informal').toString(),
            button: i18n.t('productaction.fill_out').toString(),
            comment: productAction.comment,
            to: 'knowledge-form'
        },
        REQUEST_MORTGAGE_UPDATE: {
            title: i18n.t('productaction.REQUEST_MORTGAGE_UPDATE.title').toString(),
            formal: i18n.t('productaction.REQUEST_MORTGAGE_UPDATE.description').toString(),
            informal: i18n.t('productaction.REQUEST_MORTGAGE_UPDATE.description').toString(),
            button: i18n.t('productaction.edit_now').toString(),
            comment: productAction.comment,
            to: 'mortgage-loanparts',
            download:false
        },
        EDIT_MARKET_VALUE: {
            title: i18n.t('productaction.EDIT_MARKET_VALUE.title').toString(),
            formal: i18n.t('productaction.REQUEST_MORTGAGE_UPDATE.description').toString(),
            informal: i18n.t('productaction.REQUEST_MORTGAGE_UPDATE.description').toString(),
            button: i18n.t('productaction.edit_now').toString(),
            comment: productAction.comment,
            to: 'mortgage-marketvalue',
            download:false
        },
        PAY_INVOICE_PER_IDEAL: {
            title: i18n.t('productaction.PAY_INVOICE_PER_IDEAL.title', { publicNumber: productAction?.invoice?.publicNumber }).toString(),
            formal: i18n.t('productaction.PAY_INVOICE_PER_IDEAL.description').toString(),
            informal: i18n.t('productaction.PAY_INVOICE_PER_IDEAL.description').toString(),
            comment: productAction.comment,
            button: i18n.t('productaction.PAY_INVOICE_PER_IDEAL.actionbuttonlabel').toString(),
            to: 'invoice-payment'
        },
        PRESENT_OPPORTUNITY_TO_CONSUMER: {
            title: i18n.t('product.PRESENT_OPPORTUNITY_TO_CONSUMER.title').toString(),
            formal: i18n.t('product.PRESENT_OPPORTUNITY_TO_CONSUMER.description.formal').toString(),
            informal: i18n.t('product.PRESENT_OPPORTUNITY_TO_CONSUMER.description.informal').toString(),
            comment: productAction.comment,
            button: i18n.t('product.PRESENT_OPPORTUNITY_TO_CONSUMER.actionbuttonlabel').toString(),
            to: 'present-opportunity-to-consumer',
        },
        HYPACT_CONSUMER_PROFILE: {
            title: i18n.t('productaction.HYPACT_CONSUMER_PROFILE.title').toString(),
            formal: i18n.t('productaction.HYPACT_CONSUMER_PROFILE.description').toString(),
            informal: i18n.t('productaction.HYPACT_CONSUMER_PROFILE.description').toString(),
            button: i18n.t('productaction.HYPACT_CONSUMER_PROFILE.button').toString(),
            comment: productAction.comment,
            to: 'hypact-form',
        },
        REQUEST_PENSION_XML: {
            title: i18n.t('productaction.REQUEST_PENSION_XML.title').toString(),
            formal: i18n.t('productaction.REQUEST_PENSION_XML.description').toString(),
            informal: i18n.t('productaction.REQUEST_PENSION_XML.description').toString(),
            button: i18n.t('productaction.deliver').toString(),
            comment: productAction.comment,
            to: 'upload-document',
        },
        MORTGAGE_CHECK: {
            title: localizeContent(license.content.mortgageCheckTitle),
            formal: localizeContent(license.content.mortgageCheckIntroductionActionPoint),
            informal: localizeContent(license.content.mortgageCheckIntroductionActionPoint),
            button: 'formulier invullen',
            comment: productAction.comment,
            to: 'mortgage-check',
        },
        SIGN_WOONGARANT_INSURANCE_PROPOSAL: {
            title:i18n.t('productaction.SIGN_WOONGARANT_INSURANCE_PROPOSAL.title').toString(),
            formal: i18n.t('productaction.SIGN_WOONGARANT_INSURANCE_PROPOSAL.description').toString(),
            informal: i18n.t('productaction.SIGN_WOONGARANT_INSURANCE_PROPOSAL.description').toString(),
            button: i18n.t('productaction.SIGN_WOONGARANT_INSURANCE_PROPOSAL.button.label').toString(),
            comment: productAction.comment,
            to:'sign-proposal',
        },
    }

    return texts[productAction.type]
}
