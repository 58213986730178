
    import { mapState } from 'vuex'
    import { Component, Inject, Vue } from 'vue-property-decorator'
    import { LoginFromAccelerateCommand } from '@/app/auth/commands/login-from-accelerate.command'
    import { LogoutCommand } from '@/app/auth/commands/logout.command'
    import { UpdateConsumerCommand } from '@/app/auth/commands/update-consumer.command'
    import TwoFactor from '../components/twofactor.vue'
    import { LoginCommand } from '../commands/login.command'
    import { LoginWithTwoFactorCommand } from '../commands/login-with-twofactor.command'
    import { NotificationDispatcher } from '../../common/services/notification-dispatcher.service'
    import { ClearDomainCommand } from '@/app/license/commands/clear-domain.command'
    import MaintenanceNotice from '@/app/maintenance/components/maintenance-notice.vue'
    import { Maintenance } from '@/app/maintenance/entities/maintenance.entity'
    import { GetProductDefinitionsCommand } from '@/app/product/commands/get-product-definitions.command'
    import { SetSentryUserCommand } from '@/app/auth/commands/set-sentry-user.command'
    import { Consumer } from '@/app/identity'
    import TopMenu from '@/app/common/components/layout/top-menu.vue'
    import { VForm } from '@/app/common/types/v-form.type'
    import validation from '@/app/common/helpers/validation'
    import { GetCurrentLanguageCommand } from '@/app/license/commands/get-current-language.command'
    import moment from 'moment/moment'
    import { GetCurrentLicenseCommand } from '@/app/license/commands/get-current-license-command'

    @Component({
        components: { TwoFactor, MaintenanceNotice, TopMenu },
        computed: {
            ...mapState('license', ['domain']),
            ...mapState('maintenance', ['maintenance']),
            ...mapState('auth', ['consumer'])
        }
    })
    export default class Login extends Vue {
        email = ''
        password = ''
        showPassword = false
        showTwoFactor = false
        consumerId: string|null = null
        loading = false
        domain!: string
        maintenance!: Maintenance
        consumer!: Consumer
        loggingFromAccelerate = false

        @Inject() loginCommand!: LoginCommand
        @Inject() loginWithTwoFactorCommand!: LoginWithTwoFactorCommand
        @Inject() notificationDispatcher!: NotificationDispatcher
        @Inject() loginFromAccelerateCommand!: LoginFromAccelerateCommand
        @Inject() updateConsumerCommand!: UpdateConsumerCommand
        @Inject() logoutCommand!: LogoutCommand
        @Inject() clearDomainCommand!: ClearDomainCommand
        @Inject() getProductDefinitionsCommand!: GetProductDefinitionsCommand
        @Inject() setSentryUserCommand!: SetSentryUserCommand
        @Inject() getCurrentLanguageCommand!: GetCurrentLanguageCommand
        @Inject() getCurrentLicenseCommand!: GetCurrentLicenseCommand

        rules = validation
        get form(): VForm {
            return this.$refs.form as VForm
        }
        validate(): boolean {
            return this.form.validate()
        }
        async login() {
            try {
                this.loading = true
                const { twoFactor, twoFactorSetup, consumerId } = await this.loginCommand.run(this.email, this.password)

                // Redirect to dashboard or show 2fa dialog.
                if (twoFactor) {
                    this.showTwoFactor = true
                    this.consumerId = consumerId
                } else {
                    await this.updateConsumerCommand.run()
                    await this.setSentryUserCommand.run(this.consumer)
                    await this.getProductDefinitionsCommand.run()

                    await this.getLicenseData()
                    this.$emit('reloadThemeEvent')

                    if (twoFactorSetup) {
                      await this.$router.push({ name: 'settings' })
                      await this.notificationDispatcher.success(this.$t('messages.youreauthenticated_two_factor_requirement').toString())
                    } else {
                      await this.$router.push({ name: 'dashboard' })
                      await this.notificationDispatcher.success(this.$t('messages.youreauthenticated').toString())
                    }
                }
            } catch (err) {
                await this.logoutCommand.run()
                await this.notificationDispatcher.error(err.message)
            } finally {
                this.loading = false
            }
        }

        async loginWithTwoFactor(otp: string) {
            try {
                this.loading = true
                await this.loginWithTwoFactorCommand.run(this.consumerId!, otp)
                await this.updateConsumerCommand.run()
                await this.setSentryUserCommand.run(this.consumer)
                await this.getProductDefinitionsCommand.run()
                await this.getCurrentLanguage()
                await this.getLicenseData()
                await this.$router.push({ name: 'dashboard' })
                await this.notificationDispatcher.success(this.$t('messages.youreauthenticated').toString())
            } catch (err) {
                await this.logoutCommand.run()
                await this.notificationDispatcher.error(err.message)
            } finally {
                this.loading = false
            }
        }

        async loginFromAccelerate(token: string, refreshToken: string) {
            try {
                await this.loginFromAccelerateCommand.run(token, refreshToken)
                await this.updateConsumerCommand.run()
                await this.setSentryUserCommand.run(this.consumer)
                await this.getProductDefinitionsCommand.run()
                await this.getCurrentLanguage()
                await this.getLicenseData()
                await this.$router.push({ name: 'dashboard' })
            } catch (err) {
                await this.logoutCommand.run()
                await this.notificationDispatcher.error(err.message)
            } finally {
                this.loggingFromAccelerate = false
            }
        }

        async clearDomain() {
            await this.clearDomainCommand.run()
        }

        mounted() {
            const accessToken = this.$route.query?.accessToken as string || ''
            const refreshToken = this.$route.query?.refreshToken as string || ''

            // Auto-login if there's an accessToken available.
            if (accessToken) {
                this.loggingFromAccelerate = true
                this.loginFromAccelerate(accessToken, refreshToken)
            }
        }

        async getCurrentLanguage() {
            const language = await this.getCurrentLanguageCommand.run()
            this.$i18n.locale = language.isoCode
            this.$vuetify.lang.current = language.isoCode
            moment.locale(language.isoCode)
        }

        async getLicenseData() {
            await this.getCurrentLicenseCommand.run()
        }

    }
