import { Command } from '@/app/common/base/command'
import { Consumer } from '@/app/identity'

export class UpdateConsumerCommand extends Command {
    public async run() {
        const response = await this.client.get('/api/consumers')
        const consumer = response.asEntity(Consumer)

        await this.store.dispatch('auth/updateConsumer', consumer)
    }
}
