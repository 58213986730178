import { ProductAction } from '@/app/product/entities/product-action.entity'
import { ProductDefinition } from '@/app/product/entities/product-definition.entity'

export interface ProductState {
    productActions: ProductAction[]
    definitions?: ProductDefinition
}

export const state: ProductState = {
    productActions: [],
    definitions: undefined
}
