import { Entity } from '@/app/common/base/entity'
import { Expose, Type } from 'class-transformer'
import { ProductActionCounter } from '@/app/product/entities/product-action-counter.entity'

export class BusinessIncome extends Entity<BusinessIncome> {

    @Expose()
    id!: string

    @Expose()
    label!: string

    @Expose()
    name!: string

    @Expose()
    supplier!: string

    @Expose()
    lender!: string

    @Expose()
    state!: string

    @Expose()
    created!: string

    @Expose({ name: 'action_counter' })
    @Type(() => ProductActionCounter)
    actionCounter!: ProductActionCounter
}
