import { Command } from '@/app/common/base/command'
import { MultipleDomains } from '@/app/domain-chooser/entities/multiple-domains.entity'

export class GetMultipleDomainsCommand extends Command {
    public async run(): Promise<boolean> {
        const response = await this.client.get('/api/multiple-domains')
        const multipleDomains = response.asEntity(MultipleDomains)

        return multipleDomains.enabled
    }
}
