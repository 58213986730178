import { AxiosClient } from '@/app/common/services/http/axios-client.service'
import { NotificationDispatcher } from '@/app/common/services/notification-dispatcher.service'
import {
    loginCommand,
    productActionLoginCommand,
    loginFromAccelerateCommand,
    loginWithTwoFactorCommand,
    forgotCommand,
    logoutCommand,
    resetPasswordCommand,
    confirmResetTokenCommand,
    updateConsumerCommand,
    setSentryUserCommand
} from '@/app/auth'
import {
    downloadProductActionFileCommand,
    downloadDownloadableFileCommand,
    downloadQuoteAttachmentCommand,
    getAllActionsCommand,
    updateDateConditionPrecedentCommand,
    uploadDocumentCommand,
    getProductDefinitionsCommand,
    respondDocumentApproval
} from '@/app/product'
import { getAllCreditsCommand } from '@/app/credit'
import { loadQrCommand, confirmTwoFactorCommand, disableTwoFactorCommand } from '@/app/settings'
import { downloadImportantDocumentCommand, getImportantDocumentsCommand } from '@/app/important-documents'
import { editMarketValueCommand, getAllMortgagesCommand,
    updateMortgageDetailsCommand,
    updatePresentOpportunityToConsumerCommand
} from '@/app/mortgage'
import { getAllBankguaranteesCommand, downloadBankguaranteeDocumentCommand, submitBnpChoiceCommand } from '@/app/bankguarantee'
import { getAllBusinessIncomesCommand } from '@/app/business-income'
import { getAllLifeInsurancesCommand } from '@/app/life-insurance'
import { getAllFgvsCommand } from '@/app/fgv'
import { getAllAppointmentsCommand, cancelAppointmentCommand } from '@/app/appointment'
import { getAllGeneralInsurancesCommand, createDamageReportCommand } from '@/app/general-insurance'
import { getDossierDataCommand, createDossierCommand } from '@/app/dossier'
import {
    getCurrentLicenseCommand,
    getLicensesCommand,
    setDomainCommand,
    clearDomainCommand,
    getLanguagesCommand,
    getCurrentLanguageCommand
} from '@/app/license'
import { getAllProductPhasesCommand, getAllIncomeTypesCommand } from '@/app/api-entity'
import {
    getAllCardifAosCommand,
    getCardifAoPremiumCommand,
    acceptCardifPremiumCommand,
    declineCardifPremiumCommand
} from '@/app/cardifao'
import { getAllOtdsCommand, downloadOtdCommand, signOtdCommand } from '@/app/otd'
import { getMaintenanceCommand } from '@/app/maintenance'
import { submitKnowledgeFormCommand } from '@/app/knowledge-form'
import { submitHypactProfileCommand} from "@/app/hypact-profile";
import { getMultipleDomainsCommand } from '@/app/domain-chooser'
import {
    submitInventoryFormCommand,
    getPartnerDataCommand,
    getInventoryFormRequirementsCommand,
    submitIwizeInventoryFormCommand,
    getOcktoFormStateCommand,
    submitOcktoTestDataCommand,
    renewQrCodeCommand
} from '@/app/inventory-form'
import { getAllPurchaseIntentionsCommand } from '@/app/purchase-intention'
import { getNotificationsCommand } from '@/app/notifications'
import {
    getAllInvoicesCommand,
    downloadInvoiceCommand,
    getInvoicePaymentCommand,
    validateInvoicePaymentCommand
} from '@/app/invoice'
import { getThemeCommand } from '@/app/theme'
import { getDictionaryCommand } from '@/app/dictionary'
import { getBannerCommand } from '@/app/banner'
import { getAllWillWritingAdvicesCommand } from '@/app/will_writing_advice'
import { getAllWoongarantInsurancesCommand } from "@/app/woongarant-insurance"
import {
    mortgageDataNoLoginCommand,
    submitMortgageCheckCommand,
    consumerDataNoLoginCommand,
    submitMortgageCheckNoLoginCommand,
} from "@/app/mortgage-check"

export default {
    httpClient: new AxiosClient(),
    notificationDispatcher: new NotificationDispatcher(),

    loginCommand,
    productActionLoginCommand,
    loginFromAccelerateCommand,
    loginWithTwoFactorCommand,
    forgotCommand,
    logoutCommand,
    resetPasswordCommand,
    confirmResetTokenCommand,
    updateConsumerCommand,
    loadQrCommand,
    confirmTwoFactorCommand,
    disableTwoFactorCommand,
    getAllActionsCommand,
    getAllMortgagesCommand,
    getAllCreditsCommand,
    getAllBankguaranteesCommand,
    getAllBusinessIncomesCommand,
    getAllWillWritingAdvicesCommand,
    downloadBankguaranteeDocumentCommand,
    getImportantDocumentsCommand,
    downloadImportantDocumentCommand,
    downloadQuoteAttachmentCommand,
    downloadDownloadableFileCommand,
    updateMortgageDetailsCommand,
    updatePresentOpportunityToConsumerCommand,
    downloadProductActionFileCommand,
    getAllAppointmentsCommand,
    cancelAppointmentCommand,
    getAllLifeInsurancesCommand,
    getAllFgvsCommand,
    getAllGeneralInsurancesCommand,
    getDossierDataCommand,
    getCurrentLicenseCommand,
    getAllProductPhasesCommand,
    getAllIncomeTypesCommand,
    createDossierCommand,
    updateDateConditionPrecedentCommand,
    submitBnpChoiceCommand,
    getAllCardifAosCommand,
    getCardifAoPremiumCommand,
    acceptCardifPremiumCommand,
    declineCardifPremiumCommand,
    getAllOtdsCommand,
    downloadOtdCommand,
    signOtdCommand,
    getMultipleDomainsCommand,
    getLicensesCommand,
    getMaintenanceCommand,
    uploadDocumentCommand,
    setDomainCommand,
    clearDomainCommand,
    submitKnowledgeFormCommand,
    submitHypactProfileCommand,
    getProductDefinitionsCommand,
    createDamageReportCommand,
    setSentryUserCommand,
    submitInventoryFormCommand,
    getPartnerDataCommand,
    getInventoryFormRequirementsCommand,
    submitIwizeInventoryFormCommand,
    getOcktoFormStateCommand,
    submitOcktoTestDataCommand,
    renewQrCodeCommand,
    getLanguagesCommand,
    getAllPurchaseIntentionsCommand,
    respondDocumentApproval,
    getNotificationsCommand,
    getAllInvoicesCommand,
    downloadInvoiceCommand,
    getInvoicePaymentCommand,
    validateInvoicePaymentCommand,
    getCurrentLanguageCommand,
    getThemeCommand,
    getDictionaryCommand,
    getBannerCommand,
    editMarketValueCommand,
    submitMortgageCheckCommand,
    getAllWoongarantInsurancesCommand,
    mortgageDataNoLoginCommand,
    consumerDataNoLoginCommand,
    submitMortgageCheckNoLoginCommand
}
