import { Expose } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'

export class PurchaseIntentionTimeline extends Entity<PurchaseIntentionTimeline> {
    @Expose()
    label!: string

    @Expose()
    completed!: boolean

    @Expose()
    active!: boolean
}
