
    import { Component, Vue, Prop } from 'vue-property-decorator'
    import { Credit } from '@/app/credit/entities/credit.entity'
    import SimpleTable from '@/app/common/components/simple-table.vue'

    @Component({
        components: { SimpleTable }
    })
    export default class CreditOverview extends Vue {
        @Prop({ type: Credit, required: true }) credit!: Credit
    }
