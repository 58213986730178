
    import { Component, Inject, Vue } from 'vue-property-decorator'
    import { mapGetters } from 'vuex'
    import Layout from '@/app/common/components/layout/layout.vue'
    import SimpleTable from '@/app/common/components/simple-table.vue'
    import { ProductAction } from '@/app/product/entities/product-action.entity'
    import { GetAllActionsCommand } from '@/app/product/commands/get-all-actions.command'
    import { NotificationDispatcher } from '@/app/common/services/notification-dispatcher.service'
    import { ValidateInvoicePaymentCommand } from '@/app/invoice/commands/validate-invoice-payment.command'

    @Component({
        components: { Layout, SimpleTable },
        metaInfo() {
            return {
                title: 'Validate payment'
            }
        },
        computed: {
            ...mapGetters('product', ['getByProductActionId'])
        }
    })
    export default class ValidateInvoicePayment extends Vue {
        productAction?: ProductAction | null = null
        getByProductActionId!: (id: string) => ProductAction | undefined

        @Inject() getAllActionsCommand!: GetAllActionsCommand
        @Inject() validateInvoicePaymentCommand!: ValidateInvoicePaymentCommand
        @Inject() notificationDispatcher!: NotificationDispatcher

        async mounted() {
            await this.getAllActionsCommand.run()
            const id = this.$route.params.action
            this.productAction = this.getByProductActionId(id) ?? null
            await this.validatePayment()
        }

        async validatePayment() {
            try {
                const status = await this.validateInvoicePaymentCommand.run({
                    productId: this.productAction!.product.id,
                    productActionId: this.productAction!.id,
                    invoiceId: this.productAction!.invoice.id
                })

                if (status.isPaid()) {
                    await this.notificationDispatcher.success(this.$t('page.ideal_payment.notifications.thankyou.body').toString())
                    await this.$router.push({ name: 'dashboard' })
                } else if (status.isCancelled()) {
                    await this.notificationDispatcher.error(this.$t('page.ideal_payment.notifications.payment_canceled.body').toString())
                    await this.$router.push({ name: 'invoice-payment', params: { action: this.productAction!.id } })
                } else if ([status.options.pending, status.options.created].includes(status.status)) {
                    await this.notificationDispatcher.error(this.$t('page.ideal_payment.notifications.payment_pending.body').toString())
                    await this.$router.push({ name: 'dashboard' })
                } else if ([status.options.failed, status.options.expired].includes(status.status)) {
                    await this.notificationDispatcher.error(this.$t('page.ideal_payment.notifications.payment_failed.body').toString())
                    await this.$router.push({ name: 'invoice-payment' })
                } else {
                    await this.notificationDispatcher.error(this.$t('page.ideal_payment.notifications.payment_unknown_status.body').toString())
                    await this.$router.push({ name: 'invoice-payment', params: { action: this.productAction!.id } })
                }
            } catch (err) {
                await this.notificationDispatcher.error(err.message)
            }
        }
    }
