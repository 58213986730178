import { MutationTree } from 'vuex'
import { GeneralInsuranceState } from '@/app/general-insurance/store/state'
import { GeneralInsurance } from '@/app/general-insurance/entities/general-insurance.entity'

export const mutations: MutationTree<GeneralInsuranceState> = {

    setGeneralInsurances(state: GeneralInsuranceState, generalInsurances: GeneralInsurance[]) {
        state.generalInsurances = generalInsurances
    }

}
