import { Expose } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'

export class DownloadableDocument extends Entity<DownloadableDocument> {
    @Expose()
    name!: string

    @Expose()
    description!: string

}
