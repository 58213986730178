import { Entity } from "@/app/common/base/entity"
import { Expose } from "class-transformer"

export class LicenseSmartcare extends Entity<LicenseSmartcare> {
    @Expose({ 'name': 'mortgage_check_enable_question_1b' })
    question1BEnabled!: boolean

    @Expose({ 'name': 'mortgage_check_enable_question_7b_c' })
    question7BCEnabled!: boolean

    @Expose({ 'name': 'mortgage_check_enable_question_11' })
    question11Enabled!: boolean
}
