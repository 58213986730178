import { Document } from '@/app/important-documents/entities/document.entity'

export interface ImportantDocumentState {
    documents: Document[]
    documentsByCategory: object
}

export const state: ImportantDocumentState = {
    documents: [],
    documentsByCategory: {}
}
