import { Command } from '@/app/common/base/command'
import { Language } from '@/app/license/entities/language.entity'

export class GetCurrentLanguageCommand extends Command {
    public async run(): Promise<Language> {
        const response = await this.client.get('/api/languages/current')
        const language = response.asEntity(Language)

        this.store.dispatch('global/setLocale', language.isoCode)

        return language
    }
}
