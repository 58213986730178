import { Expose } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'

export class CardifAoPremium extends Entity<CardifAoPremium> {
    @Expose()
    premium!: number

    @Expose()
    disability!: number
}
