
    import { Component, Inject, Vue } from 'vue-property-decorator'
    import { mapGetters } from 'vuex'
    import Layout from '../../common/components/layout/layout.vue'
    import { ProductAction } from '@/app/product/entities/product-action.entity'
    import { GetAllActionsCommand } from '@/app/product/commands/get-all-actions.command'
    import SimpleTable from '@/app/common/components/simple-table.vue'
    import validation from "@/app/common/helpers/validation"
    import { NotificationDispatcher } from "@/app/common/services/notification-dispatcher.service"
    import { RespondDocumentApprovalCommand } from "@/app/product/commands/respond-document-approval.command"
    import { DownloadProductActionFileCommand } from '@/app/product/commands/download-product-action-file.command'
    import localizeContent from '@/app/common/helpers/localize-content'

    @Component({
        components: { Layout, SimpleTable },
        metaInfo() {
            return {
                title: 'Document'
            }
        },
        computed: {
            ...mapGetters('product', ['getByProductActionId'])
        }
    })
    export default class ApproveDocument extends Vue {
        answer = ''
        explanation = ''
        answerTypes = {
            yes: 'yes',
            no: 'no',
            noQuestion: 'no_question'
        }
        submitLoading = false
        rules = validation
        productAction?: ProductAction | null = null
        getByProductActionId!: (id: string) => ProductAction | undefined
        localizeContent = localizeContent

        @Inject() getAllActionsCommand!: GetAllActionsCommand
        @Inject() downloadProductActionFileCommand!: DownloadProductActionFileCommand
        @Inject() respondDocumentApproval!: RespondDocumentApprovalCommand
        @Inject() notificationDispatcher!: NotificationDispatcher

        async mounted() {
            await this.getAllActionsCommand.run()
            const id = this.$route.params.action
            this.productAction = this.getByProductActionId(id) ?? null
        }

        async download() {
            try {
                await this.downloadProductActionFileCommand.run(this.productAction!.id)
            } catch (err) {
                await this.notificationDispatcher.error(err.message)
            }
        }

        async submit() {
            try {
                this.submitLoading = true
                await this.respondDocumentApproval.run(this.productAction!.id, this.answer, this.explanation)
                await this.notificationDispatcher.success(this.$t('pages.do_action.approvedoc.success').toString())
                await this.$router.push({ name: 'dashboard' })
            } catch (err) {
                await this.notificationDispatcher.error(err.message)
            } finally {
                this.submitLoading = false
            }
        }
    }
