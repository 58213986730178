
    import { Component, Prop, Vue } from 'vue-property-decorator'
    import SimpleTable from '@/app/common/components/simple-table.vue'
    import { mapState } from 'vuex'
    import { Consumer } from '@/app/identity'
    import { WillWritingAdvice } from '@/app/will_writing_advice/entities/will-writing-advice.entity'

    @Component({
        components: { SimpleTable },
        computed: {
            ...mapState('auth', ['consumer'])
        }
    })
    export default class WillWritingAdviceOverview extends Vue {
        @Prop({ type: WillWritingAdvice, required: true }) willWritingAdvice!: WillWritingAdvice

        consumer!: Consumer
    }
