import { ActionTree, Commit } from 'vuex'
import { RootState } from '@/app/common/types/root-state.type'
import { FgvState } from '@/app/fgv/store/state'
import { Fgv } from '@/app/fgv/entities/fgv.entity'

export const actions: ActionTree<FgvState, RootState> = {

    setFgvs({ commit }: { commit: Commit }, fgvs: Fgv[]) {
        commit('setFgvs', fgvs)
    }

}
