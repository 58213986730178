import { Expose, Type } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'
import { ProductActionQuoteAttachment } from '@/app/product/entities/product-action-quote-attachment.entity'

export class ProductActionQuote extends Entity<ProductActionQuote> {
    @Expose()
    id!: string
    @Expose({ name: 'date_received' })
    dateReceived?: string
    @Expose({ name: 'date_acceptation' })
    dateAcceptation?: string
    @Expose({ name: 'date_validity' })
    dateValidity?: string
    @Expose()
    property?: string
    @Expose()
    @Type(() => ProductActionQuoteAttachment)
    attachments!: ProductActionQuoteAttachment[]
}
