import { MutationTree } from 'vuex'
import { FgvState } from '@/app/fgv/store/state'
import { Fgv } from '@/app/fgv/entities/fgv.entity'

export const mutations: MutationTree<FgvState> = {

    setFgvs(state: FgvState, fgvs: Fgv[]) {
        state.fgvs = fgvs
    }

}
