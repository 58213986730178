import { Expose } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'

export class DomainLicense extends Entity<DomainLicense> {
    @Expose()
    name!: string

    @Expose()
    domain!: string

}
