
    import { mapGetters, mapState } from 'vuex'
    import { Component, Vue, Inject } from 'vue-property-decorator'
    import Layout from '../../common/components/layout/layout.vue'
    import AppointmentOverview from '../components/appointment-overview.vue'
    import DeleteConfirmation from '@/app/common/components/delete-confirmation.vue'
    import { Appointment as AppointmentEntity } from '@/app/appointment/entities/appointment.entity'
    import { GetAllAppointmentsCommand } from '@/app/appointment/commands/get-all-appointments.command'
    import { CancelAppointmentCommand } from '@/app/appointment/commands/cancel-appointment.command'
    import { NotificationDispatcher } from '../../common/services/notification-dispatcher.service'
    import { License } from '@/app/license/entities/license.entity'
    import ProductLayout from '@/app/product/components/product-layout.vue'
    import {
        GetProductDefinitionsCommand
    } from '@/app/product/commands/get-product-definitions.command'

    @Component({
        components: { DeleteConfirmation, Layout, AppointmentOverview, ProductLayout },
        metaInfo() {
            return {
                title: 'Appointment'
            }
        },
        computed: {
            ...mapGetters('appointment', ['getByAppointmentId']),
            ...mapState('appointment', ['appointments']),
            ...mapState('license', ['license'])
        }
    })
    export default class Appointment extends Vue {
        cancelConfirmation = false
        appointment: AppointmentEntity | null = null
        appointments!: AppointmentEntity[]
        license!: License
        loading = false
        getByAppointmentId!: (id: string) => AppointmentEntity | undefined

        @Inject() getAllAppointmentsCommand!: GetAllAppointmentsCommand
        @Inject() cancelAppointmentCommand!: CancelAppointmentCommand
        @Inject() notificationDispatcher!: NotificationDispatcher
        @Inject() getProductDefinitionsCommand!: GetProductDefinitionsCommand

        async mounted() {
            await this.getAllAppointments()

            const id = this.$route.params.id
            this.appointment = this.getByAppointmentId(id) ?? null
        }

        async getAllAppointments() {
            // Fetch the appointments if needed. This won't happen
            // if they navigate from the appointment list page, but will
            // happen if they visit this page directly.
            if (!this.appointments.length) {
                await this.getAllAppointmentsCommand.run()
            }
        }

        async cancelAppointment() {
            try {
                this.loading = true
                await this.cancelAppointmentCommand.run(this.appointment!.id)
                await this.notificationDispatcher.success(this.$t('page.appointment.cancel_success').toString())
                await this.getProductDefinitionsCommand.run()
                await this.$router.push({ name: 'dashboard' })
            } catch (err) {
                await this.notificationDispatcher.error(err.message)
            } finally {
                this.loading = false
            }
        }
    }
