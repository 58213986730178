import { GetterTree } from 'vuex'
import { RootState } from '@/app/common/types/root-state.type'
import { WoongarantInsuranceState } from "@/app/woongarant-insurance/store/state"
import { WoongarantInsurance } from "@/app/woongarant-insurance/entities/woongarant-insurance.entity"

export const getters: GetterTree<WoongarantInsuranceState, RootState> = {

    getByWoongarantInsuranceId(state: WoongarantInsuranceState): (id: string) => WoongarantInsurance | undefined {
        return (id: string) => {
            const woongarantInsurances = state.woongarantInsurances.filter(m => m.id === id)
            return woongarantInsurances?.[0]
        }
    }

}
