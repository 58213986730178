
    import Vue from 'vue'
    import Component from 'vue-class-component'
    import SimpleTable from '@/app/common/components/simple-table.vue'
    import { Prop } from 'vue-property-decorator'
    import { BusinessIncome } from '@/app/business-income/entities/business-income.entity'

    @Component({
        components: { SimpleTable }
    })
    export default class BusinessIncomeOverview extends Vue {
        @Prop({ type: BusinessIncome, required: true }) businessIncome!: BusinessIncome
    }
