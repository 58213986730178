import { Command } from '@/app/common/base/command'
import { Appointment } from '@/app/appointment/entities/appointment.entity'

export class GetAllAppointmentsCommand extends Command {
    public async run() {
        const response = await this.client.get('/api/products/appointments')
        const appointments = response.asEntities(Appointment)

        await this.store.dispatch('appointment/setAppointments', appointments)
    }
}
