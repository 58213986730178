import { Command } from '@/app/common/base/command'
import { CardifAoPremium } from '@/app/cardifao/entities/cardifao-premium'

export class GetCardifAoPremiumCommand extends Command {

    public async run(actionId: string): Promise<CardifAoPremium> {
        const response = await this.client.get(`/api/products/cardifao/actions/${actionId}/premium`)
        return response.asEntity(CardifAoPremium)
    }

}
