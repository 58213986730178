import { MutationTree } from 'vuex'
import { LicenseState } from '@/app/license/store/state'
import { License } from '@/app/license/entities/license.entity'
import { Language } from '@/app/license/entities/language.entity'

export const mutations: MutationTree<LicenseState> = {

    setLicense(state: LicenseState, license: License) {
        state.license = license
    },

    setDomain(state: LicenseState, domain?: string) {
        state.domain = domain
    },

    setLanguages(state: LicenseState, languages: Language[]) {
        state.languages = languages
    },

}
