import { ActionTree, Commit } from 'vuex'
import { RootState } from '@/app/common/types/root-state.type'
import { LifeInsuranceState } from '@/app/life-insurance/store/state'
import { LifeInsurance } from '@/app/life-insurance/entities/life-insurance.entity'

export const actions: ActionTree<LifeInsuranceState, RootState> = {

    setLifeInsurances({ commit }: { commit: Commit }, lifeInsurances: LifeInsurance[]) {
        commit('setLifeInsurances', lifeInsurances)
    }

}
