
    import { mapState } from 'vuex'
    import { Component, Vue, Inject } from 'vue-property-decorator'
    import Layout from '../../common/components/layout/layout.vue'
    import { Fgv } from '@/app/fgv/entities/fgv.entity'
    import { GetAllFgvsCommand } from '@/app/fgv/commands/get-all-fgvs.command'

    @Component({
        components: { Layout },
        metaInfo() {
            return {
                title: 'FGVs'
            }
        },
        computed: {
            ...mapState('fgv', ['fgvs'])
        }
    })
    export default class FgvList extends Vue {
        fgvs!: Fgv[]

        @Inject() getAllFgvsCommand!: GetAllFgvsCommand

        mounted() {
            this.getAllFgvsCommand.run()
        }
    }
