import { Expose } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'

export class LicenseContent extends Entity<LicenseContent> {
    @Expose({ 'name': 'content_label_incometype_field' })
    incomeTypeFieldLabel!: boolean

    @Expose({ 'name': 'content_label_productphase_field' })
    productPhaseFieldLabel!: boolean

    @Expose({ 'name': 'lead_source' })
    leadSource!: boolean

    @Expose({ 'name': 'cardif_ao_introduction' })
    cardifAoIntroduction!: string

    @Expose({ 'name': 'cardif_ao_action_body' })
    cardifAoActionBody!: string

    @Expose({ 'name': 'cardif_ao_approval_body' })
    cardifAoApprovalBody!: string

    @Expose({ 'name': 'cardif_ao_button_approve' })
    cardifAoButtonApprove!: string

    @Expose({ 'name': 'cardif_ao_button_decline' })
    cardifAoButtonDecline!: string

    @Expose({ 'name': 'welcome_title' })
    welcomeTitle!: string

    @Expose({ 'name': 'welcome_content' })
    welcomeContent!: string

    @Expose({ 'name': 'importantdocs_title' })
    importantDocsTitle!: string

    @Expose({ 'name': 'importantdocs_content' })
    importantDocsContent!: string

    @Expose({ 'name': 'footer_content' })
    footerContent!: string

    @Expose({ 'name': 'image_header' })
    headerImage!: string

    @Expose({ 'name': 'image_header_full_width' })
    isHeaderImageFullWidth!: boolean

    @Expose({ 'name': 'image_logo' })
    imageLogo!: string

    @Expose({ 'name': 'label_advisor' })
    labelAdvisor!: string

    @Expose({ 'name': 'label_dossier_owner' })
    labelDossierOwner!: string
    
    @Expose({ 'name': 'otd_conditions_title' })
    otdConditionsTitle!: string

    @Expose({ 'name': 'otd_conditions_content' })
    otdConditionsContent!: string

    @Expose({ 'name': 'mortgage_check_title' })
    mortgageCheckTitle!: string

    @Expose({ 'name': 'mortgage_check_introduction_actionpoint' })
    mortgageCheckIntroductionActionPoint!: string

    @Expose({ 'name': 'mortgage_check_introduction_situation_family_income' })
    mortgageCheckIntroductionSituationFamilyIncome!: string

    @Expose({ 'name': 'mortgage_check_introduction_situation_residence' })
    mortgageCheckIntroductionSituationResidence!: string

    @Expose({ 'name': 'mortgage_check_introduction_situation_mortgage' })
    mortgageCheckIntroductionSituationMortgage!: string

    @Expose({ 'name': 'mortgage_check_introduction_situation_questions' })
    mortgageCheckIntroductionSituationQuestions!: string

    @Expose({ 'name': 'mortgage_check_outcome_advice_necessary' })
    mortgageCheckOutcomeAdviceNecessary!: string

    @Expose({ 'name': 'mortgage_check_outcome_advice_desirable' })
    mortgageCheckOutcomeAdviceDesirable!: string

    @Expose({ 'name': 'mortgage_check_outcome_advice_not_needed' })
    mortgageCheckOutcomeAdviceNotNeeded!: string

}
