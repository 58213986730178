import { Expose } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'

export class InsuredConsumerData extends Entity<InsuredConsumerData> {
    @Expose()
    capital!: number

    @Expose()
    duration!: number

    @Expose({ name: 'insurance_type' })
    insuranceType!: string
}
