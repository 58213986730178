import { Expose } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'

export class Maintenance extends Entity<Maintenance> {
    @Expose({ name: 'maintenance' })
    enabled!: boolean

    @Expose({ name: 'show_notice' })
    showNotice!: boolean

    @Expose()
    notice!: string
}
