import { MutationTree } from 'vuex'
import { InvoiceState } from '@/app/invoice/store/state'
import { Invoice } from '@/app/invoice/entities/invoice.entity'

export const mutations: MutationTree<InvoiceState> = {

    setInvoices(state: InvoiceState, invoices: Invoice[]) {
        state.invoices = invoices
    }

}
