var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.showClosedActionPoints || !_vm.productAction.isDone)?_c('v-card',{attrs:{"elevation":"0"}},[_c('v-row',[_c('v-col',{staticClass:"pl-7 pr-7",attrs:{"cols":"1"}},[(_vm.productAction.isDone)?_c('v-icon',{attrs:{"color":"primary","size":"40"}},[_vm._v("fal fa-check-square")]):_c('v-icon',{attrs:{"color":"primary","size":"40"}},[_vm._v("fal fa-square")])],1),_c('v-col',[_c('v-card-title',{staticClass:"pt-1"},[_vm._v(" "+_vm._s(_vm.productActionText.title || _vm.productAction.label)+" ")]),_c('v-card-text',[(_vm.uploadTypes.includes(_vm.productAction.type))?_c('p',[(
                            _vm.productAction.document &&
                            _vm.productAction.document.description
                        )?[_c('b',[_vm._v(_vm._s(_vm.$t('pages.do_action.uploaddoc.description'))+": ")]),_vm._v(_vm._s(_vm.productAction.document.description)),_c('br')]:_vm._e(),(_vm.productAction.comment)?[(_vm.productAction.type === _vm.uploadType.reuploadDocumentManagementFile)?_c('b',[(!_vm.productAction.isDone)?_c('span',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.$t('productaction.UPLOAD_DOCUMENTMANAGEMENT_FILE.rejection'))+" ")]):_vm._e()]):_c('b',[_vm._v(_vm._s(_vm.$t('pages.do_action.uploaddoc.remarks'))+": ")]),(!_vm.productAction.isDone)?_c('span',[_vm._v(_vm._s(_vm.productAction.comment))]):_vm._e(),_c('br')]:_vm._e()],2):_c('p',[(_vm.consumerIsFormal)?[(_vm.productActionText.htmlDescription)?[_c('span',{domProps:{"innerHTML":_vm._s(_vm.productActionText.formal)}})]:[_vm._v(" "+_vm._s(_vm.productActionText.formal)+" ")]]:[(_vm.productActionText.htmlDescription)?[_c('span',{domProps:{"innerHTML":_vm._s(_vm.productActionText.informal)}})]:[_vm._v(" "+_vm._s(_vm.productActionText.informal)+" ")]],(
                            _vm.productAction.downloadableDocument &&
                            _vm.productAction.downloadableDocument.description &&
                            _vm.productAction.downloadableDocument.description !== _vm.productAction.comment
                        )?[_c('b',[_vm._v(_vm._s(_vm.$t('pages.do_action.reuploaddoc.description'))+": ")]),_vm._v(_vm._s(_vm.productAction.downloadableDocument.description)),_c('br')]:_vm._e()],2),(
                        _vm.productAction.document &&
                        _vm.productAction.document.hasUploadedRequirements
                    )?_c('div',{staticClass:"my-3"},[_c('b',[_vm._v(_vm._s(_vm.$t('pages.do_action.uploaddoc.guidelines'))+":")]),_c('br'),_c('ul',_vm._l((_vm.productAction.document.uploadRequirementsAsList),function(requirement,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(requirement)+" ")])}),0)]):_vm._e(),(!_vm.uploadTypes.includes(_vm.productAction.type) && _vm.productActionText && _vm.productActionText.comment)?_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('pages.do_action.approvedoc.remark'))+": ")]),_vm._v(_vm._s(_vm.productActionText.comment)+" ")]):_vm._e()]),(_vm.productActionText && !_vm.productAction.isDone)?_c('v-card-actions',[_c('v-spacer'),(_vm.productActionText.download)?_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.downloadAction()}}},[_vm._v(_vm._s(_vm.productActionText.button))]):[(_vm.product)?_c('v-btn',{staticClass:"mb-3",attrs:{"color":"primary","to":{ name: _vm.productActionText.to, params: { id: _vm.product.id, action: _vm.productAction.id } }}},[_vm._v(" "+_vm._s(_vm.productActionText.button)+" ")]):_c('v-btn',{staticClass:"mb-3",attrs:{"color":"primary","to":{ name: _vm.productActionText.to, params: { action: _vm.productAction.id } }}},[_vm._v(" "+_vm._s(_vm.productActionText.button)+" ")])]],2):(_vm.productActionText && _vm.productAction.isDone && _vm.productActionText.showButtonWhenClosed)?_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.downloadAction()}}},[_vm._v(_vm._s(_vm.productActionText.closedButton || _vm.productActionText.button))])],1):_vm._e()],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }