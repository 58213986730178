import { GetterTree } from 'vuex'
import { BusinessIncomeState } from '@/app/business-income/store/state'
import { RootState } from '@/app/common/types/root-state.type'
import { BusinessIncome } from '@/app/business-income/entities/business-income.entity'

export const getters: GetterTree<BusinessIncomeState, RootState> = {

    getByBusinessIncomeId(state: BusinessIncomeState): (id: string) => BusinessIncome | undefined {
        return (id: string) => {
            const businessIncomes = state.businessIncomes.filter(inc => inc.id === id)
            return businessIncomes?.[0]
        }
    }

}
