import { MutationTree } from 'vuex'
import { WillWritingAdviceState } from '@/app/will_writing_advice/store/state'
import { WillWritingAdvice } from '@/app/will_writing_advice/entities/will-writing-advice.entity'

export const mutations: MutationTree<WillWritingAdviceState> = {

    setWillWritingAdvices(state: WillWritingAdviceState, willWritingAdvices: WillWritingAdvice[]) {
        state.willWritingAdvices = willWritingAdvices
    }

}
