import { Expose, Type } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'
import { ProductActionCounter } from '@/app/product/entities/product-action-counter.entity'

export class Bankguarantee extends Entity<Bankguarantee> {
    @Expose()
    id!: string

    @Expose()
    label!: string

    @Expose()
    company!: string

    @Expose({ name: 'is_BNP' })
    isBNP!: boolean

    @Expose()
    cost!: number

    @Expose()
    amount!: number

    @Expose({ name: 'one_off_additional_orv_cost' })
    oneOffAdditionalOrvCost!: number

    @Expose()
    premium!: number

    @Expose()
    arrangement!: string

    @Expose({ name: 'purchase_price' })
    purchasePrice!: number

    @Expose({ name: 'date_of_issue' })
    dateOfIssue!: string

    @Expose({ name: 'date_ultimate' })
    dateUltimate!: string

    @Expose({ name: 'date_bankguarantee' })
    dateBankguarantee!: string

    @Expose({ name: 'purchase_agreement_date' })
    purchaseAgreementDate!: string

    @Expose({ name: 'action_counter' })
    @Type(() => ProductActionCounter)
    actionCounter!: ProductActionCounter
}
