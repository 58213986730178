import { Entity } from "@/app/common/base/entity"
import { Expose } from "class-transformer"

export class ProductActionAuthEntity extends Entity<ProductActionAuthEntity> {
    @Expose()
    id!: string

    @Expose()
    token!: string

    @Expose()
    code!: string|null

    @Expose({ name: 'locked_time_remaining' })
    lockedTimeRemaining!: number|null
}
