import { Expose } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'

export class LoanPart extends Entity<LoanPart> {
    @Expose()
    id!: number

    @Expose()
    interest!: number

    @Expose()
    type!: string

    @Expose()
    amount!: number

    @Expose()
    date!: string

    @Expose({ name: 'start_of_fixed_rate_period' })
    startOfFixedRatePeriod!: string

    @Expose({ name: 'end_of_fixed_rate_period' })
    endOfFixedRatePeriod!: string

    @Expose({ name: 'fixed_interest_period' })
    fixedInterestPeriod!: string

    @Expose({ name: 'end_of_loan_period' })
    endOfLoanPeriod!: string
}
