import { Command } from '@/app/common/base/command'
import { ProductDefinition } from '@/app/product/entities/product-definition.entity'

export class GetProductDefinitionsCommand extends Command {
    public async run() {
        const response = await this.client.get('/api/products/definitions')
        const productDefinitions = response.asEntity(ProductDefinition)

        await this.store.dispatch('product/setDefinitions', productDefinitions)
    }
}
