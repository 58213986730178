
    import { mapState } from 'vuex'
    import { Component, Inject, Vue } from 'vue-property-decorator'
    import Layout from '@/app/common/components/layout/layout.vue'
    import { GeneralInsurance } from '@/app/general-insurance/entities/general-insurance.entity'
    import { GetAllGeneralInsurancesCommand } from '@/app/general-insurance/commands/get-all-general-insurances.command'
    import { WoongarantInsurance } from "@/app/woongarant-insurance/entities/woongarant-insurance.entity"
    import { GetAllWoongarantInsurancesCommand } from "@/app/woongarant-insurance/commands/get-all-woongarant-insurances.command"


    @Component({
        components: { Layout },
        metaInfo() {
            return {
                title: this.$t('page.general_insurance_list.title').toString()
            }
        },
        computed: {
            ...mapState('generalInsurance', ['generalInsurances']),
            ...mapState('woongarantInsurance', ['woongarantInsurances'])
        }
    })
    export default class GeneralInsuranceList extends Vue {
        generalInsurances!: GeneralInsurance[]
        woongarantInsurances!: WoongarantInsurance[]

        @Inject() getAllGeneralInsurancesCommand!: GetAllGeneralInsurancesCommand
        @Inject() getAllWoongarantInsurancesCommand!: GetAllWoongarantInsurancesCommand

        mounted() {
            this.getAllGeneralInsurancesCommand.run()
            this.getAllWoongarantInsurancesCommand.run()
        }
    }
