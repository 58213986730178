
    import { Component, Inject, Vue } from 'vue-property-decorator'
    import Layout from '@/app/common/components/layout/layout.vue'
    import { mapGetters, mapState } from 'vuex'
    import { WillWritingAdvice as WillWritingAdviceEntity } from '@/app/will_writing_advice/entities/will-writing-advice.entity'
    import ProductLayout from '@/app/product/components/product-layout.vue'
    import WillWritingAdviceOverview from '@/app/will_writing_advice/components/will-writing-advice-overview.vue'
    import { GetAllWillWritingAdvicesCommand } from '@/app/will_writing_advice/commands/get-all-will-writing-advices.command'
    import WillWritingAdviceWills from '@/app/will_writing_advice/components/will-writing-advice-wills.vue'

    @Component({
        components: { WillWritingAdviceWills, WillWritingAdviceOverview, ProductLayout, Layout },
        metaInfo() {
            return {
                title: 'Testamenten'
            }
        },
        computed: {
            ...mapState('willWritingAdvice', ['willWritingAdvices']),
            ...mapGetters('willWritingAdvice', ['getByWillWritingAdviceId'])
        }
    })
    export default class WillWritingAdvice extends Vue {
        willWritingAdvices!: WillWritingAdviceEntity[]
        willWritingAdvice: WillWritingAdviceEntity | null = null
        getByWillWritingAdviceId!: (id: string) => WillWritingAdviceEntity | undefined

        @Inject() getAllWillWritingAdvicesCommand!: GetAllWillWritingAdvicesCommand

        async mounted() {
            await this.getAllWillWritingAdvices()

            const id = this.$route.params.id
            this.willWritingAdvice = this.getByWillWritingAdviceId(id) ?? null
        }
        async getAllWillWritingAdvices() {
            if (!this.willWritingAdvices.length) {
                await this.getAllWillWritingAdvicesCommand.run()
            }
        }
    }
