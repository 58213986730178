
    import { mapState } from 'vuex'
    import { Component, Vue, Inject } from 'vue-property-decorator'
    import Layout from '../../common/components/layout/layout.vue'
    import { LifeInsurance } from '@/app/life-insurance/entities/life-insurance.entity'
    import { GetAllLifeInsurancesCommand } from '@/app/life-insurance/commands/get-all-life-insurances.command'

    @Component({
        components: { Layout },
        metaInfo() {
            return {
                title: 'Life Insurances'
            }
        },
        computed: {
            ...mapState('lifeInsurance', ['lifeInsurances'])
        }
    })
    export default class LifeInsuranceList extends Vue {
        lifeInsurances!: LifeInsurance[]

        @Inject() getAllLifeInsurancesCommand!: GetAllLifeInsurancesCommand

        mounted() {
            this.getAllLifeInsurancesCommand.run()
        }
    }
