
    import { mapGetters, mapState } from 'vuex'
    import { Component, Inject, Vue } from 'vue-property-decorator'
    import Layout from '../../common/components/layout/layout.vue'
    import OtdOverview from '@/app/otd/components/otd-overview.vue'
    import { GetAllOtdsCommand } from '@/app/otd/commands/get-all-otds.command'
    import ProductLayout from '@/app/product/components/product-layout.vue'
    import { Otd as OtdEntity } from '@/app/otd/entities/otd.entity'

    @Component({
        components: { Layout, OtdOverview, ProductLayout },
        metaInfo() {
            return {
                title: 'OTD'
            }
        },
        computed: {
            ...mapGetters('otd', ['getByOtdId']),
            ...mapState('otd', ['otds'])
        }
    })
    export default class Otd extends Vue {
        otd: OtdEntity | null = null
        getByOtdId!: any
        otds!: OtdEntity[]

        @Inject() getAllOtdsCommand!: GetAllOtdsCommand

        async mounted() {
            await this.getAllOtds()

            const id = this.$route.params.id
            this.otd = this.getByOtdId(id)
        }

        async getAllOtds() {
            if (!this.otds.length) {
                await this.getAllOtdsCommand.run()
            }
        }
    }
