import { MutationTree } from 'vuex'
import { LifeInsuranceState } from '@/app/life-insurance/store/state'
import { LifeInsurance } from '@/app/life-insurance/entities/life-insurance.entity'

export const mutations: MutationTree<LifeInsuranceState> = {

    setLifeInsurances(state: LifeInsuranceState, lifeInsurances: LifeInsurance[]) {
        state.lifeInsurances = lifeInsurances
    }

}
