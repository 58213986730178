
    import { Component, Inject, Vue, Ref } from 'vue-property-decorator'
    import { mapGetters, mapState } from 'vuex'
    import Layout from '@/app/common/components/layout/layout.vue'
    import SimpleTable from '@/app/common/components/simple-table.vue'
    import { ProductAction } from '@/app/product/entities/product-action.entity'
    import { GetAllActionsCommand } from '@/app/product/commands/get-all-actions.command'
    import validation from '@/app/common/helpers/validation'
    import { Consumer } from '@/app/identity'
    import { NotificationDispatcher } from '@/app/common/services/notification-dispatcher.service'
    import { Woongarant } from '@/app/woongarant-insurance/entities/woongarant.entity'

    @Component({
        components: { Layout, SimpleTable },
        metaInfo() {
            return {
                title: this.$t('woongarant-insurance.pages.sign-proposal').toString()
            }
        },
        computed: {
            ...mapState('product', ['productActions']),
            ...mapGetters('product', ['getByProductActionId']),
            ...mapState('auth', ['consumer'])
        }
    })
    export default class SignProposal extends Vue {
        @Ref('form') form!: { validate: () => boolean }
        woongarant: Woongarant | null = null
        productActions!: ProductAction[]
        getByProductActionId!: (id: string) => ProductAction | undefined
        productAction: ProductAction | null = null
        loading = false
        rules = validation
        consumer!: Consumer

        @Inject() getAllActionsCommand!: GetAllActionsCommand
        @Inject() notificationDispatcher!: NotificationDispatcher

        async mounted() {
            await this.getAllProductActions()
            const productActionId = this.$route.params.action
            this.productAction = this.getByProductActionId(productActionId) ?? null
            this.woongarant = this.productAction?.woongarant ?? null
        }

        async getAllProductActions() {
            if (!this.productActions.length) {
                await this.getAllActionsCommand.run()
            }
        }

        validate(): boolean {
            return this.form.validate()
        }

        async handleSign() {
            if (!this.validate()) {
                return
            }
            try {
                this.loading = true
                // Create a temporary anchor element and simulate a click
                const tempLink = document.createElement('a')
                tempLink.href = this.woongarant!.approvalUrl
                tempLink.target = '_blank'
                tempLink.rel = 'noopener noreferrer'
                document.body.appendChild(tempLink)
                tempLink.click()
                document.body.removeChild(tempLink)

                await this.$router.push({ name: 'dashboard' })
            } catch (err) {
                await this.notificationDispatcher.error(err.message)
            } finally {
                this.loading = false
            }
        }
    }
