import { ActionTree, Commit } from 'vuex'
import { RootState } from '@/app/common/types/root-state.type'
import { AppointmentState } from './state'
import { Appointment } from '@/app/appointment/entities/appointment.entity'

export const actions: ActionTree<AppointmentState, RootState> = {

    setAppointments({ commit }: { commit: Commit }, appointments: Appointment[]) {
        commit('setAppointments', appointments)
    },

    deleteAppointment({ commit }: { commit: Commit }, appointmentId: string) {
        commit('deleteAppointment', appointmentId)
    }

}
