
    import { Component, Prop, Vue } from 'vue-property-decorator'
    import TruemeetingButton from './truemeeting-button.vue'
    import { Appointment } from '@/app/appointment/entities/appointment.entity'
    import SimpleTable from '@/app/common/components/simple-table.vue'
    import { mapState } from 'vuex'
    import { License } from '@/app/license/entities/license.entity'
    import GooglemeetButton from '@/app/appointment/components/googlemeet-button.vue'
    import TeamsButton from '@/app/appointment/components/teams-button.vue'

    @Component({
        components: { TruemeetingButton, SimpleTable, GooglemeetButton, TeamsButton },
        computed: {
            ...mapState('license', ['license'])
        }
    })
    export default class AppointmentOverview extends Vue {
        @Prop({ type: Appointment, required: true }) appointment!: Appointment

        license!: License
    }
