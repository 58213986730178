import { AxiosClient } from "@/app/common/services/http/axios-client.service"
import store from "@/plugins/store"
import { SubmitMortgageCheckCommand } from "@/app/mortgage-check/commands/submit-mortgage-check"
import { MortgageDataNoLoginCommand } from "@/app/mortgage-check/commands/mortgage-data-no-login.command"
import { ConsumerDataNoLoginCommand } from "@/app/mortgage-check/commands/consumer-data-no-login.command"
import { SubmitMortgageCheckNoLoginCommand } from "@/app/mortgage-check/commands/submit-mortgage-check-no-login.command"

const httpClient = new AxiosClient()

export const submitMortgageCheckCommand = new SubmitMortgageCheckCommand(httpClient, store)
export const mortgageDataNoLoginCommand = new MortgageDataNoLoginCommand(httpClient, store)
export const consumerDataNoLoginCommand = new ConsumerDataNoLoginCommand(httpClient, store)
export const submitMortgageCheckNoLoginCommand = new SubmitMortgageCheckNoLoginCommand(httpClient, store)
