
    import { Component, Inject, Vue } from 'vue-property-decorator'
    import Layout from '@/app/common/components/layout/layout.vue'
    import { mapGetters, mapState } from 'vuex'
    import { GeneralInsurance } from '@/app/general-insurance/entities/general-insurance.entity'
    import { GetAllGeneralInsurancesCommand } from '@/app/general-insurance/commands/get-all-general-insurances.command'
    import DatePicker from '@/app/common/components/date-picker.vue'
    import validation from '@/app/common/helpers/validation'
    import { VForm } from '@/app/common/types/v-form.type'
    import { NotificationDispatcher } from '@/app/common/services/notification-dispatcher.service'
    import { CreateDamageReportCommand } from '@/app/general-insurance/commands/create-damage-report.command'

    @Component({
        components: { Layout, DatePicker },
        metaInfo() {
            return {
                title: 'Create damage report'
            }
        },
        computed: {
            ...mapState('generalInsurance', ['generalInsurances']),
            ...mapGetters('generalInsurance', ['getByGeneralInsuranceId'])
        }
    })
    export default class CreateDamageReport extends Vue {
        generalInsurances!: GeneralInsurance[]
        getByGeneralInsuranceId!: (id: string) => GeneralInsurance|undefined
        generalInsurance: GeneralInsurance|null = null
        loading = false
        rules = validation

        date = ''
        time = ''
        cause = ''
        notes = ''
        files: File[] = []

        @Inject() getAllGeneralInsurancesCommand!: GetAllGeneralInsurancesCommand
        @Inject() createDamageReportCommand!: CreateDamageReportCommand
        @Inject() notificationDispatcher!: NotificationDispatcher

        get generalInsuranceId(): string {
            return this.$route.params.id
        }

        get form(): VForm {
            return this.$refs.form as VForm
        }

        validate(): boolean {
            return this.form.validate()
        }

        async mounted() {
            await this.getAllGeneralInsurances()
            this.generalInsurance = this.getByGeneralInsuranceId(this.generalInsuranceId) ?? null
        }

        async getAllGeneralInsurances() {
            if (!this.generalInsurances.length) {
                await this.getAllGeneralInsurancesCommand.run()
            }
        }

        async create() {
            try {
                this.loading = true

                const data = new FormData()
                data.append('date', this.date)
                data.append('time', this.time)
                data.append('cause', this.cause)
                data.append('notes', this.notes)
                data.append('product_id', this.generalInsurance!.productId)

                if (this.files && this.files.length) {
                    this.files.forEach((file: File) => {
                        data.append('file[]', file)
                    })
                }

                await this.createDamageReportCommand.run(data, this.generalInsuranceId)
                await this.getAllGeneralInsurancesCommand.run()
                await this.notificationDispatcher.success(this.$t("page.reportdamage.notifications.damage_report_has_been_created").toString())
                await this.$router.push({ name: 'general-insurance', params: { id: this.generalInsuranceId } })
            } catch (err) {
                await this.notificationDispatcher.error(err.message)
            } finally {
                this.loading = false
            }
        }
    }
