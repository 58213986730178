import { Expose } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'

export class ProductActionCounter extends Entity<ProductActionCounter> {
    @Expose()
    total!: number

    @Expose()
    closed!: number

    @Expose()
    open!: number
}
