import { Command } from '@/app/common/base/command'
import { ProductActionAuthEntity } from "@/app/auth/entities/product-action-auth.entity"

export class ProductActionLoginCommand extends Command {
    public async run(email: string, birthday: string, productActionId: string): Promise<ProductActionAuthEntity> {
        const response = await this.client.post('/api/auth/product-action-login', { email, birthday, productActionId })
        const productAuth =  response.asEntity(ProductActionAuthEntity)

        await this.store.dispatch('auth/updateProductActionToken', { id: productAuth.id, token: productAuth.token })

        return productAuth
    }
}
