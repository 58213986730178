import { Command } from '@/app/common/base/command'
import { Document } from '@/app/important-documents/entities/document.entity'

export class GetImportantDocumentsCommand extends Command {
    public async run() {
        const response = await this.client.get('/api/documents')
        const documents = response.asEntities(Document)

        await this.store.dispatch('importantDocuments/updateDocuments', documents)
    }
}
