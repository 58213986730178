
    import { Component, Vue, Prop } from 'vue-property-decorator'
    import { Fgv } from '@/app/fgv/entities/fgv.entity'
    import SimpleTable from '@/app/common/components/simple-table.vue'
    import { mapState } from 'vuex'
    import { Consumer } from '@/app/identity'

    @Component({
        components: { SimpleTable },
        computed: {
            ...mapState('auth', ['consumer'])
        }
    })
    export default class FgvOverview extends Vue {
        @Prop({ type: Fgv, required: true }) fgv!: Fgv

        consumer!: Consumer
    }
