import { Expose } from 'class-transformer'
import { Entity } from '@/app/common/base/entity'

export class Dictionary extends Entity<Dictionary> {
    @Expose()
    id!: string

    @Expose()
    translation!: string
}
