
    import { Component, Prop, Vue } from 'vue-property-decorator'
    import { ProductAction } from '../entities/product-action.entity'
    import ProductActionCard from './product-action-card.vue'
    import { Product } from '@/app/product/entities/product.entity'
    import { ProductType } from '@/app/product/support/product-type'
    import { mapState } from 'vuex'
    import { Consumer } from '@/app/identity'

    @Component({
        components: { ProductActionCard },
        computed: {
            ...mapState('auth', ['consumer'])
        }
    })
    export default class ProductActionList extends Vue {
        productType = ProductType
        consumer!: Consumer

        @Prop({ type: Array, default: [] }) productActions!: ProductAction[]
        @Prop({ type: Object }) product!: Product
        @Prop({ type: Boolean, default: false }) showClosedActionPoints!: boolean
        @Prop({ type: String, default: null }) title?: string

        get shouldShow(): boolean {
            // When including closed action points, just count
            // the total number of product actions.
            if (this.showClosedActionPoints) {
                return this.productActions.length > 0
            }

            // Otherwise, only count the non-closed product actions.
            // This way the list doesn't show up empty when closed
            // actions points are available, but not shown.
            return this.productActions.filter((action: ProductAction) => !action.isDone).length > 0
        }
    }
