
    import { Component, Vue, Prop } from 'vue-property-decorator'
    import { Mortgage } from '@/app/mortgage/entities/mortgage.entity'
    import SimpleTable from '@/app/common/components/simple-table.vue'

    @Component({
        components: { SimpleTable }
    })
    export default class MortgageOverview extends Vue {
        @Prop({ type: Mortgage, required: true }) mortgage!: Mortgage
    }
