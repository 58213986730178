import i18n from '@/plugins/i18n'

export default (value?: any): string => {
    let content = value

    if (value === null || value === undefined) {
        return ''
    }

    if (typeof value === 'string') {
        content = JSON.parse(value)
    }

    const locale = i18n.locale
    const fallback = i18n.fallbackLocale as string

    return content[locale] ?? content[fallback]
}
