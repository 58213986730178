
    import { Vue, Component } from 'vue-property-decorator'

    @Component({
        metaInfo() {
            return {
                title: 'Maintenance'
            }
        },
    })
    export default class Maintenance extends Vue {}
